import React, {useState} from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Link } from 'react-router-dom';

function TableRequerimientos({ columns, data}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    sortBy,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 200 }
  },
  useSortBy,
  usePagination)



  // Render the UI for your table
  return (
  <>
    <table {...getTableProps()}  
           className="w-full leading-normal text-md bg-white">
      
      <thead>
        {headerGroups.map(headerGroup => (

          <tr {...headerGroup.getHeaderGroupProps()} 
              className="border-b-2 bg-gray-200 border-gray-300 font-light text-xs text-gray-600 uppercase tracking-wider">

            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} 
                  className="px-5 py-3 text-left">{column.render('Header')}
                  
              </th>
            ))}

          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()} >
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}
                className="border-b cursor-pointer text-gray-800">
              {row.cells.map(cell => {

                return <td  {...cell.getCellProps()} 
                            className="p-2 px-5 text-left ">{cell.render('Cell', { id: cell.row.original.id, 
                                                                                    estadorequerimiento_id: cell.row.original.estadorequerimiento_id, 
                                                                                    estadorequerimiento_nombre: cell.row.original.estadorequerimiento_nombre,
                                                                                    origenrequerimiento_id: cell.row.original.origenrequerimiento_id})}
                       </td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>




    </>
  )
}


export default TableRequerimientos
