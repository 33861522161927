import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';

import { appSetMenuActual } from '../../actions/app'
import { obtenerRequerimientos } from '../../actions/requerimientos'
import { obtenerDepartamentosMunicipales } from '../../actions/departamentos'

import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientosDpto  from './TableRequerimientosDpto';

import ReactModal from 'react-modal';

import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import moment from 'moment';
import 'moment/locale/es';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

moment.locale('es');
registerLocale("es", es);
setDefaultLocale("es");


export const Requerimientos = () => {

  const dispatch = useDispatch();

  const { usuario } = useSelector( state => state.auth );


  useEffect(() => {
      dispatch( appSetMenuActual("requerimientos") );
  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'Departamento', accessor: 'departamento_nombre' },
            { Header: 'Fecha llamada', accessor: 'fecha', Cell: (props) => <span className="whitespace-nowrap">{props.original.fecha}</span>  },
            { Header: 'Requerimiento', accessor: 'requerimientotipificado_nombre', Cell: (props) => <span className="whitespace-nowrap" >{props.original.requerimientotipificado_nombre}</span>  },
            { Header: 'SLA', accessor: 'dias_en_proceso', Cell: (props) => {

              if(props.original.dias_en_proceso === null)
              return (<></>)

              if(props.original.dias_en_proceso <= 10)
              return(
              <span className="whitespace-nowrap bg-green-200 py-1 px-3 rounded">{props.original.dias_en_proceso} de 10</span>
              )
              else
              return(
                <span className="whitespace-nowrap bg-red-200 py-1 px-3 rounded">{props.original.dias_en_proceso} de 10</span>
              )
            }

            },

            { Header: 'Estado',
              Cell: (props) => {
                return (

                  <>

                  { props.original.estadorequerimiento_id === 1 ?

                    <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.original.estadorequerimiento_nombre}</span>

                  :  props.original.estadorequerimiento_id === 2 ?

                    <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.original.estadorequerimiento_nombre} </span>

                  :  props.original.estadorequerimiento_id === 3 ?

                    <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.original.estadorequerimiento_nombre} </span>

                  :  props.original.estadorequerimiento_id === 4 || props.original.estadorequerimiento_id === 5 ?

                    <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.original.estadorequerimiento_nombre} </span>



                  :
                  null }

                  </>
                );
              }
            },


            { Header: 'Ver',
              Cell: (props) => {
                return (

                  <Link to={`/requerimientos/ver/${props.original.id}`}>
                    <button className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </button>
                  </Link>

                );

              }
            }



          ],
    []
  )



  const [ formValues, handleInputChange ] = useForm({
      departamento_id: -1,
  });
  const { departamento_id } = formValues;


  const [fechaInicio, setFechaInicio] = useState(new Date(Date.now() - 30 * 24*60*60*1000))
  const [fechaFinal, setFechaFinal] = useState(new Date())


  const [requerimientos, setRequerimientos] = useState([])

  useEffect(() => {

    dispatch(obtenerRequerimientos( 0,
                                    moment(fechaInicio).format('DD-MM-YYYY'),
                                    moment(fechaFinal).format('DD-MM-YYYY'),
                                    departamento_id )).then(result => { setRequerimientos(result) })

  }, [dispatch, fechaInicio, fechaFinal, departamento_id])






  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
      dispatch( obtenerDepartamentosMunicipales() ).then( (result) => setDepartamentos(result) )

  }, [ dispatch ])





  const items_select_departamentos = [];

  departamentos.forEach(function(valor, indice, array) {

    if (valor.departamento_id !== null && valor.departamento_id !== undefined) {
      items_select_departamentos.push( <option value = { valor.departamento_id } key ={`option_rol_${ valor.departamento_id }`} > { valor.departamento_nombre } </option>
        )
    }

  });




  return (
    <div className='lg:flex min-h-full h-full w-full '>

      <MenuLeft />
      <Main>

      <div id="requerimiento_departamento_main" className="flex justify-left text-sm py-4 px-0 md:px-4 ">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-col  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faUser}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Gestión de Requerimientos asociados a Departamentos Municipales
                    </span>
                  </div>


                  <div className="px-2 mt-0 mb-0 bg-gray-200 p-4 rounded-lg w-full">
                    <div className="flex flex-col md:flex-row w-full">

                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                          Fecha inicio
                        </label>

                        <DatePicker
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={fechaInicio} onChange={date => setFechaInicio(date)}  locale="es" dateFormat="dd/MM/yyyy" />
                      </div>

                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                        Fecha final
                        </label>

                        <DatePicker
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={fechaFinal} onChange={date => setFechaFinal(date)}  locale="es" dateFormat="dd/MM/yyyy" />
                      </div>

                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                        Departamento
                        </label>

                        <select
                          className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight '
                          id = 'departamento_id'
                          name = "departamento_id"
                          onChange={handleInputChange}
                          >
                          <option value = "-1" > - Todos - </option>

                          { items_select_departamentos }

                        </select>


                      </div>



                    </div>
                  </div>



                </div>

                <hr className="mt-5" />

                <div className="flex flex-wrap py-2 ">

                  <div className="flex flex-wrap w-56 mb-2 bg-gray-100 border-gray-300 h-28 p-2 rounded-lg border ">
                    <span className="w-full font-bold text-base text-center">Total Requerimientos Derivados</span>
                    <span className="w-full font-normal text-base text-center"></span>
                    <span className="w-full font-bold text-2xl text-center text-green-500">{requerimientos.length}</span>
                  </div>

                  <div className="flex flex-wrap w-56 mb-2 bg-gray-100 border-gray-300 h-28 p-2 rounded-lg border ml-4 ">
                    <span className="w-full font-bold text-base text-center">Total Requerimientos Cerrados</span>
                    <span className="w-full font-normal text-base text-center"></span>
                    <span className="w-full font-bold text-2xl text-center text-gray-400">{requerimientos.filter(r=>r.estadorequerimiento_id===4).length}</span>
                  </div>

                  <div className="flex flex-wrap w-56 mb-2 bg-gray-100 border-gray-300 h-28 p-2 rounded-lg border ml-4 ">
                    <span className="w-full font-bold text-base text-center">Total Requerimientos Pendientes {'>'} SLA</span>
                    <span className="w-full font-normal text-base text-center"></span>
                    <span className="w-full font-bold text-2xl text-center text-red-400">{requerimientos.filter(r=>r.dias_en_proceso>10).length }</span>
                  </div>

                  <div className="flex flex-wrap w-56 mb-2 bg-gray-100 border-gray-300 h-28 p-2 rounded-lg border ml-4 ">
                    <span className="w-full font-bold text-base text-center">Tiempo promedio gestión (días)</span>
                    <span className="w-full font-normal text-base text-center"></span>
                    <span className="w-full font-bold text-2xl text-center text-indigo-500">{Math.round(requerimientos.reduce((acum, r)=>acum+r.dias_en_proceso, 0) / (requerimientos.length!==0?requerimientos.length:1)  ) }</span>
                  </div>

                </div>


                <hr />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableRequerimientosDpto columns={columns} data={requerimientos} />

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
