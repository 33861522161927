// Set API URL de acuerdo al dominio del cliente.
let apiUrl;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'fonoemergencia1409.temuco.cl') {
    apiUrl = 'https://fonoemergencia1409-api.temuco.cl';
}
else if(hostname === 'fonoemergencia1409-testing.temuco.cl') {
    apiUrl = 'https://fonoemergencia1409-testing-api.temuco.cl';
} else {
    apiUrl = 'http://localhost:3000';
}

export const APP_CONFIG = { apiUrl: apiUrl };
