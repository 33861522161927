import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {appSetMenuActual} from '../../actions/app'
import { editarUsuario, obtenerRoles, obtenerUsuario, modificarPassword, eliminarUsuario, desactivarUsuario, activarUsuario } from '../../actions/usuarios'
import { obtenerDepartamentosMunicipales } from '../../actions/departamentos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import cogoToast from 'cogo-toast'
import { faArrowLeft, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


export const UsuariosEditar = (props) => {

  let history = useHistory();
  const dispatch = useDispatch();

  const { roles } = useSelector( state => state.usuarios );

  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
      dispatch( appSetMenuActual("usuarios") );
      dispatch( obtenerRoles() );
      dispatch( obtenerDepartamentosMunicipales() ).then( (result) => setDepartamentos(result) )

  }, [ dispatch ])



const [formValues, setValues] = useState({
  id: '',
  email: '',
  nombre: '',
  rol_id: 0,
  departamento_id: 0,
  xcontact_id_operador: '',
  password1: '',
  password2: '',
  activo: null
});

const setCustomValues = (val) => {
    setValues( val );
}


const handleInputChange = ({ target }) => {

    setValues({
        ...formValues,
        [ target.name ]: target.value
    });

}

  const { id, email, nombre, rol_id, departamento_id, xcontact_id_operador, password1, password2, activo } = formValues;


  useEffect(() => {
    dispatch( obtenerUsuario(props.match.params.id) )
    .then(result => { setCustomValues({
                                      id: result.usuario_id,
                                      email: result.usuario_email,
                                      nombre: result.usuario_nombre,
                                      rol_id: result.rol_id,
                                      departamento_id: result.departamento_id,
                                      xcontact_id_operador: result.xcontact_id_operador,
                                      password1: '',
                                      password2: '',
                                      activo: result.usuario_activo
                                    })
             
                 
                                  })
  
                                }, [])


                  

  const handleSubmitEditarUsuario = ( e ) => {
    e.preventDefault();

    if(password1 === password2){

      let departamento_id_enviar = departamento_id;
      if(rol_id == 1 || rol_id == 2 || rol_id == 5)
      departamento_id_enviar = 0;

      let xcontact_id_operador_enviar = xcontact_id_operador;
      if(rol_id != 2 )
      xcontact_id_operador_enviar = '';
   
      dispatch( editarUsuario( id, email, nombre, rol_id, departamento_id_enviar, xcontact_id_operador_enviar ) )
        .then( response => {

          if(response.error === false)
            history.push('/usuarios/');
        
        });
    
    }
    else{
      cogoToast.error("Las contraseñas no coinciden.", {hideAfter:6});
    }
  }

  const handleSubmitModificarPassword = ( e ) => {
    e.preventDefault();

    if(password1 === password2){
   
      dispatch( modificarPassword( id, password1 ) )
        .then( response => {

          if(response.error === false)
            history.push('/usuarios/');
        
        });
    
    }
    else{
      cogoToast.error("Las contraseñas no coinciden.", {hideAfter:6});
    }
  }

  const handleEliminarUsuario = ( e ) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='text-center border border-indigo-100 p-10 bg-gray-100'>
            <p className="p-5 pb-8">Esta acción eliminará el usuario y todos sus registros asociados. ¿Esta seguro de continuar?</p>
            <button className="bg-gray-500 hover:bg-gray-600 text-white px-5 py-2 m-3" onClick={onClose}>No</button>
            <button
              className="bg-red-400 hover:bg-red-500 text-white px-5 py-2 m-3"
              onClick={() => {
                handleEliminarUsuarioConfirma();
                onClose();
              }}
            >
              Si, eliminar usuario
            </button>
          </div>
        );
      }
    });

  }


  const handleDesactivarUsuario = ( e ) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='text-center border border-indigo-100 p-10 bg-gray-100'>
            <p className="p-5 pb-8">Esta acción desactivará el usuario del sistema. ¿Esta seguro de continuar?</p>
            <button className="bg-gray-500 hover:bg-gray-600 text-white px-5 py-2 m-3" onClick={onClose}>No</button>
            <button
              className="bg-green-500 hover:bg-green-600 text-white px-5 py-2 m-3"
              onClick={() => {
                handleDesactivarUsuarioConfirma();
                onClose();
              }}
            >
              Si, desactivar usuario
            </button>
          </div>
        );
      }
    });

  }


  const handleActivarUsuario = ( e ) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='text-center border border-indigo-100 p-10 bg-gray-100'>
            <p className="p-5 pb-8">Esta acción activará el usuario del sistema. ¿Esta seguro de continuar?</p>
            <button className="bg-gray-500 hover:bg-gray-600 text-white px-5 py-2 m-3" onClick={onClose}>No</button>
            <button
              className="bg-green-500 hover:bg-green-600 text-white px-5 py-2 m-3"
              onClick={() => {
                handleActivarUsuarioConfirma();
                onClose();
              }}
            >
              Si, activar usuario
            </button>
          </div>
        );
      }
    });

  }



  const handleEliminarUsuarioConfirma = () => {

    dispatch( eliminarUsuario( id ) )
      .then( response => {

        if(response.error === false)
          history.push('/usuarios/');
      
      });

  }


  const handleDesactivarUsuarioConfirma = () => {

    dispatch( desactivarUsuario( id ) )
      .then( response => {

        if(response.error === false)
          history.push('/usuarios/');
      
      });

  }

  const handleActivarUsuarioConfirma = () => {

    dispatch( activarUsuario( id ) )
      .then( response => {

        if(response.error === false)
          history.push('/usuarios/');
      
      });

  }




  const items_select_roles = [];

  roles.forEach(function(valor, indice, array) {

    if (valor.rol_id !== null && valor.rol_id !== undefined) {
      items_select_roles.push( <option value = { valor.rol_id } key ={`option_rol_${ valor.rol_id }`} > { valor.rol_nombre } </option>
        )
    }

  });


  const items_select_departamentos = [];

  departamentos.forEach(function(valor, indice, array) {

    if (valor.departamento_id !== null && valor.departamento_id !== undefined) {
      items_select_departamentos.push( <option value = { valor.departamento_id } key ={`option_rol_${ valor.departamento_id }`} > { valor.departamento_nombre } </option>
        )
    }

  });


  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className='flex-1 flex flex-col'>
        
        <div className='flex items-start mb-4 text-sm p-8 md:p-16 md:pt-12'>
          <div className='flex-1 overflow-hidden  rounded p-2 lg:p-6 lg:pt-0 text-left '>
            
            <Link to='/usuarios' className="pt-2 md:pt-0">
              <button 
                id='btn_volver' 
                type='button' 
                className='bg-gray-500 hover:bg-gray-600 text-white text-base font-bold py-2 px-8 mb-5 rounded'
              >
                <FontAwesomeIcon icon={faArrowLeft} size='1x' />
                <span className="pl-2">
                Volver
                </span>
              </button>
            </Link>



          <form className=' w-full lg:w-3/4 xlg:w-1/3 border rounded border-indigo-200 p-10 bg-white' 
                onSubmit={handleSubmitEditarUsuario} 
                autoComplete="off">

            <span className="font-bold text-lg">Editar Usuario</span>
            <hr className="mt-2" />

          
            <div className="flex flex-wrap -mx-3 mb-6 mt-5">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="nombre">
                  Nombre
                </label>
                
                <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight 
                                  focus:shadow-sm focus:border-indigo-200" 
                id="nombre"
                name="nombre"
                type="text"
                defaultValue={nombre} 
                required 
                onChange={handleInputChange}
                />
                
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input 
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight 
                            focus:shadow-sm focus:border-indigo-200" 
                id="email"
                name="email" 
                type="email"
                defaultValue={email}
                required
                onChange={handleInputChange}
                autoComplete="off"
                />
                
              </div>
            </div>


            <div className="flex flex-wrap -mx-3 mb-8">

              <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="rol">
                  Rol
                </label>
                
                <div>
                  <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                  id = 'rol_id'
                  name = "rol_id"
                  onChange={handleInputChange}
                  value={rol_id}
                   >
                  <option value = "0" > - Seleccione - </option> 

                  { items_select_roles } 
                  
                  </select>
                </div>
              </div>

            </div>



            { ( parseInt(rol_id) !== 1 && parseInt(rol_id) !== 2 && parseInt(rol_id) !== 5 && parseInt(rol_id) !== 0 )  ?
            <div className="flex flex-wrap -mx-3 mb-8">

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="departamento_id">
                  Departamento
                </label>
                
                <div>
                  <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight '
                  id = 'departamento_id'
                  name = "departamento_id"
                  onChange={handleInputChange}
                  value={departamento_id}
                   >
                  <option value = "0" > - Seleccione - </option> 

                  { items_select_departamentos } 
                  
                  </select>
                </div>
              </div>
            
            </div>
            : null }



            { ( parseInt(rol_id) === 2 )  ?
            <div className="flex flex-wrap -mx-3 mb-6 mt-5">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="xcontact_id_operador">
                  Operador ID (xContact)
                </label>
                
                <input className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                id="xcontact_id_operador"
                name="xcontact_id_operador"
                type="number" 
                defaultValue={xcontact_id_operador}
                onChange={handleInputChange}
                />
                
              </div>
            </div>
            : null }



            <div className="w-full px-0 text-left pt-4">

              <button 
                id='btn_confirmar' 
                type='submit' 
                className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
              >
              <FontAwesomeIcon icon={faSave} size='1x' />
              <span className="pl-2">
              Modificar Usuario
              </span>
              </button>
            </div>


            </form>







            <form className='w-full lg:w-3/4 xlg:w-1/3  border rounded border-indigo-200 p-10 mt-5 bg-white '
            onSubmit={handleSubmitModificarPassword}  >

            <span className="font-bold text-lg">Modificar contraseña</span>
            <hr className="mt-2" />

              <div className="flex flex-wrap -mx-3 mb-6 pt-5">
                <div className="px-3 w-full">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password1">
                    Nueva Contraseña
                  </label>
                  <input 
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                    id="password1" 
                    name="password1"
                    type="password" 
                    placeholder="******************"
                    required
                    onChange={handleInputChange}
                     />
                </div>
              </div>


              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="px-3 w-full">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password2">
                    Confirmar nueva contraseña
                  </label>
                  <input 
                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                    id="password2"
                    name="password2"
                    type="password" 
                    placeholder="******************"
                    required
                    onChange={handleInputChange}
                     />
                </div>
              </div>


              <div className="w-full px-0 text-left pt-4">

                <button 
                  id='btn_confirmar_password' 
                  type='submit' 
                  className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
                >
                <FontAwesomeIcon icon={faSave} size='1x' />
                <span className="pl-2">
                Guardar cambios
                </span>
                </button>
              </div>


            </form>






            <div className="w-full lg:w-3/4 xlg:w-1/3 text-right flex flex-row-reverse">

            <form onSubmit={handleEliminarUsuario}>
                <button 
                  type='submit' 
                  className='bg-red-400 hover:bg-red-500 text-white text-base font-bold py-2 px-8 mt-6 rounded ml-4'>
                  <FontAwesomeIcon icon={faTrashAlt} size='1x' />
                    <span className="pl-2">
                    Eliminar Usuario
                    </span>
                </button>
              </form>


              { activo === 1 ?
              <form onSubmit={handleDesactivarUsuario}>
                <button 
                  type='submit' 
                  className='bg-gray-500 hover:bg-gray-600 text-white text-base font-bold py-2 px-8 mt-6 rounded'>
                  
                    <span className="pl-2">
                    Desactivar Usuario
                    </span>
                </button>
              </form>
              : 
              <form onSubmit={handleActivarUsuario}>
                <button 
                  type='submit' 
                  className='bg-gray-500 hover:bg-gray-600 text-white text-base font-bold py-2 px-8 mt-6 rounded'>
                  
                    <span className="pl-2">
                    Activar Usuario
                    </span>
                </button>
              </form>

              }

              
            </div>






          

          </div>
        </div>
     
    </div>
      </Main>
    </div>
  )
}
