import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app';

import { obtenerRequerimientos } from '../../actions/requerimientos';

import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableReporteRequerimientos  from './TableReporteRequerimientos';

import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_CONFIG } from '../../config/app'

import moment from 'moment';
import 'moment/locale/es';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

moment.locale('es');
registerLocale("es", es);
setDefaultLocale("es");


export const ReporteRequerimientos = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( appSetMenuActual("reporte-requerimientos") );

  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Origen', accessor: 'origenrequerimiento_nombre' },
            { Header: 'Telefono', accessor: 'telefono_origen' },
            { Header: 'Fecha', accessor: 'fecha_hora' },
            { Header: 'Tipo Requerimiento', accessor: 'tiporequerimiento_nombre' },
            { Header: 'Operador', accessor: 'usuario_agente_nombre' },
            { Header: 'Estado', accessor: 'estadorequerimiento_nombre' },
            { Header: 'Ver',
              Cell: (props) => {
                if(props.estadorequerimiento_id === 3 || props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5) {
                  return(
                    <Link to={`/requerimientos/ver/${props.id}/?from=reporte_requerimientos`} target="_blank">
                      <button className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </button>
                    </Link>
                  )
                }
                else {
                  return(<></>)
                }

              }
            }


          ],
    []
  )

  const [fechaInicio, setFechaInicio] = useState(new Date())
  const [fechaFinal, setFechaFinal] = useState(new Date())

  const [requerimientos, setRequerimientos] = useState([])

  useEffect(() => {

    dispatch(obtenerRequerimientos(0, moment(fechaInicio).format('DD-MM-YYYY'), moment(fechaFinal).format('DD-MM-YYYY'))).then(result => { setRequerimientos(result) })

  }, [dispatch])


  const handleActualizarFiltros = () => {
    dispatch(obtenerRequerimientos(0, moment(fechaInicio).format('DD-MM-YYYY'), moment(fechaFinal).format('DD-MM-YYYY'))).then(result => { setRequerimientos(result);  })
  }



  const getCurrentDate = () => {

    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let seconds = date_ob.getSeconds();

    return year + "" + month + "" + date + "_" + hours + "" + minutes + "" + seconds

  }


  const downloadExcelRequerimientos = () => {

    const token = localStorage.getItem('token') || '';

		fetch(APP_CONFIG.apiUrl + '/requerimientos/excel/?fecha_inicio='+moment(fechaInicio).format('DD-MM-YYYY')+'&fecha_final='+moment(fechaFinal).format('DD-MM-YYYY'),  { headers: {'x-token': token} } )
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = getCurrentDate() + "_" + "reporte_requerimientos.xlsx";
					a.click();
				});
		});
	}


  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-col  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faColumns}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Reporte: Requerimientos
                    </span>
                  </div>


                  <div className="px-2 mt-0 mb-0 bg-gray-200 p-4 rounded-lg w-full">
                    <div className="flex flex-col md:flex-row w-1/2">

                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                          Fecha inicio
                        </label>

                        <DatePicker
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={fechaInicio} onChange={date => setFechaInicio(date)}  locale="es" dateFormat="dd/MM/yyyy" />
                      </div>

                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                        Fecha final
                        </label>

                        <DatePicker
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={fechaFinal} onChange={date => setFechaFinal(date)}  locale="es" dateFormat="dd/MM/yyyy" />
                      </div>


                      <div className="w-full px-3 mb-5 md:mb-0 pt-7">

                        <button className="bg-gray-600 hover:bg-gray-700 text-white p-x-5 py-2 rounded w-48 "  onClick={handleActualizarFiltros}>Actualizar resultados</button>
                      </div>

                    </div>
                  </div>



                </div>

                <hr />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableReporteRequerimientos columns={columns} data={requerimientos} />

                    </div>
                  </div>

                  <button onClick={downloadExcelRequerimientos} className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-1 w-56 text-center">Descargar XLSX</button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
