import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { Main } from '../App/Main';
import { startRecuperarClave, appSetLoader } from '../../actions/auth';


export const RecuperarClave = () => {

    let history = useHistory();
    const dispatch = useDispatch();

    
    const [ formValues, handleInputChange ] = useForm({
        email: ''
    });

    useEffect(() => {
      dispatch( appSetLoader(false, "") )
    }, [])

    const { email } = formValues;

    const handleSubmit = ( e ) => {
        e.preventDefault();
        
        dispatch( startRecuperarClave( email ) )
          .then( response => {

            if(response.error === false)
              history.push('/usuarios/');
          
          });

      }

    return (

      <Main>
      <div className='text-center antialiased h-full min-h-full'>
          <div className='shadow-md rounded pt-0 flex w-full min-h-full items-center justify-center my-auto  '>
            <div className='w-full sm:w-3/4 xl:w-2/5 lg:w-1/2 md:w-3/4 mx-3'>

            <div className="w-full flex justify-center text-center mb-10" >
              {/* <img src={logo_blue} className="w-1/2 sm:w-1/3  object-center" ></img> */}
              <h2 className="text-2xl font-bold text-gray-500">Sistema de Gestión Municipal de Requerimientos Fono Emergencias 1409</h2>
            </div>

            <h4 className='font-semibold py-4 text-left pl-6 text-lg '>Recuperar Clave</h4>

            <form className='bg-white shadow-md rounded px-10 py-10 mb-4 w-full' onSubmit={handleSubmit} autoComplete="off" >

              <div className='mb-4'>
                <label className='block text-gray-700 text-sm font-bold text-left' htmlFor='usuario_rut'>
                  Email
                  <input 
                    className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight' 
                    id='email' 
                    name='email'
                    type='email' 
                    onChange={handleInputChange}
                    required 
                    autoFocus 
                  
                    autoComplete="off"
                    />
                </label>
              </div>

              <div className='flex flex-col md:flex-row  items-center justify-between pt-3'>

                <button className="bg-temuco-green text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline" type='submit'>
                  Enviar link de recuperación
                </button>

                <Link to='/login' className="pt-2 md:pt-0">
                  <span className='inline-block mt-4 align-baseline font-medium text-sm text-gray-400 hover:text-indigo-500'>
                    Volver
                  </span>
                </Link>

              </div>

            </form>

          </div>
        </div>
      </div>
    </Main>


    )
}
