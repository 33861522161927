
import { fetchConToken } from '../helpers/fetch';
import { appSetLoader } from './auth'
import cogoToast from 'cogo-toast'

export const obtenerRequerimientos = (id=0, fecha_inicio=0, fecha_final=0, departamento_id = 0) => {
    return async( dispatch ) => {

        if(id === undefined )
        id = 0

        try {
            const resp = await fetchConToken('requerimientos/?id='+id+'&fecha_inicio='+fecha_inicio+'&fecha_final='+fecha_final+'&departamento_id='+departamento_id, {}, 'GET');
            const body = await resp.json();

            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}

export const obtenerRequerimientosCallCenter = (xcontact_id_operador) => {
    return async( dispatch ) => {

        if(xcontact_id_operador === null )
            return [];

        try {
            const resp = await fetchConToken('requerimientos/?xcontact_id_operador='+xcontact_id_operador+'&last24h=1', {}, 'GET');
            const body = await resp.json();

            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerRequerimientosDepartamento = (id) => {
    return async( dispatch ) => {

        if(id === undefined )
        id = 0

        try {
            const resp = await fetchConToken('requerimientos/departamento/?id='+id, {}, 'GET');
            const body = await resp.json();
            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerRequerimientosFuncionario = (id) => {
    return async( dispatch ) => {

        if(id === undefined )
        id = 0

        try {
            const resp = await fetchConToken('requerimientos/funcionario/?id='+id, {}, 'GET');
            const body = await resp.json();
            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}



export const obtenerUltimosRequerimientos = (requerimiento_id) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('requerimientos/?limit_requerimiento_id='+requerimiento_id+'&limit=5&estado_inicial=3', {}, 'GET');
            const body = await resp.json();

            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerSectores = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('sectores', {}, 'GET');
            const body = await resp.json();

            if(body.sectores)
            return body.sectores;
            else
            return [];

        } catch (error) {
            return {}
        }



    }
}



export const obtenerUnidadesOperativas = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('unidades-operativas', {}, 'GET');
            const body = await resp.json();

            if(body.unidades_operativas)
            return body.unidades_operativas;
            else
            return [];

        } catch (error) {
            return {}
        }



    }
}


export const obtenerTiposRequerimientos = (unidadoperativa_id = 0) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('tipos-requerimientos/?unidadoperativa_id='+unidadoperativa_id, {}, 'GET');
            const body = await resp.json();

            if(body.tipos_requerimientos)
            return body.tipos_requerimientos;
            else
            return [];

        } catch (error) {
            return {}
        }



    }
}


export const obtenerRequerimientosTipificados = (departamento_id = 0) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('requerimientos-tipificados/?departamento_id='+departamento_id, {}, 'GET');
            const body = await resp.json();

            if(body.requerimientos_tipificados  )
            return body.requerimientos_tipificados;
            else
            return [];

        } catch (error) {
            return {}
        }



    }
}


// export const obtenerRoles = () => {
//     return async( dispatch ) => {

//         try {
//             const resp = await fetchConToken('roles', {}, 'GET');
//             const body = await resp.json();

//             if(Array.isArray(body))
//             dispatch( usuariosSetRoles(body) );
//             else
//             dispatch( usuariosSetRoles([]) );

//         } catch (error) {
//             dispatch( usuariosSetRoles([]) );
//         }



//     }
// }


// const usuariosSetRoles = (roles) => ({ type: types.usuariosSetRoles, payload: roles });






export const asignarFuncionarioRequerimiento = ( requerimiento_id, usuario_id )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Asignando Funcionario a Requerimiento.") );

            const resp = await fetchConToken('requerimientos/asignar-funcionario', { requerimiento_id, usuario_id }, 'POST');
            const body = await resp.json();

            if( body.error === false ) {

                cogoToast.success("Se asignó el Funcionario al Requerimiento correctamente.", {hideAfter:6});
                dispatch( appSetLoader(false, "") );
                return { error: false };

            } else {

                cogoToast.error(body.msg, {hideAfter:8});

                dispatch( appSetLoader(false, "") );
                return { error: true };

            }

        } catch (error) {
            cogoToast.error("Error inesperado al asignar Funcionaro a Requerimiento. Si el problema persiste contacte al administrador.", {hideAfter:8});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}


export const generarRequerimiento = ( requerimiento, formValues )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Generando nuevo requerimiento.") );

            const resp = await fetchConToken('requerimientos', { requerimiento, formValues }, 'POST');
            const body = await resp.json();

            if( body.error === false ) {

                cogoToast.success("Requerimiento ingresado correctamente.", {hideAfter:6});
                dispatch( appSetLoader(false, "") );
                return { error: false };

            } else {

                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }

                dispatch( appSetLoader(false, "") );
                return { error: true };

            }

        } catch (error) {
            cogoToast.error("Error inesperado al ingresar el requerimiento. Si el problema persiste contacte al administrador.", {hideAfter:8});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}




export const cerrarRequerimiento = ( requerimiento, formValues )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Cerrando nuevo requerimiento.") );

            const resp = await fetchConToken('requerimientos/cerrar', { requerimiento, formValues }, 'POST');
            const body = await resp.json();

            if( body.error === false ) {

                cogoToast.success("Requerimiento cerrado correctamente.", {hideAfter:6});
                dispatch( appSetLoader(false, "") );
                return { error: false };

            } else {

                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }

                dispatch( appSetLoader(false, "") );
                return { error: true };

            }

        } catch (error) {
            cogoToast.error("Error inesperado al cerrar el requerimiento. Si el problema persiste contacte al administrador.", {hideAfter:8});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}




// export const editarUsuario = ( id, email, nombre, rol_id, departamento_id )  => {
//     return async( dispatch ) => {

//         try {

//             dispatch( appSetLoader(true, "Editando nuevo usuario.") );

//             const resp = await fetchConToken('usuarios', { usuario_id: id, usuario_email: email, usuario_nombre: nombre, rol_id, departamento_id }, 'PATCH');
//             const body = await resp.json();

//             if( body.error === false ) {

//                 cogoToast.success("Usuario editado correctamente.", {hideAfter:8});
//                 dispatch( appSetLoader(false, "") );
//                 return { error: false };

//             } else {

//                 if(body.msg_validation){
//                     cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
//                 }
//                 else{
//                     cogoToast.error(body.msg, {hideAfter:8});
//                 }

//                 dispatch( appSetLoader(false, "") );
//                 return { error: true };

//             }

//         } catch (error) {
//             cogoToast.error("Error inesperado al editar el usuario. Si el problema persiste contacte al administrador.", {hideAfter:8});
//             dispatch( appSetLoader(false, "") );
//             return { error: true };
//         }

//     }
// }



// export const modificarPassword = ( id, password )  => {
//     return async( dispatch ) => {

//         try {

//             dispatch( appSetLoader(true, "Actualizando contraseña del usuario.") );

//             const resp = await fetchConToken('usuarios/update-password', { usuario_id: id, usuario_password: password }, 'POST')
//             const body = await resp.json();

//             if( body.error === false ) {

//                 cogoToast.success("Contraseña actualizada correctamente.", {hideAfter:8});
//                 dispatch( appSetLoader(false, "") );
//                 return { error: false };

//             } else {

//                 if(body.msg_validation){
//                     cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
//                 }
//                 else{
//                     cogoToast.error(body.msg, {hideAfter:8});
//                 }

//                 dispatch( appSetLoader(false, "") );
//                 return { error: true };

//             }

//         } catch (error) {
//             cogoToast.error("Error inesperado al cambiar la contraseña del usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
//             dispatch( appSetLoader(false, "") );
//             return { error: true };
//         }

//     }
// }




// export const eliminarUsuario = ( id )  => {
//     return async( dispatch ) => {

//         try {

//             dispatch( appSetLoader(true, "Eliminando usuario.") );

//             const resp = await fetchConToken('usuarios', { usuario_id: id }, 'DELETE')
//             const body = await resp.json();

//             if( body.error === false ) {

//                 cogoToast.success("Usuario eliminado.", {hideAfter:8});
//                 dispatch( appSetLoader(false, "") );
//                 return { error: false };

//             } else {

//                 if(body.msg_validation){
//                     cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
//                 }
//                 else{
//                     cogoToast.error(body.msg, {hideAfter:8});
//                 }

//                 dispatch( appSetLoader(false, "") );
//                 return { error: true };

//             }

//         } catch (error) {
//             cogoToast.error("Error inesperado al eliminar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
//             dispatch( appSetLoader(false, "") );
//             return { error: true };
//         }

//     }
// }




// export const desactivarUsuario = ( id )  => {
//     return async( dispatch ) => {

//         try {

//             dispatch( appSetLoader(true, "Desactivando usuario.") );

//             const resp = await fetchConToken('usuarios/deactivate', { usuario_id: id }, 'POST')
//             const body = await resp.json();

//             if( body.error === false ) {

//                 cogoToast.success("Usuario desactivado correctamente.", {hideAfter:8});
//                 dispatch( appSetLoader(false, "") );
//                 return { error: false };

//             } else {

//                 if(body.msg_validation){
//                     cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
//                 }
//                 else{
//                     cogoToast.error(body.msg, {hideAfter:8});
//                 }

//                 dispatch( appSetLoader(false, "") );
//                 return { error: true };

//             }

//         } catch (error) {
//             cogoToast.error("Error inesperado al desactivar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
//             dispatch( appSetLoader(false, "") );
//             return { error: true };
//         }

//     }
// }





// export const activarUsuario = ( id )  => {
//     return async( dispatch ) => {

//         try {

//             dispatch( appSetLoader(true, "Activando usuario.") );

//             const resp = await fetchConToken('usuarios/activate', { usuario_id: id }, 'POST')
//             const body = await resp.json();

//             if( body.error === false ) {

//                 cogoToast.success("Usuario activado correctamente.", {hideAfter:8});
//                 dispatch( appSetLoader(false, "") );
//                 return { error: false };

//             } else {

//                 if(body.msg_validation){
//                     cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
//                 }
//                 else{
//                     cogoToast.error(body.msg, {hideAfter:8});
//                 }

//                 dispatch( appSetLoader(false, "") );
//                 return { error: true };

//             }

//         } catch (error) {
//             cogoToast.error("Error inesperado al activar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
//             dispatch( appSetLoader(false, "") );
//             return { error: true };
//         }

//     }
// }





// const eventAddNew = (event) => ({
//     type: types.eventAddNew,
//     payload: event
// });

// export const eventSetActive = (event) => ({
//     type: types.eventSetActive,
//     payload: event
// });

// export const eventClearActiveEvent = () => ({ type: types.eventClearActiveEvent });



// export const eventStartUpdate = ( event ) => {
//     return async(dispatch) => {

//         try {
//             const resp = await fetchConToken(`events/${ event.id }`, event, 'PUT' );
//             const body = await resp.json();

//             if ( body.ok ) {
//                 dispatch( eventUpdated( event ) );
//             } else {
//                 Swal.fire('Error', body.msg, 'error');
//             }


//         } catch (error) {
//             console.log(error)
//         }

//     }
// }

// const eventUpdated = ( event ) => ({
//     type: types.eventUpdated,
//     payload: event
// });


// export const eventStartDelete = () => {
//     return async ( dispatch, getState ) => {

//         const { id } = getState().calendar.activeEvent;
//         try {
//             const resp = await fetchConToken(`events/${ id }`, {}, 'DELETE' );
//             const body = await resp.json();

//             if ( body.ok ) {
//                 dispatch( eventDeleted() );
//             } else {
//                 Swal.fire('Error', body.msg, 'error');
//             }


//         } catch (error) {
//             console.log(error)
//         }

//     }
// }


// const eventDeleted = () => ({ type: types.eventDeleted });


// export const eventStartLoading = () => {
//     return async(dispatch) => {

//         try {

//             const resp = await fetchConToken( 'events' );
//             const body = await resp.json();

//             const events = prepareEvents( body.eventos );
//             dispatch( eventLoaded( events ) );

//         } catch (error) {
//             console.log(error)
//         }

//     }
// }

// const eventLoaded = (events) => ({
//     type: types.eventLoaded,
//     payload: events
// })

// export const eventLogout =() => ({ type: types.eventLogout });