import { types } from '../types/types';

const initialState = {
    // usuario: null
}

export const authReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
        case types.authLogin:
            return {
                ...state,
                ...action.payload
            }

        case types.authCheckingFinish:
            return {
                ...state
            }


        case types.authLogout:
            return {
                
            }

        case types.authMisDatosModificar:
        return {
            ...state,
            usuario: {
                    ...state.usuario,
                    usuario_nombre: action.payload.nombre,
                    usuario_email: action.payload.email
                }
        }

        default:
            return state;
    }

}


