import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { appSetLoader } from '../../actions/auth'
import { appSetMenuActual } from '../../actions/app';

import {  obtenerConsolidadoRequerimientosTrimestral,
          obtenerConsolidadoSectoresTrimestral,
          obtenerConsolidadoRequerimientosMensual,
          obtenerConsolidadoSectoresMensual,
          obtenerConsolidadoRequerimientosInformadosTrimestral,
          obtenerConsolidadoRequerimientosInformadosMensual,
          obtenerConsolidadoDerivadosServiciosMunicipalesTrimestral,
          obtenerConsolidadoDerivadosServiciosMunicipalesMensual,
          obtenerConsolidadoRangoHoraTrimestral,
          obtenerConsolidadoRangoHoraMensual,
          obtenerConsolidadoPatrullajeMovilTrimestral,
          obtenerConsolidadoPatrullajeMovilMensual,
          obtenerConsolidadoPatrullajeCicloviasTrimestral,
          obtenerConsolidadoPatrullajeCicloviasMensual,
          obtenerConsolidadoMovilTerrenoTrimestral,
          obtenerConsolidadoMovilTerrenoMensual,
        } from '../../actions/reportes';

import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableReporteMensualTrimestral  from './TableReporteMensualTrimestral';

import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_CONFIG } from '../../config/app'

import { Line } from 'react-chartjs-2';

import { Chart } from "react-google-charts";

import * as htmlToImage from 'html-to-image';

import cogoToast from 'cogo-toast'

import moment from 'moment';
import 'moment/locale/es';

import { saveAs } from "file-saver";
import { Document, ImageRun, Packer, Paragraph, TextRun } from "docx";

import imageSize from 'image-size';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

moment.locale('es');
registerLocale("es", es);
setDefaultLocale("es");

function add_months(dt, n)
 {

   return new Date(dt.setMonth(dt.getMonth() + n));
 }


export const ReporteMensualTrimestral = () => {




  const [mes, setMes] = useState(null)
  const [trimestre, setTrimestre] = useState(null)


  const colorsGraph = ['#3466cc', '#ff9900', '#dc3a14', '#109619', '#990099', '#0099c6', '#dd4477', '#8b0708'];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( appSetMenuActual("reporte-mensual-trimestral") );

  }, [ dispatch ])

  const [mesSeleccionado, setMesSeleccionado] = useState("")
  const [trimestreSeleccionado, setTrimestreSeleccionado] = useState("")
  const [trimestreSeleccionado2, setTrimestreSeleccionado2] = useState("")
  const [trimestreSeleccionado3, setTrimestreSeleccionado3] = useState("")

  useEffect(() => {

    if(mes !== null)
    setMesSeleccionado( mes.toLocaleString('es-cl', { month: 'short' }).replace(".", "") )

  }, [mes])


  useEffect(() => {

    if(trimestre !== null){

      const trimestre2 = new Date(trimestre.getTime());
      const trimestre3 = new Date(trimestre.getTime());

      add_months(trimestre2, 1)
      add_months(trimestre3, 2)

      setTrimestreSeleccionado( trimestre.toLocaleString('es-cl', { month: 'short' }).replace(".", "") )
      setTrimestreSeleccionado2( trimestre2.toLocaleString('es-cl', { month: 'short' }).replace(".", "") )
      setTrimestreSeleccionado3( trimestre3.toLocaleString('es-cl', { month: 'short' }).replace(".", "") )

    }

  }, [trimestre])


  const columnsTrimestral =  [ { Header: "", accessor: 'letra' },
            { Header: "Requerimiento", accessor: 'requerimiento' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]



  const columnsTrimestralSectores = [
            { Header: "", accessor: 'letra' },
            { Header: 'Sector', accessor: 'sector' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsTrimestralRequerimientosInformados = [
            { Header: "", accessor: 'letra' },
            { Header: "Requerimiento Informado", accessor: 'requerimiento_informado' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsTrimestralDerivadosServiciosMunicipales = [
            { Header: "", accessor: 'letra' },
            { Header: "Derivados Servicio Municipal", accessor: 'servicio_municipal' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsTrimestralRangoHora = [
            { Header: "Rango Hora", accessor: 'horario' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]


  const columnsTrimestralIncivilidades = [
            { Header: "", accessor: 'letra' },
            { Header: "Incivilidades", accessor: 'requerimiento' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsTrimestralPatrullajeMovil = [
            { Header: "", accessor: 'letra' },
            { Header: "Patrullaje Movil - Avistamientos terreno (WSP)", accessor: 'requerimiento' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsTrimestralPatrullajeCiclovias = [
            { Header: "", accessor: 'letra' },
            { Header: "Patrullaje Ciclovías", accessor: 'requerimiento' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },
            { Header: 'TRIM.', accessor: 'total_trimestre' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]


  const columnsTrimestralMovilTerreno = [
            { Header: "", accessor: 'requerimiento' },
            { Header: trimestreSeleccionado, accessor: 'total_mes_1' },
            { Header: trimestreSeleccionado2, accessor: 'total_mes_2' },
            { Header: trimestreSeleccionado3, accessor: 'total_mes_3' },

          ]






  const columnsMensual = [
            { Header: "", accessor: 'letra' },
            { Header: 'Requerimiento', accessor: 'requerimiento' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualSectores = [
            { Header: "", accessor: 'letra' },
            { Header: 'Sector', accessor: 'sector' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualRequerimientosInformados = [
            { Header: "", accessor: 'letra' },
            { Header: 'Requerimiento Informado', accessor: 'requerimiento_informado' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualDerivadosServiciosMunicipales = [
            { Header: "", accessor: 'letra' },
            { Header: 'Derivados Servicio Municipal', accessor: 'servicio_municipal' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualRangoHora = [
            { Header: "Rango Hora", accessor: 'horario' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]


  const columnsMensualIncivilidades = [
            { Header: "", accessor: 'letra' },
            { Header: "Incivilidades", accessor: 'requerimiento' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualPatrullajeMovil = [
            { Header: "", accessor: 'letra' },
            { Header: "Patrullaje Movil - Avistamientos terreno (WSP)", accessor: 'requerimiento' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualPatrullajeCiclovias = [
            { Header: "", accessor: 'letra' },
            { Header: "Patrullaje Ciclovías", accessor: 'requerimiento' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' },
            { Header: '%', accessor: 'porcentaje_total' }
          ]

  const columnsMensualMovilTerreno = [
            { Header: "Movil a terreno", accessor: 'requerimiento' },
            { Header: mesSeleccionado, accessor: 'total_mes_1' }
          ]




  const [mesFiltrado, setMesFiltrado] = useState(null)
  const [trimestreFiltrado, setTrimestreFiltrado] = useState(null)

  const [trimestralPrevencionSeguridad, setTrimestralPrevencionSeguridad] = useState([])
  const [trimestralServiciosMunicipales, setTrimestralServiciosMunicipales] = useState([])
  const [trimestralServiciosComunidad, setTrimestralServiciosComunidad] = useState([])
  const [trimestralEmergencias, setTrimestralEmergencias] = useState([])
  const [trimestralSectores, setTrimestralSectores] = useState([])
  const [trimestralRequerimientosInformados, setTrimestralRequerimientosInformados] = useState([])
  const [trimestralDerivadosServiciosMunicipales, setTrimestralDerivadosServiciosMunicipales] = useState([])
  const [trimestralRangoHora, setTrimestralRangoHora] = useState([])
  const [trimestralIncivilidades, setTrimestralIncivilidades] = useState([])
  const [trimestralPatrullajeMovil, setTrimestralPatrullajeMovil] = useState([])
  const [trimestralPatrullajeCiclovias, setTrimestralPatrullajeCiclovias] = useState([])
  const [trimestralMovilTerreno, setTrimestralMovilTerreno] = useState([])

  const [mensualPrevencionSeguridad, setMensualPrevencionSeguridad] = useState([])
  const [mensualServiciosMunicipales, setMensualServiciosMunicipales] = useState([])
  const [mensualServiciosComunidad, setMensualServiciosComunidad] = useState([])
  const [mensualEmergencias, setMensualEmergencias] = useState([])
  const [mensualSectores, setMensualSectores] = useState([])
  const [mensualRequerimientosInformados, setMensualRequerimientosInformados] = useState([])
  const [mensualDerivadosServiciosMunicipales, setMensualDerivadosServiciosMunicipales] = useState([])
  const [mensualRangoHora, setMensualRangoHora] = useState([])
  const [mensualIncivilidades, setMensualIncivilidades] = useState([])
  const [mensualPatrullajeMovil, setMensualPatrullajeMovil] = useState([])
  const [mensualPatrullajeCiclovias, setMensualPatrullajeCiclovias] = useState([])
  const [mensualMovilTerreno, setMensualMovilTerreno] = useState([])

  const clearDataTrimestral = () => {
    setTrimestralPrevencionSeguridad([]);
    setTrimestralServiciosMunicipales([]);
    setTrimestralServiciosComunidad([]);
    setTrimestralEmergencias([]);
    setTrimestralSectores([]);
    setTrimestralRequerimientosInformados([]);
    setTrimestralDerivadosServiciosMunicipales([]);
    setTrimestralRangoHora([]);
    setTrimestralIncivilidades([]);
    setTrimestralPatrullajeMovil([]);
    setTrimestralPatrullajeCiclovias([]);
    setTrimestralMovilTerreno([]);
  }

  const clearDataMensual = () => {
    setMensualPrevencionSeguridad([]);
    setMensualServiciosMunicipales([]);
    setMensualServiciosComunidad([]);
    setMensualEmergencias([]);
    setMensualSectores([]);
    setMensualRequerimientosInformados([]);
    setMensualDerivadosServiciosMunicipales([]);
    setMensualRangoHora([]);
    setMensualIncivilidades([]);
    setMensualPatrullajeMovil([]);
    setMensualPatrullajeCiclovias([]);
    setMensualMovilTerreno([]);
  }
  // useEffect(() => {

  //   dispatch(obtenerRequerimientos(0, moment(fechaInicio).format('DD-MM-YYYY'), moment(fechaFinal).format('DD-MM-YYYY'))).then(result => { setRequerimientos(result) })

  // }, [dispatch])


  const [trimestralServiciosComunidad_graph_data, setTrimestralServiciosComunidad_graph_data] = useState([])
  const [trimestralServiciosMunicipales_graph_data, setTrimestralServiciosMunicipales_graph_data] = useState([])
  const [trimestralPrevencionSeguridad_graph_data, setTrimestralPrevencionSeguridad_graph_data] = useState([])
  const [trimestralEmergencias_graph_data, setTrimestralEmergencias_graph_data] = useState([])
  const [trimestralSectores_graph_data, setTrimestralSectores_graph_data] = useState([])
  const [trimestralRequerimientosInformados_graph_data, setTrimestralRequerimientosInformados_graph_data] = useState([])
  const [trimestralDerivadosServiciosMunicipales_graph_data, setTrimestralDerivadosServiciosMunicipales_graph_data] = useState([])
  const [trimestralIncivilidades_graph_data, setTrimestralIncivilidades_graph_data] = useState([])
  const [trimestralPatrullajeMovil_graph_data, setTrimestralPatrullajeMovil_graph_data] = useState([])
  const [trimestralPatrullajeCiclovias_graph_data, setTrimestralPatrullajeCiclovias_graph_data] = useState([])

  const [mensualServiciosComunidad_graph_data, setMensualServiciosComunidad_graph_data] = useState([])
  const [mensualServiciosMunicipales_graph_data, setMensualServiciosMunicipales_graph_data] = useState([])
  const [mensualPrevencionSeguridad_graph_data, setMensualPrevencionSeguridad_graph_data] = useState([])
  const [mensualEmergencias_graph_data, setMensualEmergencias_graph_data] = useState([])
  const [mensualSectores_graph_data, setMensualSectores_graph_data] = useState([])
  const [mensualRequerimientosInformados_graph_data, setMensualRequerimientosInformados_graph_data] = useState([])
  const [mensualDerivadosServiciosMunicipales_graph_data, setMensualDerivadosServiciosMunicipales_graph_data] = useState([])
  const [mensualIncivilidades_graph_data, setMensualIncivilidades_graph_data] = useState([])
  const [mensualPatrullajeMovil_graph_data, setMensualPatrullajeMovil_graph_data] = useState([])
  const [mensualPatrullajeCiclovias_graph_data, setMensualPatrullajeCiclovias_graph_data] = useState([])




  const handleActualizarFiltros = () => {


    if(tipoReporte==2) {

      if(trimestre === null) {
        cogoToast.error(<div> <b>ERROR</b> <div>Debe seleccionar un Trimestre.</div> </div>);
        document.getElementById("trimestre").focus();
      }
      else {
        clearDataMensual();
        clearDataTrimestral();
        setTrimestreFiltrado(trimestre);



        dispatch(obtenerConsolidadoRequerimientosTrimestral( "26, 14, 15, 13, 16, 17, 22, 34, 32, 35, 24", moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralPrevencionSeguridad(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralPrevencionSeguridad_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosTrimestral( "8, 19, 3, 2, 1, 6, 5, 7, 4, 18, 30, 36, 37, 38, 39, 40, 41, 42, 43", moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralServiciosMunicipales(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}));

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralServiciosMunicipales_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosTrimestral( "12, 28, 23, 25, 9, 29, 31, 33, 20", moment(trimestre).format('DD/MM/YYYY') )).then(result => {
          setTrimestralServiciosComunidad(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}));

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralServiciosComunidad_graph_data(chartData)

        })


        dispatch(obtenerConsolidadoRequerimientosTrimestral( "10, 21, 27, 11", moment(trimestre).format('DD/MM/YYYY'))).then(result => {

          setTrimestralEmergencias(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralEmergencias_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoSectoresTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralSectores(result.map( (a,i) => {if(a.sector === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.sector !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Sector', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralSectores_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosInformadosTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralRequerimientosInformados(result.map( (a,i) => {if(a.requerimiento_informado === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento_informado !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralRequerimientosInformados_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoDerivadosServiciosMunicipalesTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralDerivadosServiciosMunicipales(result.map( (a,i) => {if(a.servicio_municipal === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.servicio_municipal !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Servicio Municipal', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralDerivadosServiciosMunicipales_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRangoHoraTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralRangoHora(result)

        })


        dispatch(obtenerConsolidadoRequerimientosTrimestral( "5, 13, 23, 15, 12, 25, 4, 32, 33, 34, 35", moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralIncivilidades(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Servicio Municipal', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralIncivilidades_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoPatrullajeMovilTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralPatrullajeMovil(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralPatrullajeMovil_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoPatrullajeCicloviasTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {

          setTrimestralPatrullajeCiclovias(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]

          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setTrimestralPatrullajeCiclovias_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoMovilTerrenoTrimestral( moment(trimestre).format('DD/MM/YYYY') )).then(result => {
          console.log(result)
          setTrimestralMovilTerreno(result)

        })




      }

    }
    else if(tipoReporte==1) {

      if(mes === null) {
        cogoToast.error(<div> <b>ERROR</b> <div>Debe seleccionar un Mes.</div> </div>);
        document.getElementById("mes").focus();
      }
      else {
        clearDataMensual();
        clearDataTrimestral();
        setMesFiltrado(mes);



        dispatch(obtenerConsolidadoRequerimientosMensual("26, 14, 15, 13, 16, 17, 22, 34, 32, 35, 24", moment(mes).format('DD/MM/YYYY'))).then(result => {

          setMensualPrevencionSeguridad(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualPrevencionSeguridad_graph_data(chartData)

        })




        dispatch(obtenerConsolidadoRequerimientosMensual("8, 19, 3, 2, 1, 6, 5, 7, 4, 18, 30, 36, 37, 38, 39, 40, 41, 42, 43", moment(mes).format('DD/MM/YYYY'))).then(result => {

          setMensualServiciosMunicipales(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualServiciosMunicipales_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosMensual("12, 28, 23, 25, 9, 29, 31, 33, 20", moment(mes).format('DD/MM/YYYY'))).then(result => {

          setMensualServiciosComunidad(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualServiciosComunidad_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosMensual("10, 21, 27, 11", moment(mes).format('DD/MM/YYYY'))).then(result => {

          setMensualEmergencias(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualEmergencias_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoSectoresMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {

          setMensualSectores(result.map( (a,i) => {if(a.sector === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.sector !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Sector', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualSectores_graph_data(chartData)

        })



        dispatch(obtenerConsolidadoRequerimientosInformadosMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {

          setMensualRequerimientosInformados(result.map( (a,i) => {if(a.requerimiento_informado === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento_informado !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento Informado', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualRequerimientosInformados_graph_data(chartData)

        })




        dispatch(obtenerConsolidadoDerivadosServiciosMunicipalesMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {

          setMensualDerivadosServiciosMunicipales(result.map( (a,i) => {if(a.servicio_municipal === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.servicio_municipal !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento Informado', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualDerivadosServiciosMunicipales_graph_data(chartData)

        })




        dispatch(obtenerConsolidadoRangoHoraMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {
          setMensualRangoHora(result)
        })




        dispatch(obtenerConsolidadoRequerimientosMensual( "5, 13, 23, 15, 12, 25, 4, 32, 33, 34, 35", moment(mes).format('DD/MM/YYYY') )).then(result => {

          setMensualIncivilidades(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {return {...a, letra: "["+String.fromCharCode('A'.charCodeAt() + i)+"]" }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualIncivilidades_graph_data(chartData)

        })




        dispatch(obtenerConsolidadoPatrullajeMovilMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {


          setMensualPatrullajeMovil(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualPatrullajeMovil_graph_data(chartData)

        })




        dispatch(obtenerConsolidadoPatrullajeCicloviasMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {


          setMensualPatrullajeCiclovias(result.map( (a,i) => {if(a.requerimiento === null ) return {...a}; if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const resultForGraph = Object.values(result.filter(result => result.requerimiento !== null).map( (a,i) => {if(i===26) i=32; else if(i===27) i=33;else if(i===28) i=34;else if(i===29) i=35;else if(i===30) i=36;else if(i===31) i=37;else if(i===32) i=38; else if(i===33) i=39; else if(i===34) i=40; return {...a, letra: String.fromCharCode('A'.charCodeAt() + i) }}))

          const chartData = [['Requerimiento', 'Porcentaje Total']]
          for (let i = 0; i < resultForGraph.length; i += 1) {
            chartData.push([resultForGraph[i].letra + " " + resultForGraph[i].porcentaje_total+ "%" , parseInt(resultForGraph[i].porcentaje_total) ])
          }
          setMensualPatrullajeCiclovias_graph_data(chartData)

        })






        dispatch(obtenerConsolidadoMovilTerrenoMensual( moment(mes).format('DD/MM/YYYY') )).then(result => {
          setMensualMovilTerreno(result)
        })




      }

    }
  }


  const [tipoReporte, setTipoReporte] = useState(1)

  const handleSetTipoReporte = (e) => {

    if (e.target.value == 1)
      setTrimestre(null);
    else if(e.target.value == 2)
      setMes(null);

    setTipoReporte(e.target.value);


  }


  const getCurrentDate = () => {

    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let seconds = date_ob.getSeconds();

    return year + "" + month + "" + date + "_" + hours + "" + minutes + "" + seconds

  }


  const downloadExcelRequerimientosTrimestral = () => {

    const token = localStorage.getItem('token') || '';

		fetch(APP_CONFIG.apiUrl + '/reportes/consolidado-requerimientos-trimestral/excel/?trimestre='+moment(trimestreFiltrado).format('DD/MM/YYYY'),  { headers: {'x-token': token} } )
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = getCurrentDate() + "_" + "consolidado_requerimientos_trimestral.xlsx";
					a.click();
				});
		});
	}


  const downloadExcelRequerimientosMensual = () => {

    const token = localStorage.getItem('token') || '';

		fetch(APP_CONFIG.apiUrl + '/reportes/consolidado-requerimientos-mensual/excel/?mes='+moment(mesFiltrado).format('DD/MM/YYYY'),  { headers: {'x-token': token} } )
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = getCurrentDate() + "_" + "consolidado_requerimientos_mensual.xlsx";
					a.click();
				});
		});
	}




  // BROWN
  //  const colors = [ '#795548', '#EFEBE9', '#D7CCC8', '#BCAAA4', '#A1887F', '#8D6E63', '#795548', '#6D4C41', '#5D4037', '#4E342E', '#3E2723', '#9E9E9E', '#FAFAFA', '#F5F5F5', '#EEEEEE', '#E0E0E0', '#BDBDBD', '#9E9E9E', '#757575', '#616161', '#424242', '#212121', '#607D8B', ];

  // BLUE
   const colorsBlue = [ '#424242', '#607D8B', '#CFD8DC', '#B0BEC5', '#90A4AE', '#78909C', '#607D8B', '#546E7A', '#455A64', '#37474F', '#263238', ];

  // PINK
  // const colors = [ '#E91E63', '#FCE4EC', '#F8BBD0', '#F48FB1', '#F06292', '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457', '#880E4F', '#FF80AB', ];

  // VIOLETT
  const colors = [ '#673AB7', '#EDE7F6', '#D1C4E9', '#B39DDB', '#9575CD', '#7E57C2', '#673AB7', '#5E35B1', '#512DA8', '#4527A0', '#311B92', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA', ];


  const trimestralRangoHora_graph_data = {
    labels: trimestralRangoHora.filter(result => result.horario !== null).map(a =>  a.horario),
    datasets: [{
      data: trimestralRangoHora.filter(result => result.horario !== null).map(a => a.total_trimestre),
      backgroundColor: colorsGraph,
      borderWidth: 2,
      backgroundColor: '#DADFF3',
      borderColor: '#7C7AC7',
    }]
  };


  const mensualRangoHora_graph_data = {
    labels: mensualRangoHora.filter(result => result.horario !== null).map(a =>  a.horario),
    datasets: [{
      data: mensualRangoHora.filter(result => result.horario !== null).map(a => a.total_mes_1),
      backgroundColor: colorsGraph,
      borderWidth: 2,
      backgroundColor: '#DADFF3',
      borderColor: '#7C7AC7',

    }]
  };




  const pie_graph_global_options = {
    legend: {
    	display: false
    },
    responsive: true,
    maintainAspectRatio: true,

  }




  const downloadImage_trimestralPrevencionSeguridad = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_prevencion_seguridad"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_prevencion_seguridad.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralServiciosMunicipales = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_servicios_municipales"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_servicios_municipales.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralServiciosComunidad = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_servicios_comunidad"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_servicios_comunidad.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralEmergencias = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_emergencias"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_emergencias.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralSectores = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_sectores"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_sectores.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralRequerimientosInformados = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_requerimientos_informados"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_requerimientos_informados.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralDerivadosServiciosMunicipales = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_derivados_servicios_municipales"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_derivados_servicios_municipales.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralRangoHora = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_rango_hora"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_rango_hora.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralIncivilidades = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_incivilidades"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_incivilidades.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralPatrullajeMovil = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_patrullaje_movil"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_patrullaje_movil.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_trimestralPatrullajeCiclovias = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_patrullaje_ciclovias"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_patrullaje_ciclovias.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }


  const downloadImage_trimestralMovilTerreno = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("trimestral_movil_terreno"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'trimestral_movil_terreno.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }





  const downloadImage_mensualPrevencionSeguridad = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_prevencion_seguridad"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_prevencion_seguridad.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualServiciosMunicipales = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_servicios_municipales"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_servicios_municipales.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualServiciosComunidad = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_servicios_comunidad"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_servicios_comunidad.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualEmergencias = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_emergencias"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_emergencias.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualSectores = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_sectores"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_sectores.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualRequerimientosInformados = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_requerimientos_informados"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_requerimientos_informados.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualDerivadosServiciosMunicipales = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_derivados_servicios_municipales"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_derivados_servicios_municipales.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualRangoHora = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_rango_hora"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_rango_hora.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualIncivilidades = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_incivilidades"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_incivilidades.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }

  const downloadImage_mensualPatrullajeMovil = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_patrullaje_movil"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_patrullaje_movil.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }


  const downloadImage_mensualPatrullajeCiclovias = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_patrullaje_ciclovias"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_patrullaje_ciclovias.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }


  const downloadImage_mensualMovilTerreno = (tipo) => {

    htmlToImage.toJpeg(document.getElementById("mensual_movil_terreno"), { quality: 0.99 })
      .then(function (dataUrl) {

        var link = document.createElement('a');
        link.download = getCurrentDate() + "_" +'mensual_movil_terreno.jpeg';
        link.href = dataUrl;
        link.click();

      });
  }





  // INICIO___EXPORTAR_IMAGENES_A_WORD: Funcionalidad deshabilitada por no lograr escalar el tamaño de la imagen de manera proporcional.
  const generateDocxMensual = async () => {

    dispatch( appSetLoader(true, "Generando documento .docx") );

    let img_mensual_prevencion_seguridad = await htmlToImage.toJpeg(document.getElementById("mensual_prevencion_seguridad"), { quality: 0.99 });
    let img_mensual_prevencion_seguridad_DIM = document.getElementById('mensual_prevencion_seguridad');
    let img_mensual_servicios_municipales = await htmlToImage.toJpeg(document.getElementById("mensual_servicios_municipales"), { quality: 0.99 });
    let img_mensual_servicios_municipales_DIM = document.getElementById('mensual_servicios_municipales');
    let img_mensual_servicios_comunidad = await htmlToImage.toJpeg(document.getElementById("mensual_servicios_comunidad"), { quality: 0.99 });
    let img_mensual_servicios_comunidad_DIM = document.getElementById('mensual_servicios_comunidad');
    let img_mensual_emergencias = await htmlToImage.toJpeg(document.getElementById("mensual_emergencias"), { quality: 0.99 });
    let img_mensual_emergencias_DIM = document.getElementById('mensual_emergencias');
    let img_mensual_sectores = await htmlToImage.toJpeg(document.getElementById("mensual_sectores"), { quality: 0.99 });
    let img_mensual_sectores_DIM = document.getElementById('mensual_sectores');
    let img_mensual_requerimientos_informados = await htmlToImage.toJpeg(document.getElementById("mensual_requerimientos_informados"), { quality: 0.99 });
    let img_mensual_requerimientos_informados_DIM = document.getElementById('mensual_requerimientos_informados');
    let img_mensual_derivados_servicios_municipales = await htmlToImage.toJpeg(document.getElementById("mensual_derivados_servicios_municipales"), { quality: 0.99 });
    let img_mensual_derivados_servicios_municipales_DIM = document.getElementById('mensual_derivados_servicios_municipales');
    let img_mensual_rango_hora = await htmlToImage.toJpeg(document.getElementById("mensual_rango_hora"), { quality: 0.99 });
    let img_mensual_rango_hora_DIM = document.getElementById('mensual_rango_hora');
    let img_mensual_incivilidades = await htmlToImage.toJpeg(document.getElementById("mensual_incivilidades"), { quality: 0.99 });
    let img_mensual_incivilidades_DIM = document.getElementById('mensual_incivilidades');
    let img_mensual_patrullaje_movil = await htmlToImage.toJpeg(document.getElementById("mensual_patrullaje_ciclovias"), { quality: 0.99 });
    let img_mensual_patrullaje_movil_DIM = document.getElementById('mensual_patrullaje_ciclovias');
    let img_mensual_patrullaje_ciclovias = await htmlToImage.toJpeg(document.getElementById("mensual_patrullaje_ciclovias"), { quality: 0.99 });
    let img_mensual_patrullaje_ciclovias_DIM = document.getElementById('mensual_patrullaje_ciclovias');
    let img_mensual_movil_terreno = await htmlToImage.toJpeg(document.getElementById("mensual_movil_terreno"), { quality: 0.99 });
    let img_mensual_movil_terreno_DIM = document.getElementById('mensual_movil_terreno');

        const doc = new Document({
          sections: [
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: img_mensual_prevencion_seguridad,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_prevencion_seguridad_DIM.clientHeight/img_mensual_prevencion_seguridad_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_servicios_municipales,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_servicios_municipales_DIM.clientHeight/img_mensual_servicios_municipales_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_servicios_comunidad,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_servicios_comunidad_DIM.clientHeight/img_mensual_servicios_comunidad_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_emergencias,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_emergencias_DIM.clientHeight/img_mensual_emergencias_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_sectores,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_sectores_DIM.clientHeight/img_mensual_sectores_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_requerimientos_informados,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_requerimientos_informados_DIM.clientHeight/img_mensual_requerimientos_informados_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_derivados_servicios_municipales,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_derivados_servicios_municipales_DIM.clientHeight/img_mensual_derivados_servicios_municipales_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_rango_hora,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_rango_hora_DIM.clientHeight/img_mensual_rango_hora_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_incivilidades,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_incivilidades_DIM.clientHeight/img_mensual_incivilidades_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_patrullaje_movil,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_patrullaje_movil_DIM.clientHeight/img_mensual_patrullaje_movil_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_mensual_patrullaje_ciclovias,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_patrullaje_ciclovias_DIM.clientHeight/img_mensual_patrullaje_ciclovias_DIM.clientWidth
                      }
                    })
                    ,
                    new ImageRun({
                      data: img_mensual_movil_terreno,
                      transformation: {
                        width: 600,
                        height: 600*img_mensual_movil_terreno_DIM.clientHeight/img_mensual_movil_terreno_DIM.clientWidth
                      }
                    })
                  ]
                })
              ]
            }
          ]
        })


    Packer.toBlob(doc).then(blob => {
      dispatch( appSetLoader(false, "") );
      saveAs(blob, "Reporte_Mensual.docx");
    });


  }



  const generateDocxTrimestral = async () => {

    dispatch( appSetLoader(true, "Generando documento .docx") );

    let img_trimestral_prevencion_seguridad = await htmlToImage.toJpeg(document.getElementById("trimestral_prevencion_seguridad"), { quality: 0.99 });
    let img_trimestral_prevencion_seguridad_DIM = document.getElementById('trimestral_prevencion_seguridad');
    let img_trimestral_servicios_municipales = await htmlToImage.toJpeg(document.getElementById("trimestral_servicios_municipales"), { quality: 0.99 });
    let img_trimestral_servicios_municipales_DIM = document.getElementById('trimestral_servicios_municipales');
    let img_trimestral_servicios_comunidad = await htmlToImage.toJpeg(document.getElementById("trimestral_servicios_comunidad"), { quality: 0.99 });
    let img_trimestral_servicios_comunidad_DIM = document.getElementById('trimestral_servicios_comunidad');
    let img_trimestral_emergencias = await htmlToImage.toJpeg(document.getElementById("trimestral_emergencias"), { quality: 0.99 });
    let img_trimestral_emergencias_DIM = document.getElementById('trimestral_emergencias');
    let img_trimestral_sectores = await htmlToImage.toJpeg(document.getElementById("trimestral_sectores"), { quality: 0.99 });
    let img_trimestral_sectores_DIM = document.getElementById('trimestral_sectores');
    let img_trimestral_requerimientos_informados = await htmlToImage.toJpeg(document.getElementById("trimestral_requerimientos_informados"), { quality: 0.99 });
    let img_trimestral_requerimientos_informados_DIM = document.getElementById('trimestral_requerimientos_informados');
    let img_trimestral_derivados_servicios_municipales = await htmlToImage.toJpeg(document.getElementById("trimestral_derivados_servicios_municipales"), { quality: 0.99 });
    let img_trimestral_derivados_servicios_municipales_DIM = document.getElementById('trimestral_derivados_servicios_municipales');
    let img_trimestral_rango_hora = await htmlToImage.toJpeg(document.getElementById("trimestral_rango_hora"), { quality: 0.99 });
    let img_trimestral_rango_hora_DIM = document.getElementById('trimestral_rango_hora');
    let img_trimestral_incivilidades = await htmlToImage.toJpeg(document.getElementById("trimestral_incivilidades"), { quality: 0.99 });
    let img_trimestral_incivilidades_DIM = document.getElementById('trimestral_incivilidades');
    let img_trimestral_patrullaje_movil = await htmlToImage.toJpeg(document.getElementById("trimestral_patrullaje_ciclovias"), { quality: 0.99 });
    let img_trimestral_patrullaje_movil_DIM = document.getElementById('trimestral_patrullaje_ciclovias');
    let img_trimestral_patrullaje_ciclovias = await htmlToImage.toJpeg(document.getElementById("trimestral_patrullaje_ciclovias"), { quality: 0.99 });
    let img_trimestral_patrullaje_ciclovias_DIM = document.getElementById('trimestral_patrullaje_ciclovias');
    let img_trimestral_movil_terreno = await htmlToImage.toJpeg(document.getElementById("trimestral_movil_terreno"), { quality: 0.99 });
    let img_trimestral_movil_terreno_DIM = document.getElementById('trimestral_movil_terreno');

        const doc = new Document({
          sections: [
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: img_trimestral_prevencion_seguridad,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_prevencion_seguridad_DIM.clientHeight/img_trimestral_prevencion_seguridad_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_servicios_municipales,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_servicios_municipales_DIM.clientHeight/img_trimestral_servicios_municipales_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_servicios_comunidad,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_servicios_comunidad_DIM.clientHeight/img_trimestral_servicios_comunidad_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_emergencias,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_emergencias_DIM.clientHeight/img_trimestral_emergencias_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_sectores,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_sectores_DIM.clientHeight/img_trimestral_sectores_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_requerimientos_informados,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_requerimientos_informados_DIM.clientHeight/img_trimestral_requerimientos_informados_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_derivados_servicios_municipales,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_derivados_servicios_municipales_DIM.clientHeight/img_trimestral_derivados_servicios_municipales_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_rango_hora,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_rango_hora_DIM.clientHeight/img_trimestral_rango_hora_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_incivilidades,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_incivilidades_DIM.clientHeight/img_trimestral_incivilidades_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_patrullaje_movil,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_patrullaje_movil_DIM.clientHeight/img_trimestral_patrullaje_movil_DIM.clientWidth
                      }
                    }),
                    new ImageRun({
                      data: img_trimestral_patrullaje_ciclovias,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_patrullaje_ciclovias_DIM.clientHeight/img_trimestral_patrullaje_ciclovias_DIM.clientWidth
                      }
                    })
                    ,
                    new ImageRun({
                      data: img_trimestral_movil_terreno,
                      transformation: {
                        width: 600,
                        height: 600*img_trimestral_movil_terreno_DIM.clientHeight/img_trimestral_movil_terreno_DIM.clientWidth
                      }
                    })
                  ]
                })
              ]
            }
          ]
        })


    Packer.toBlob(doc).then(blob => {
      dispatch( appSetLoader(false, "") );
      saveAs(blob, "Reporte_Trimestral.docx");
    });


  }
  // FIN___EXPORTAR_IMAGENES_A_WORD








  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div id="reporte_mensual_trimestral_conainer" className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4 font-sans">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-col  justify-between mb-3">

                  <div className="  flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faColumns}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Reporte: Mensual-Trimestral
                    </span>
                  </div>


                  <div className=" px-2 mt-0 mb-0 bg-gray-200 p-4 rounded-lg w-full">
                    <div className="flex flex-col md:flex-row w-1/2">


                      <div className="w-full px-3 mb-5 md:mb-0 mr-5">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                          Periodo:
                        </label>

                        <select className = ' select-temuco-2 inline-block cursor-pointer w-48 bg-white border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                        onChange={e => handleSetTipoReporte(e) }
                        // value={formValues.sector_id}
                        >
                        <option value = "1" > Mensual </option>
                        <option value = "2" > Trimestral </option>

                        </select>

                      </div>

                      { tipoReporte == 1 ?
                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                          MES:
                        </label>

                        <DatePicker
                          id="mes"
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={mes}
                          onChange={date => setMes(date)}
                          locale="es"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          minDate={new Date("04/01/2021")}
                          maxDate={new Date()}
                          placeholderText="Seleccione"
                          />
                      </div>
                      : null }

                      { tipoReporte == 2 ?
                      <div className="w-full px-3 mb-5 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2" htmlFor="fecha_final">
                        TRIMESTRE:
                        </label>

                        <DatePicker
                          id="trimestre"
                          className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
                          selected={trimestre}
                          onChange={date => setTrimestre(date)}
                          locale="es"
                          dateFormat="yyyy, QQQ"
                          showQuarterYearPicker
                          minDate={new Date("04/01/2021")}
                          maxDate={new Date()}
                          placeholderText="Seleccione"
                        />
                      </div>
                      : null }


                      <div className="w-full px-3 mb-5 md:mb-0 pt-7 ml-7">

                        <button className="bg-gray-600 hover:bg-gray-700 text-white p-x-5 py-2 rounded w-48 "  onClick={handleActualizarFiltros}>Actualizar resultados</button>
                      </div>

                    </div>
                  </div>



                </div>

                <hr className="border-gray-100 py-2" />


                <div className="py-0 bg-white rounded-lg ">

                  {tipoReporte == 2 && trimestralPrevencionSeguridad.length > 0 && trimestre !== null ?
                  <>


                    {/* BEGIN: RESUMEN FILTROS SELECCIONADOS */}
                    <div className="px-2 mt-0 mb-5 bg-gray-200 p-4 rounded-lg w-full text-base text-black">

                      <div className="flex flex-row w-full ">

                          <div className="flex flex-col w-full">

                            <div className="flex flex-row px-4">
                              <div className="w-96 font-bold">Tipo de reporte:</div>
                              <div className="w-64 font-semibold">Trimestral</div>
                            </div>



                            <div className="flex flex-row pt-2 px-4">
                              <div className="w-96 font-bold whitespace-nowrap">Total Requerimientos [{ moment(trimestreFiltrado).format('MMMM').toUpperCase() } { moment(trimestreFiltrado).format('YYYY').toUpperCase() }]: </div>
                              <div className="w-64 font-normal">
                              {trimestralRequerimientosInformados.filter(result => result.requerimiento_informado !== null).reduce((pv, cv) => parseInt(pv) + parseInt(cv.total_mes_1), 0)}
                              </div>
                            </div>

                            <div className="flex flex-row pt-2 px-4">
                              <div className="w-96 font-bold whitespace-nowrap">Total Requerimientos [{ moment(trimestreFiltrado).add(1, 'M').format('MMMM').toUpperCase() } { moment(trimestreFiltrado).format('YYYY').toUpperCase() }]: </div>
                              <div className="w-64 font-normal">
                              {trimestralRequerimientosInformados.filter(result => result.requerimiento_informado !== null).reduce((pv, cv) => parseInt(pv) + parseInt(cv.total_mes_2), 0)}
                              </div>
                            </div>

                            <div className="flex flex-row pt-2 px-4">
                              <div className="w-96 font-bold whitespace-nowrap">Total Requerimientos [{ moment(trimestreFiltrado).add(2, 'M').format('MMMM').toUpperCase() } { moment(trimestreFiltrado).format('YYYY').toUpperCase() }]: </div>
                              <div className="w-64 font-normal">
                              {trimestralRequerimientosInformados.filter(result => result.requerimiento_informado !== null).reduce((pv, cv) => parseInt(pv) + parseInt(cv.total_mes_3), 0)}
                              </div>
                            </div>

                          </div>

                          <div className="flex-grow h-12">
                          </div>

                          <div className="text-right pt-0 pr-2 ">
                            <div className="w-full text-right pt-0 pr-2 flex flex-col ">
                              <button
                                onClick={downloadExcelRequerimientosTrimestral}
                                className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded mt-1 w-56 text-center">
                              Exportar a Excel
                              </button>

                              <button onClick={generateDocxTrimestral}
                                className="bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mt-1 w-56 text-center">Exportar a Word</button>

                            </div>
                          </div>

                        </div>

                    </div>
                    {/* END: RESUMEN FILTROS SELECCIONADOS */}



                    {/* <div className=" text-xl font-bold leading-tight pt-8 pl-4 my-3">
                      LLAMADOS FONO EMERGENCIAS 1409
                    </div> */}

                    <hr className="mx-3" />




                    <button onClick={(downloadImage_trimestralPrevencionSeguridad)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-5 ml-4">Descargar gráfico</button>
                    <div id="trimestral_prevencion_seguridad" className="px-4 py-4 pt-1 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                        CATEGORÍA PREVENCIÓN Y SEGURIDAD
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'450px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralPrevencionSeguridad_graph_data}
                            options={{
                              colors: colorsGraph,
                              title: 'Porcentaje de requerimientos en categoría Prevención y Seguridad',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                              //chartArea:{top: 0, height:'100%'}
                            }}
                            rootProps={{ 'data-testid': '1' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                            <TableReporteMensualTrimestral columns={columnsTrimestral} data={trimestralPrevencionSeguridad} />
                          </div>
                        </div>


                      </div>

                    </div>

                    <hr className="border-gray-300 py-2 my-5" />





                    <button onClick={(downloadImage_trimestralServiciosMunicipales)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_servicios_municipales" className=" px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="flex flex-row">
                        <div className="text-gray-900 text-lg font-bold leading-tight py-3 pt-1 pl-2">
                        CATEGORÍA SERVICIOS MUNICIPALES
                        </div>


                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralServiciosMunicipales_graph_data}
                            options={{
                              colors: colorsGraph,
                              title: 'Porcentaje de requerimientos en categoría Servicios Municipales',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '2' }}
                          />
                      </div>



                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                            <TableReporteMensualTrimestral columns={columnsTrimestral} data={trimestralServiciosMunicipales} />
                          </div>
                        </div>


                      </div>

                    </div>

                    <hr className="border-gray-300 py-2 my-5" />




                    <button onClick={(downloadImage_trimestralServiciosComunidad)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_servicios_comunidad" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                    <div className="flex flex-row">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 pt-1 pl-2">
                      CATEGORÍA SERVICIOS A LA COMUNIDAD
                      </div>

                    </div>

                      <div className="flex flex-row w-full">


                        <div className=" w-2/5 text-center">
                            <Chart
                              width={'100%'}
                              height={'400px'}
                              chartType="PieChart"
                              loader={<div>Cargando gráfico</div>}
                              data={trimestralServiciosComunidad_graph_data}
                              options={{
                                title: 'Porcentaje de requerimientos en categoría Servicios a la Comunidad',
                                legend: 'none',
                                pieSliceText: 'label',
                                pieStartAngle: 50,
                                xAxis: { textStyle: { fontSize: 16  } },
                                titleTextStyle: {
                                      fontSize: 16
                                  },
                              }}
                              rootProps={{ 'data-testid': '3' }}
                            />
                          </div>


                        <div className=" flex-1 w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestral} data={trimestralServiciosComunidad} />
                          </div>
                        </div>


                      </div>

                    </div>



                    <hr className="border-gray-300 py-2 my-5" />



                    <button onClick={(downloadImage_trimestralEmergencias)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_emergencias" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white ">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      CATEGORÍA EMERGENCIAS
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">


                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralEmergencias_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos en categoría Emergencias',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '4' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestral} data={trimestralEmergencias} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_trimestralSectores)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_sectores" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      SECTORES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralSectores_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos en categoría Sectores',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '5' }}
                          />
                        </div>

                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestralSectores} data={trimestralSectores} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_trimestralRequerimientosInformados)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>


                    <div id="trimestral_requerimientos_informados" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      REQUERIMIENTOS INFORMADOS
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralRequerimientosInformados_graph_data}
                            options={{
                              title: 'Porcentaje de Requerimientos Informados',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '6' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestralRequerimientosInformados} data={trimestralRequerimientosInformados} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_trimestralDerivadosServiciosMunicipales)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div  id="trimestral_derivados_servicios_municipales" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      DERIVADOS SERVICIOS MUNICIPALES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralDerivadosServiciosMunicipales_graph_data}
                            options={{
                              title: 'Porcentaje de Derivaciones a Servicios Municipales',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '5' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestralDerivadosServiciosMunicipales} data={trimestralDerivadosServiciosMunicipales} />
                          </div>
                        </div>


                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />

                    <button onClick={(downloadImage_trimestralRangoHora)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_rango_hora" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      RANGO HORA
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">


                        <div className="w-full xl:w-1/2 text-center">

                          <h2 className="px-5 py-2 pb-5">Cantidad de requerimientos por Rango de Hora.</h2>
                          <Line
                                data={trimestralRangoHora_graph_data}
                                height={190}
                                options={pie_graph_global_options}

                                />

                        </div>


                        <div className="w-full xl:w-1/2 ml-3">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden">
                          <TableReporteMensualTrimestral columns={columnsTrimestralRangoHora} data={trimestralRangoHora} />
                          </div>
                        </div>



                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />



                    <button onClick={(downloadImage_trimestralIncivilidades)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_incivilidades" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      INCIVILIDADES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralIncivilidades_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos agrupados en Incivilidades',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '7' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsTrimestralIncivilidades} data={trimestralIncivilidades} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />

                    <button onClick={(downloadImage_trimestralPatrullajeMovil)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_patrullaje_movil" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      Avistamientos en terreno Patrullaje Móvil Municipal
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'600px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralPatrullajeMovil_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos asociados a Patrullaje Movil',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '8' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-12">
                          <TableReporteMensualTrimestral columns={columnsTrimestralPatrullajeMovil} data={trimestralPatrullajeMovil} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />

                    <button onClick={(downloadImage_trimestralPatrullajeCiclovias)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_patrullaje_ciclovias" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      Avistamientos en terreno Patrullaje Ciclovías Municipal
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'600px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={trimestralPatrullajeCiclovias_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos asociados a Patrullaje de Ciclovías',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              xAxis: { textStyle: { fontSize: 16  } },
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '8' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-12">
                          <TableReporteMensualTrimestral columns={columnsTrimestralPatrullajeCiclovias} data={trimestralPatrullajeCiclovias} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_trimestralMovilTerreno)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white ml-4">Descargar gráfico</button>

                    <div id="trimestral_movil_terreno" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      Requerimientos con envío de Móvil a terreno
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">

                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden ">
                          <TableReporteMensualTrimestral columns={columnsTrimestralMovilTerreno} data={trimestralMovilTerreno} />
                          </div>
                        </div>

                      </div>

                    </div>




                  </>
                  : null }

















                  {tipoReporte == 1 && mensualPrevencionSeguridad.length > 0 && mes !== null ?
                  <>


                    {/* BEGIN: RESUMEN FILTROS SELECCIONADOS */}
                    <div id="mensual_1" className="px-2 mt-0 mb-5 bg-gray-200 p-4 rounded-lg w-full text-base">
                      <div className="flex flex-row w-full">

                        <div className="flex flex-col w-full">
                          <h2 className="px-4 pb-2 font-semibold text-gray-900">Filtros seleccionados:</h2>

                          <hr className="border-gray-300 mx-4 py-2" />

                          <div className="flex flex-row px-4">
                            <div className="w-96 font-semibold">Tipo de reporte:</div>
                            <div className="w-64 font-normal">Mensual</div>
                          </div>

                          <div className="flex flex-row pt-2 px-4">
                            <div className="w-96 font-semibold whitespace-nowrap">Total Requerimientos [{ moment(mesFiltrado).format('MMMM').toUpperCase() } { moment(mesFiltrado).format('YYYY').toUpperCase() }]: </div>
                            <div className="w-64 font-normal">
                            {mensualRequerimientosInformados.filter(result => result.requerimiento_informado !== null).reduce((pv, cv) => parseInt(pv) + parseInt(cv.total_mes_1), 0)}

                            </div>
                          </div>
                        </div>

                        <div className="flex-grow h-16">
                        </div>

                        <div className="text-right pt-0 pr-2 ">
                          <div className="w-full text-right pt-12 pr-2 flex flex-col ">

                            <button onClick={downloadExcelRequerimientosMensual}
                                className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded mt-1 w-56 text-center">Exportar a Excel</button>

                            <button onClick={generateDocxMensual}
                                className="bg-blue-800 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mt-1 w-56 text-center">Exportar a Word</button>

                          </div>
                        </div>

                      </div>
                    </div>
                    {/* END: RESUMEN FILTROS SELECCIONADOS */}




                    {/* <div className=" text-xl font-bold leading-tight pt-2 pl-4 py-2">
                      LLAMADOS FONO EMERGENCIAS 1409
                    </div> */}

                    <hr className="border-gray-300 mx-4 my-3" />



                    <button onClick={(downloadImage_mensualPrevencionSeguridad)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-5  ml-4">Descargar gráfico</button>

                    <div id="mensual_prevencion_seguridad" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      CATEGORÍA PREVENCIÓN Y SEGURIDAD
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualPrevencionSeguridad_graph_data}
                            options={{
                              colors: colorsGraph,
                              title: 'Porcentaje de requerimientos en categoría Prevención y Seguridad',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '10' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensual} data={mensualPrevencionSeguridad} />
                          </div>
                        </div>


                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />



                    <button onClick={(downloadImage_mensualServiciosMunicipales)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_servicios_municipales" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      CATEGORÍA SERVICIOS MUNICIPALES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                            <Chart
                              width={'100%'}
                              height={'500px'}
                              chartType="PieChart"
                              loader={<div>Cargando gráfico</div>}
                              data={mensualServiciosMunicipales_graph_data}
                              options={{
                                colors: colorsGraph,
                                title: 'Porcentaje de requerimientos en categoría Servicios Municipales',
                                legend: 'none',
                                pieSliceText: 'label',
                                pieStartAngle: 50,
                                titleTextStyle: {
                                    fontSize: 16
                                },
                              }}
                              rootProps={{ 'data-testid': '11' }}
                            />
                        </div>

                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                            <TableReporteMensualTrimestral columns={columnsMensual} data={mensualServiciosMunicipales} />
                          </div>
                        </div>


                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />



                    <button onClick={(downloadImage_mensualServiciosComunidad)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_servicios_comunidad" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      CATEGORÍA SERVICIOS A LA COMUNIDAD
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">


                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualServiciosComunidad_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos en categoría Servicios a la Comunidad',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '12' }}
                          />
                        </div>

                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensual} data={mensualServiciosComunidad} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualEmergencias)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_emergencias" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      CATEGORÍA EMERGENCIAS
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">


                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualEmergencias_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos en categoría Emergencias',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '13' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensual} data={mensualEmergencias} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualSectores)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_sectores" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      SECTORES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualSectores_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos en categoría Sectores',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '14' }}
                          />
                        </div>

                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualSectores} data={mensualSectores} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualRequerimientosInformados)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_requerimientos_informados" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      REQUERIMIENTOS INFORMADOS
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualRequerimientosInformados_graph_data}
                            options={{
                              title: 'Porcentaje de Requerimientos Informados',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '15' }}
                          />
                        </div>

                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualRequerimientosInformados} data={mensualRequerimientosInformados} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />

                    <button onClick={(downloadImage_mensualDerivadosServiciosMunicipales)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_derivados_servicios_municipales" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      DERIVADOS SERVICIOS MUNICIPALES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className=" w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualDerivadosServiciosMunicipales_graph_data}
                            options={{
                              title: 'Porcentaje de Derivaciones a Servicios Municipales',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '16' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualDerivadosServiciosMunicipales} data={mensualDerivadosServiciosMunicipales} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualRangoHora)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_rango_hora" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      RANGO HORA
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-1/2 text-center">
                          <h2 className="px-5 py-2 pb-5">Cantidad de requerimientos por Rango de Hora.</h2>
                          <Line data={mensualRangoHora_graph_data} height={190} options={pie_graph_global_options} />
                        </div>


                        <div className="w-full xl:w-1/2 mr-2 ml-3">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden">
                          <TableReporteMensualTrimestral columns={columnsMensualRangoHora} data={mensualRangoHora} />
                          </div>
                        </div>


                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualIncivilidades)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_incivilidades" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3">
                      INCIVILIDADES
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-2/5 text-center">
                          <Chart
                            width={'100%'}
                            height={'500px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualIncivilidades_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos agrupados en Incivilidades',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '17' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualIncivilidades} data={mensualIncivilidades} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualPatrullajeMovil)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_patrullaje_movil" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      Avistamientos en terreno Patrullaje Móvil Municipal
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-3/7 text-center">
                          <Chart
                            width={'100%'}
                            height={'600px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualPatrullajeMovil_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos asociados a Patrullaje Movil',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '18' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualPatrullajeMovil} data={mensualPatrullajeMovil} />
                          </div>
                        </div>

                      </div>

                    </div>


                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualPatrullajeCiclovias)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_patrullaje_ciclovias" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      Avistamientos en terreno Patrullaje Ciclovías Municipal
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-3/7 text-center">
                          <Chart
                            width={'100%'}
                            height={'600px'}
                            chartType="PieChart"
                            loader={<div>Cargando gráfico</div>}
                            data={mensualPatrullajeCiclovias_graph_data}
                            options={{
                              title: 'Porcentaje de requerimientos asociados a Patrullaje de Ciclovías',
                              legend: 'none',
                              pieSliceText: 'label',
                              pieStartAngle: 50,
                              titleTextStyle: {
                                    fontSize: 16
                                },
                            }}
                            rootProps={{ 'data-testid': '18' }}
                          />
                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden mt-10">
                          <TableReporteMensualTrimestral columns={columnsMensualPatrullajeCiclovias} data={mensualPatrullajeCiclovias} />
                          </div>
                        </div>

                      </div>

                    </div>



                    <hr className="border-gray-200 py-2" />


                    <button onClick={(downloadImage_mensualMovilTerreno)} className=" text-xs px-2 py-1  bg-green-500 hover:bg-green-600 rounded text-white mt-2 ml-4">Descargar gráfico</button>

                    <div id="mensual_movil_terreno" className="px-3  py-4 pt-0 overflow-x-auto w-full bg-white">

                      <div className="text-gray-900 text-lg font-bold leading-tight py-3 uppercase">
                      REQUERIMIENTOS CON ENVÍO DE MÓVIL A TERRENO
                      </div>

                      <div className="flex flex-col xl:flex-row w-full">

                        <div className="w-full xl:w-3/7 text-center">

                        </div>


                        <div className="w-full xl:w-3/5">
                          <div className="inline-block w-full h-auto shadow rounded-lg overflow-hidden">
                          <TableReporteMensualTrimestral columns={columnsMensualMovilTerreno} data={mensualMovilTerreno} />
                          </div>
                        </div>

                      </div>

                    </div>




                  </>
                  : null }





                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
