import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app'
import { obtenerRequerimientosDepartamento, asignarFuncionarioRequerimiento } from '../../actions/requerimientos'
import { obtenerUsuariosDepartamento } from '../../actions/usuarios'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientos  from './TableRequerimientos';


import ReactModal from 'react-modal';


import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


export const RequerimientosDepartamento = () => {

  const dispatch = useDispatch();

  const { usuario } = useSelector( state => state.auth );

  const [funcionariosDepartamento, setFuncionariosDepartamento] = useState([])


  useEffect(() => {
    console.log(usuario)
      dispatch( appSetMenuActual("requerimientos-departamento") );
      dispatch( obtenerUsuariosDepartamento(usuario.departamento_id) ).then(result => setFuncionariosDepartamento(result) );
  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Fecha llamada', accessor: 'fecha', Cell: ({ row }) => <span className="whitespace-nowrap">{row.values.fecha}</span>  },
            { Header: 'Requerimiento', accessor: 'requerimientotipificado_nombre', Cell: ({ row }) => <span className="whitespace-nowrap" >{row.values.requerimientotipificado_nombre.slice(0,20)+"..."}</span>  },
            { Header: 'SLA', accessor: 'dias_en_proceso', Cell: ({ row }) => {

              if(row.values.dias_en_proceso === null)
              return (<></>)
              
              if(row.values.dias_en_proceso <= 10)
              return(
              <span className="whitespace-nowrap bg-green-200 py-1 px-3 rounded">{row.values.dias_en_proceso} de 10</span>
              )
              else
              return(
                <span className="whitespace-nowrap bg-red-200 py-1 px-3 rounded">{row.values.dias_en_proceso} de 10</span>
              )
            }
            
            },
            
            { Header: 'Estado',  
              Cell: (props) => {
                return (

                  <>

                  { props.estadorequerimiento_id === 1 ?

                    <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.estadorequerimiento_nombre}</span>

                  :  props.estadorequerimiento_id === 2 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 
                    
                  :  props.estadorequerimiento_id === 3 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  :  props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  

                  :
                  null }

                  </>
                );
              }
            },

            { Header: 'Funcionario asignado ', accessor: 'usuario_asignado_nombre' },


          
            { Header: 'Asignar',  
              Cell: (props) => {
                if(props.estadorequerimiento_id === 3)
                return (
       
                    <button className="bg-gray-500 text-white hover:bg-gray-700 text-sm px-5 py-1 rounded" 
                    onClick={() => handleOpenModal(props.id)}
                    > Asignar </button>
             
                )
                else
                return(<></>)

              }
            },

            { Header: 'Ver',  
              Cell: (props) => {
                return (
                  
                  <Link to={`/requerimientos/ver/${props.id}`}>
                    <button className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </button>
                  </Link>
                  
                );
               
              }
            }



          ],
    []
  )

  const [requerimientos, setRequerimientos] = useState([])
  
  useEffect(() => {

    dispatch(obtenerRequerimientosDepartamento()).then(result => { setRequerimientos(result) })

  }, [dispatch])



  const items_select_funcionarios_departamento = [];

  funcionariosDepartamento.forEach(function(valor, indice, array) {

    if (valor.usuario_id !== null && valor.usuario_id !== undefined) {
      items_select_funcionarios_departamento.push( <option value = { valor.usuario_id } key ={`option_rol_${ valor.usuario_id }`} > { valor.usuario_nombre } </option>
        )
    }

  });



  ReactModal.setAppElement('body')


  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = (requerimiento_id) => {
    setRequerimientoSeleccionadoId(requerimiento_id)
    setShowModal(true)
  }
  
  const handleCloseModal = () => {
    setShowModal(false)
  }


  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };


  const [usuarioAsignarId, setUsuarioAsignarId] = useState(null)
  const [requerimientoSeleccionadoId, setRequerimientoSeleccionadoId] = useState(null)

  const handleInputChangeFuncionarioDepartamento = ({ target }) => {

    setUsuarioAsignarId(target.value)

  }

  const handleAsignarFuncionarioRequerimiento = (e) => {
    e.preventDefault();

    if(usuarioAsignarId === null || usuarioAsignarId == 0){
      alert("Debe seleccionar un Funcionario para asignar.")
      document.getElementById("usuario_asignar_id").focus();
    }
    else{

      dispatch( asignarFuncionarioRequerimiento( requerimientoSeleccionadoId, usuarioAsignarId ) )
      .then( response => {

        if(response.error === false)
        {
          handleCloseModal()
          dispatch(obtenerRequerimientosDepartamento()).then(result => { setRequerimientos(result) })
          setUsuarioAsignarId(null)
          setRequerimientoSeleccionadoId(null)
        }
      
      });

    }
  }


  return (
    <div className='lg:flex min-h-full h-full w-full '>

        <ReactModal 
           isOpen={showModal}
           contentLabel="Minimal Modal Example"
           style={customStyles}
           
        >
          <div className="flex flex-col">
            
            <span className="text-md font-bold">Seleccione Funcionario:</span>
            <br />

            <div className="flex flex-wrap -mx-3 mb-8">
              <div className="w-full px-3 mb-6 md:mb-0">

                
                <div>
                  <select className = 'inline-block select-temuco cursor-pointer w-full bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                  id = 'usuario_asignar_id'
                  name = "usuario_asignar_id"
                  onChange={handleInputChangeFuncionarioDepartamento}
                  >
                  <option value = "0" > - Seleccione - </option> 

                  { items_select_funcionarios_departamento } 
                  
                  </select>
                </div>
              </div>
            </div>

            

            <br />
            <div className="flex flex-row">
            <button className="bg-gray-500 p-2 px-8 rounded text-white" onClick={handleCloseModal}>Cerrar</button>
            <button className="bg-green-500 p-2 px-8 ml-3 rounded text-white" onClick={handleAsignarFuncionarioRequerimiento} >Asignar Requerimiento</button>
            </div>
            
          </div>
          
        </ReactModal>


      <MenuLeft />
      <Main>

        
      <div id="requerimiento_departamento_main" className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4 ">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-row sm:flex-row  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faUser}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Requerimientos asignados a Departamento 
                      
                    </span>

                       

                  </div>

          

                </div>


                <span className="bg-blue-100 border-blue-200 border rounded  text-blue-800 leading-5 px-4 py-1">
                    <span className="text-xs font-semibold pr-1">Departamento: </span>
                    <span className="text-xs font-light">{usuario.departamento_nombre}</span>
                </span> 

                <hr className="mt-5" />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableRequerimientos columns={columns} data={requerimientos} />

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
