import { React, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { Main } from '../App/Main';
import { startLogin } from '../../actions/auth';
import { setApiVersion } from '../../actions/app';

import logo_mtemuco from '../../assets/images/logo_mtemuco.png';
import logo_1409 from '../../assets/images/logo_1409.png';


export const Login = () => {

    const dispatch = useDispatch();

    const { apiVersion } = useSelector( state => state.app );

    useEffect(() => { 
      dispatch(setApiVersion())
    }, [])




    const [ formLoginValues, handleLoginInputChange ] = useForm({
        email: '',
        password: ''
    });


    
    const { email, password } = formLoginValues;

    const handleLogin = ( e ) => {
        e.preventDefault();
        dispatch( startLogin( email, password ) );
    }


   
    return (

        <Main>
        <div className='text-center antialiased h-full min-h-full'>
          <div className='shadow-md rounded pt-0 flex w-full min-h-full items-center justify-center my-auto  '>
            <div className='w-full  mx-3'>
              
              <div className="w-full flex flex-col md:flex-row justify-center text-center mb-10" >

                <img src={ logo_mtemuco } className="m-auto mt-0 md:mt-6 w-32 h-20 " alt="Logo Municipalidad de Temuco"></img> 

                <h2 className="text-2xl font-bold text-gray-500 p-5 px-12 mt-3">Sistema de Gestión Municipal de Requerimientos <br /> Fono Emergencias 1409</h2>

                <img src={ logo_1409 } className="hidden sm:flex m-auto mt-6 w-44 h-24 bg-gray-700 p-2 rounded-lg" alt="Logo Seguridad Ciudadana 1409"></img> 
      
              </div>


              <div className="sm:w-full md:w-3/4 xl:w-2/5 lg:w-1/2 m-auto">
                <h4 className='font-semibold py-4 text-lg text-left pl-6'>Ingreso al sistema</h4>

                <form className='bg-white shadow-md rounded px-5 sm:px-10 py-10 mb-4 w-full' onSubmit={handleLogin}>

                  <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2 text-left' htmlFor='rut'>
                    Email
                    <input 
                      className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight' 
                      id='email' 
                      name='email'
                      type='email' 
                      onChange={handleLoginInputChange}
                      required 
                      autoFocus 
                    
                      autoComplete="off"
                      />
                    </label>
                  </div>

                  <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2 text-left' htmlFor='password'>
                      Clave

                      <input 
                        className=' appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight' 
                        id='password' 
                        name="password"
                        type='password'
                        required
                        onChange={handleLoginInputChange}
                        autoComplete="off"
                      />

                    </label>
                  </div>


                  <div className='flex flex-col md:flex-row  items-center justify-between'>

                      <button 
        
                        className='bg-temuco-green text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline' 
                        type='submit'>
                        Ingresar
                      </button>

                      <Link to='/recuperar-clave'  className="pt-2 md:pt-0">
                        <span className='inline-block align-baseline font-normal text-sm text-gray-400 hover:text-green-600'>
                          Recuperar clave
                        </span>
                      </Link>

                  </div>

                </form>

                <div className='flex flex-col md:flex-col w-full items-left justify-between text-left text-xs text-gray-400'>

                      <p>API VERSION: { apiVersion }</p>
                      {/* <p>CLIENT VERSION: { clientVersion }</p> */}

                </div>

                </div>


             


            </div>
          </div>
        </div>

      </Main>

    )
}
