import { fetchConToken } from '../helpers/fetch';

export const obtenerConsolidadoRequerimientosTrimestral = (requerimientos = 0, trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-requerimientos-trimestral/?requerimientos='+requerimientos+'&trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}

export const obtenerConsolidadoSectoresTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-sectores-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();
            
            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerConsolidadoRequerimientosMensual = (requerimientos = 0, mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-requerimientos-mensual/?requerimientos='+requerimientos+'&mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoSectoresMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-sectores-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();
            
            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}





export const obtenerConsolidadoRequerimientosInformadosTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-requerimientos-informados-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoRequerimientosInformadosMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-requerimientos-informados-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoDerivadosServiciosMunicipalesTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-derivados-servicios-municipales-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}





export const obtenerConsolidadoDerivadosServiciosMunicipalesMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-derivados-servicios-municipales-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoRangoHoraTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-rango-hora-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerConsolidadoRangoHoraMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-rango-hora-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}



export const obtenerConsolidadoPatrullajeMovilTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-patrullaje-movil-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerConsolidadoPatrullajeMovilMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-patrullaje-movil-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoPatrullajeCicloviasTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-patrullaje-ciclovias-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerConsolidadoPatrullajeCicloviasMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-patrullaje-ciclovias-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}




export const obtenerConsolidadoMovilTerrenoTrimestral = (trimestre) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-movil-terreno-trimestral/?trimestre='+trimestre, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


export const obtenerConsolidadoMovilTerrenoMensual = (mes) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-movil-terreno-mensual/?mes='+mes, {}, 'GET');
            const body = await resp.json();

            if(body.result)
            return body.result.result;
            else
            return []

        } catch (error) {
            return []
        }

    }
}









export const getTotalesDia = (fecha) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/totales-dia/?fecha=' + fecha, {}, 'GET');
            const body = await resp.json();

            if(body.totales)
            return body.totales;
            else
            return null

        } catch (error) {
            return null
        }

    }
}




export const getConsolidadoRequerimientosDia = (tipo_requerimiento_id, fecha) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-requerimientos-dia/?fecha=' + fecha + '&tipo_requerimiento_id=' + tipo_requerimiento_id, {}, 'GET');
            const body = await resp.json();

            if(body.requerimientos)
            return body.requerimientos;
            else
            return []

        } catch (error) {
            return []
        }

    }
}





export const getConsolidadoTiemposLlamadaDia = (fecha) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('reportes/consolidado-tiempos-llamada-dia/?fecha=' + fecha, {}, 'GET');
            const body = await resp.json();

            if(body.tiempos_llamada)
            return body.tiempos_llamada;
            else
            return []

        } catch (error) {
            return []
        }

    }
}


