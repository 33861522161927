import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { Main } from '../App/Main';
import { startRecuperarClavePaso2, checkTokenResetPassword } from '../../actions/auth';
import cogoToast from 'cogo-toast';


export const RecuperarClavePaso2 = (props) => {

  let history = useHistory();
  const dispatch = useDispatch();

  const token = props.match.params ? props.match.params.token : null;


const [tokenResetValid, settokenResetValid] = useState(false)

  useEffect(() => {
     dispatch( checkTokenResetPassword(token) ).then(response => settokenResetValid(response) );
  }, [])

    
  const [ formValues, handleInputChange ] = useForm({
      password1: '',
      password2: ''
  });


  const { password1, password2 } = formValues;

  const handleSubmit = ( e ) => {
      e.preventDefault();

      if(password1 !== password2) {
        cogoToast.error("Las contraseñas no coinciden.", {hideAfter:6});
      }
      else {

        dispatch( startRecuperarClavePaso2( props.match.params.token, password1, password2 ) )
          .then( response => {

            if(response.error === false)
              history.push('/usuarios/');
          
          });

      }

  }


    return (
      

      <Main>
        <div className='text-center antialiased h-full min-h-full'>
          <div className='shadow-md rounded pt-0 flex w-full min-h-full items-center justify-center my-auto  '>
            <div className='w-3/4 xl:w-1/3 lg:w-1/3 md:w-1/2'>

              { tokenResetValid ?
              
                <form
                  className='bg-white shadow-md rounded px-10 py-10 mb-4 w-full'
                  onSubmit={handleSubmit}
                  autoComplete='off'
                >
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 text-md  mb-2 text-left'
                      htmlFor='usuario_rut'
                    >
                      <p className='font-bold mb-10'>
                        Recuperación de contraseña{' '}
                      </p>
                      <p>Estimado/a  </p>
                      <p className='pt-4 pb-3'>
                        Por favor ingrese una nueva contraseña. Debe tener al menos 6 carácteres.
                      </p>
                    </label>
                  </div>

                  <div className='flex flex-wrap -mx-3 mb-6 pt-5'>
                    <div className='px-3 w-full text-left'>
                      <label
                        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                        htmlFor='password1'
                      >
                        Nueva Contraseña
                      </label>
                      <input
                        className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight' 
                        id='password1'
                        name='password1'
                        type='password'
                        placeholder='******************'
                        required
                        onChange={handleInputChange}
                        autoFocus
                      />
                    </div>
                  </div>

                  <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='px-3 w-full text-left'>
                      <label
                        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                        htmlFor='password2'
                      >
                        Confirmar nueva contraseña
                      </label>
                      <input
                        className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight' 
                        id='password2'
                        name='password2'
                        type='password'
                        placeholder='******************'
                        required
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className='w-full px-0 text-center pt-4'>
                    <button
                      id='btn_confirmar_password'
                      type='submit'
                      className="bg-temuco-green text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                    >
                      Modificar contraseña
                    </button>
                  </div>
                </form>
                
                : 
                
                <div className='mb-4'>
                    <h2 className='font-bold mb-10 text-2xl text-gray-600'>
                      El token no es válido o expiró.
                    </h2>
                </div>
                
                }

            </div>
          </div>
        </div>
      </Main>


    )
}