import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';

import {appSetMenuActual} from '../../actions/app'
import { nuevoUsuario, obtenerRoles } from '../../actions/usuarios'
import { obtenerDepartamentosMunicipales } from '../../actions/departamentos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import cogoToast from 'cogo-toast'
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';
import { isArrayBindingPattern } from 'typescript';
moment.locale('es');


export const UsuariosNuevo = () => {

  let history = useHistory();
  const dispatch = useDispatch();

  const { roles } = useSelector( state => state.usuarios );

  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
      dispatch( appSetMenuActual("usuarios") );
      dispatch( obtenerRoles() );
      dispatch( obtenerDepartamentosMunicipales() ).then( (result) => setDepartamentos(result) )

  }, [ dispatch ])



  const [ formValues, handleInputChange ] = useForm({
    email: '',
    nombre: '',
    rol_id: 0,
    departamento_id: 0,
    xcontact_id_operador: '',
    password1: '',
    password2: ''
});
  const { email, nombre, rol_id, departamento_id, xcontact_id_operador, password1, password2 } = formValues;



  const handleSubmitNuevoUsuario = ( e ) => {
    e.preventDefault();

    if(password1 === password2){
   

      let departamento_id_enviar = departamento_id;
      if(rol_id == 1 || rol_id == 2 || rol_id == 5)
      departamento_id_enviar = 0;

      let xcontact_id_operador_enviar = xcontact_id_operador;
      if(rol_id != 2 )
      xcontact_id_operador_enviar = '';



      dispatch( nuevoUsuario( email, nombre, rol_id, departamento_id_enviar, xcontact_id_operador_enviar, password1 ) )
        .then( response => {

          if(response.error === false)
            history.push('/usuarios/');
        
        });
    
    }
    else{
      cogoToast.error("Las contraseñas no coinciden.", {hideAfter:6});
    }
  }


  const items_select_roles = [];

  roles.forEach(function(valor, indice, array) {

    if (valor.rol_id !== null && valor.rol_id !== undefined) {
      items_select_roles.push( <option value = { valor.rol_id } key ={`option_rol_${ valor.rol_id }`} > { valor.rol_nombre } </option>
        )
    }

  });

  const items_select_departamentos = [];

  departamentos.forEach(function(valor, indice, array) {

    if (valor.departamento_id !== null && valor.departamento_id !== undefined) {
      items_select_departamentos.push( <option value = { valor.departamento_id } key ={`option_rol_${ valor.departamento_id }`} > { valor.departamento_nombre } </option>
        )
    }

  });

  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className='flex-1 flex flex-col'>
        
        <div className='flex items-start mb-4 text-sm p-8 md:p-16 md:pt-12'>
          <div className='flex-1 overflow-hidden  rounded p-2 lg:p-6 lg:pt-0 text-left '>
            
            <Link to='/usuarios' className="pt-2 md:pt-0">
              <button 
                id='btn_volver' 
                type='button' 
                className='bg-gray-500 hover:bg-gray-600 text-white text-base font-bold py-2 px-8 mb-5 rounded'
              >
                <FontAwesomeIcon icon={faArrowLeft} size='1x' />
                <span className="pl-2">
                Volver
                </span>
              </button>
            </Link>



          <form className=' w-full lg:w-3/4 xlg:w-1/3 border rounded border-indigo-200 p-10 bg-white' 
                onSubmit={handleSubmitNuevoUsuario} 
                autoComplete="off">

            <span className="font-bold text-lg">Crear Usuario</span>
            <hr className="mt-2" />

          
            <div className="flex flex-wrap -mx-3 mb-6 mt-5">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="nombre">
                  Nombre
                </label>
                
                <input className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                id="nombre"
                name="nombre"
                type="text" 
                required 
                autoFocus
                onChange={handleInputChange}
                />
                
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input 
                className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                id="email"
                name="email" 
                type="email"
                required
                onChange={handleInputChange}
                autoComplete="off"
                />
                
              </div>
            </div>


            <div className="flex flex-wrap -mx-3 mb-8">

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="rol">
                  Rol
                </label>
                
                <div>
                  <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight '
                  id = 'rol_id'
                  name = "rol_id"
                  onChange={handleInputChange}
                   >
                  <option value = "0" > - Seleccione - </option> 

                  { items_select_roles } 
                  
                  </select>
                </div>
              </div>
            
            </div>

            { ( parseInt(rol_id) !== 1 && parseInt(rol_id) !== 2 && parseInt(rol_id) !== 5 && parseInt(rol_id) !== 0 )  ?
              <div className="flex flex-wrap -mx-3 mb-8">

                <div className="w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="departamento_id">
                    Departamento
                  </label>
                  
                  <div>
                    <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight '
                    id = 'departamento_id'
                    name = "departamento_id"
                    onChange={handleInputChange}
                    >
                    <option value = "0" > - Seleccione - </option> 

                    { items_select_departamentos } 
                    
                    </select>
                  </div>
                </div>
              
              </div>
            : null }


            { ( parseInt(rol_id) === 2 )  ?
            <div className="flex flex-wrap -mx-3 mb-6 mt-5">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="xcontact_id_operador">
                  Operador ID (xContact)
                </label>
                
                <input className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                id="xcontact_id_operador"
                name="xcontact_id_operador"
                type="number" 
                onChange={handleInputChange}
                />
                
              </div>
            </div>
            : null }




            <div className="flex flex-wrap -mx-3 mb-6 pt-5">
              <div className="px-3 w-full">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="password1">
                  Contraseña
                </label>
                <input 
                  className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                  id="password1" 
                  name="password1"
                  type="password" 
                  placeholder="******************"
                  required
                  onChange={handleInputChange}
                    />
              </div>
            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="px-3 w-full">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2" htmlFor="password2">
                  Confirmar contraseña
                </label>
                <input 
                  className="appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight" 
                  id="password2"
                  name="password2"
                  type="password" 
                  placeholder="******************"
                  required
                  onChange={handleInputChange}
                    />
              </div>
            </div>


            



            <div className="w-full px-0 text-left pt-4">

              <button 
                id='btn_confirmar' 
                type='submit' 
                className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
              >
              <FontAwesomeIcon icon={faSave} size='1x' />
              <span className="pl-2">
              Crear nuevo Usuario
              </span>
              </button>
            </div>


            </form>

          

          </div>
        </div>
     
    </div>
      </Main>
    </div>
  )
}
