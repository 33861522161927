import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
  } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { AccesoDenegado } from '../components/App/AccesoDenegado';

import { Login } from '../components/Auth/Login';
import { RecuperarClave } from '../components/Auth/RecuperarClave';
import { RecuperarClavePaso2 } from '../components/Auth/RecuperarClavePaso2';

import { Usuarios } from '../components/Usuarios/Usuarios';
import { UsuariosEditar } from '../components/Usuarios/UsuariosEditar';
import { UsuariosNuevo } from '../components/Usuarios/UsuariosNuevo';

import { MisDatos } from '../components/MisDatos/MisDatos';

import { Dashboard } from '../components/Dashboard/Dashboard';

import { Requerimientos } from '../components/Requerimientos/Requerimientos';

import { RequerimientosCallCenter } from '../components/Requerimientos/RequerimientosCallCenter';
import { RequerimientosDepartamento } from '../components/Requerimientos/RequerimientosDepartamento';
import { RequerimientosFuncionario } from '../components/Requerimientos/RequerimientosFuncionario';


import { RequerimientosEditar } from '../components/Requerimientos/RequerimientosEditar'
import { RequerimientosVer } from '../components/Requerimientos/RequerimientosVer'

import { ReporteRequerimientos } from '../components/ReporteRequerimientos/ReporteRequerimientos'
import { ReporteMensualTrimestral } from '../components/ReporteMensual/ReporteMensualTrimestral'

import { Blank } from '../components/App/Blank';

import { startChecking } from '../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export const AppRouter = () => {

  const dispatch = useDispatch();
  const { usuario } = useSelector( state => state.auth);
  const [checked, setChecked] = useState(false)

  useEffect(() => {

    dispatch( startChecking() ).then( () => setChecked(true)).catch(() => setChecked(true));

  }, [dispatch])


  return (
    <Router history={history}>
        <Switch>

          <PrivateRoute exact path="/acceso-denegado" component={ AccesoDenegado } isChecked={checked} isAuthenticated={ !!usuario } />

          <PublicRoute exact path="/login" component={ Login } isChecked={checked} isAuthenticated={ !!usuario } />
          <PublicRoute exact path="/recuperar-clave" component={ RecuperarClave } isChecked={checked} isAuthenticated={ !!usuario } />
          <PublicRoute exact path="/recuperar-clave/:token" component={ RecuperarClavePaso2 } isChecked={checked} isAuthenticated={ !!usuario } />

          <PrivateRoute exact path="/dashboard" component={ Dashboard } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1, 5] }  />

          <PrivateRoute exact path="/usuarios/" component={ Usuarios } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1] } />
          <PrivateRoute exact path="/usuarios/nuevo" component={ UsuariosNuevo } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1] } />
          <PrivateRoute exact path="/usuarios/:id/editar" component={ UsuariosEditar } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1] } />


          <PrivateRoute exact path="/mis-datos" component={ MisDatos } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1, 2, 3, 4, 5] } />

          <PrivateRoute exact path="/requerimientos/" component={ Requerimientos } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1,5] } />

          <PrivateRoute exact path="/requerimientos/callcenter/" component={ RequerimientosCallCenter } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1, 2] } />
          <PrivateRoute exact path="/requerimientos/departamento/" component={ RequerimientosDepartamento } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [3] } />
          <PrivateRoute exact path="/requerimientos/funcionario/" component={ RequerimientosFuncionario } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [3,4] } />

          <PrivateRoute exact path="/requerimientos/editar/:id" component={ RequerimientosEditar } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1, 2] } />
          <PrivateRoute exact path="/requerimientos/nuevo" component={ RequerimientosEditar } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1, 2] } />
          <PrivateRoute exact path="/requerimientos/ver/:id" component={ RequerimientosVer } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1,2,3,4,5] } />

          <PrivateRoute exact path="/reportes/requerimientos/" component={ ReporteRequerimientos } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1,5] } />
          <PrivateRoute exact path="/reportes/mensual-trimestral/" component={ ReporteMensualTrimestral } isChecked={checked} isAuthenticated={ !!usuario } usuario={usuario} roles={ [1,5] } />



          <PrivateRoute exact path="/" isChecked={checked} isAuthenticated={ !!usuario } usuarioPathInicial={usuario} />

          <Redirect to="/" />

        </Switch>
    </Router>
  )
}
