import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app'
import { obtenerRequerimientosFuncionario } from '../../actions/requerimientos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientos  from './TableRequerimientos';

import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


export const RequerimientosFuncionario = () => {

  const dispatch = useDispatch();

  const { usuario } = useSelector( state => state.auth );

  useEffect(() => {
      dispatch( appSetMenuActual("requerimientos-funcionario") );
  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Fecha', accessor: 'fecha', Cell: ({ row }) => <span className="whitespace-nowrap">{row.values.fecha}</span>  },
            { Header: 'Requerimiento', accessor: 'requerimientotipificado_nombre' },

            { Header: 'SLA', accessor: 'dias_en_proceso', Cell: ({ row }) => {

              if(row.values.dias_en_proceso === null)
              return (<></>)

              if(row.values.dias_en_proceso <= 10)
              return(
              <span className="whitespace-nowrap bg-green-200 py-1 px-3 rounded">{row.values.dias_en_proceso} de 10</span>
              )
              else
              return(
                <span className="whitespace-nowrap bg-red-200 py-1 px-3 rounded">{row.values.dias_en_proceso} de 10</span>
              )
            }
            
            },

            { Header: 'Estado',  
              Cell: (props) => {
                return (

                  <>

                  { props.estadorequerimiento_id === 1 ?

                    <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.estadorequerimiento_nombre}</span>

                  :  props.estadorequerimiento_id === 2 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 
                    
                  :  props.estadorequerimiento_id === 3 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  :  props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5 ?
                  
                    <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  

                  :
                  null }

                  </>
                );
              }
            },

            { Header: 'Funcionario asignado ', accessor: 'usuario_asignado_nombre' },


          

            { Header: 'Ver',  
              Cell: (props) => {
                return (
                  
                  <Link to={`/requerimientos/ver/${props.id}`}>
                    <button className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </button>
                  </Link>
                  
                );
               
              }
            }



          ],
    []
  )

  const [requerimientos, setRequerimientos] = useState([])
  
  useEffect(() => {

    dispatch(obtenerRequerimientosFuncionario()).then(result => { setRequerimientos(result) })

  }, [dispatch])



  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4 ">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-row sm:flex-row  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faUser}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Requerimientos asignados a Funcionario
                    </span>
                  </div>

          

                </div>

                <span className="bg-blue-100 border-blue-200 border rounded  text-blue-800 leading-5 px-4 py-1">
                    <span className="text-xs font-semibold pr-1">Funcionario: </span>
                    <span className="text-xs font-light">{usuario.usuario_nombre}</span>
                </span> 

                <hr className="mt-5" />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableRequerimientos columns={columns} data={requerimientos} />

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
