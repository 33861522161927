import { fetchSinToken, fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';
import cogoToast from 'cogo-toast'

export const misDatosModificar = ( email, nombre ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Actualizando datos de usuario...") );

        const resp = await fetchConToken( 'mis-datos', { usuario_email: email, usuario_nombre: nombre }, 'PATCH' );
        const body = await resp.json();

        if( body.error === false ) {
            console.log(body)
            dispatch( appSetLoader(false, "") );
            dispatch( update(email, nombre) );

            cogoToast.success("Datos actualizados correctamente.", { hideAfter:8 });
            
        } else {

            if(body.msg_validation){
                cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
            }
            else{
                cogoToast.error(body.msg, {hideAfter:8});
            }

            dispatch( appSetLoader(false, "") );
        }
        

    }
}


export const misDatosModificarPassword = ( password ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Actualizando contraseña...") );

        const resp = await fetchConToken( 'mis-datos/actualizar-clave', { usuario_password: password }, 'PATCH' );
        const body = await resp.json();

        if( body.error === false ) {
            
            dispatch( appSetLoader(false, "") );
            cogoToast.success("Contraseña modificada correctamente.", { hideAfter:8 });
               
        } else {

            if(body.msg_validation){
                cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
            }
            else{
                cogoToast.error(body.msg, {hideAfter:8});
            }

            dispatch( appSetLoader(false, "") );
        }
        

    }
}



export const update = ( email, nombre ) => ({
    type: types.authMisDatosModificar,
    payload: { email, nombre}
});

export const appSetLoader = ( visible, msg ) => ({ type: types.appSetLoader, payload: {visible, msg} })