import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app'
import { obtenerUsuarios } from '../../actions/usuarios'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableUsuarios  from './TableUsuarios';

import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');


export const Usuarios = () => {

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch( appSetMenuActual("usuarios") );
  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'Nombre', accessor: 'usuario_nombre' },
            { Header: 'Email', accessor: 'usuario_email' },
            { Header: 'Rol', accessor: 'rol_nombre' },
            { Header: 'Estado',  
              Cell: (props) => {
                return (

                  <>
                  { props.usuario_activo === 1 ?
                    <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default">Activo</span>
                  : 
                    <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default"> Inactivo </span> }
                  </>
                );
              }
            },
            { Header: 'Departamento', accessor: 'departamento_nombre' },

            { Header: 'Editar',  
              Cell: (props) => {
                return (
                  <Link to={`/usuarios/${props.usuario_id}/editar`}>
                    <button className="bg-gray-500 text-white hover:bg-gray-700 text-sm px-5 py-1 rounded"> Editar </button>
                  </Link>
                );
              }
            }
          ],
    []
  )

  const [usuarios, setUsuarios] = useState([])
  
  useEffect(() => {

    dispatch(obtenerUsuarios()).then(result => { setUsuarios(result) })

  }, [dispatch])



  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4 ">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-row sm:flex-row  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-3 pb-3">
                    <FontAwesomeIcon icon={faUser}  className="text-gray-700 text-xl mt-1" />
                    <span className=" font-semibold leading-tight ml-3">
                      Usuarios
                    </span>
                  </div>

                

                  <div className="pt-2 sm:pt-0">
                    <Link to='/usuarios/nuevo' className="pt-2 md:pt-0">
                      <button 
                        className="bg-temuco-green text-white py-3 px-8 rounded ml-3 shadow-md">

                        <FontAwesomeIcon icon={faPlus} size='1x' />
                        <span className="pl-2">
                        Nuevo usuario
                        </span>

                      </button>
                    </Link>
                  </div>

                </div>

                <hr />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableUsuarios columns={columns} data={usuarios} />

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
