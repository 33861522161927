import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from '../../hooks/useForm';

import { appSetMenuActual } from '../../actions/app'
import { misDatosModificar, misDatosModificarPassword } from '../../actions/misDatos';

import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';
import 'moment/locale/es';

moment.locale('es');


export const MisDatos = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( appSetMenuActual("mis-datos") );
    }, [ dispatch ])

    const { usuario } = useSelector( state => state.auth );


    const [ formValues, handleInput ] = useForm({
        email: usuario.usuario_email,
        nombre: usuario.usuario_nombre,
        password1: '',
        password2: ''
    });


    
    const { email, nombre, password1, password2 } = formValues;

    const handleSubmitGuardarDatos = ( e ) => {
        e.preventDefault();
        dispatch( misDatosModificar( email, nombre ) );
    }

    const handleSubmitModificarPassword = ( e ) => {
        e.preventDefault();

        if(password1 === password2)
            dispatch( misDatosModificarPassword( password1 ) );
        else
            console.log("toast: las contraseñas no coinciden.")
    }


    return (
        <div className='lg:flex min-h-full h-full w-full '>
          <MenuLeft />
          <Main>
          <div className='flex-1 flex flex-col'>
          
          <div className='flex items-start mb-4 text-sm p-8 md:p-16 md:pt-5'>
            <div className='flex-1 overflow-hidden  rounded p-2 lg:p-6  text-left '>
              

            <form className=' w-full lg:w-3/4 xlg:w-1/3 border rounded border-indigo-200 p-10 bg-white' 
                  onSubmit={handleSubmitGuardarDatos} >

              <span className="font-bold text-lg">Modificar información personal</span>
              <hr className="mt-2" />

              <div className="flex flex-wrap -mx-3 mb-6 pt-5">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
                    Email
                  </label>
                  <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" 
                  id="email"
                  name="email" 
                  type="email"
                  required
                  autoFocus
                  onChange={handleInput} 
                  defaultValue={usuario.usuario_email}  
                  />
                  
                </div>
              </div>


              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="nombre">
                    Nombre
                  </label>
                  
                  <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" 
                  id="nombre"
                  name="nombre"
                  type="text" 
                  required 
                  onChange={handleInput}
                  defaultValue={usuario.usuario_nombre}
                  />
                  
                </div>
              </div>

   
              <div className="flex flex-wrap -mx-3 mb-8">

                <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="rol">
                    Rol
                  </label>
                  <div className="relative">

                  <input className="appearance-none block w-full bg-gray-100 border-2 border-gray-100 rounded py-3 pr-3 pl-4 mb-3 leading-tight text-gray-400 font-bold" 
                  id="rol"
                  name="rol"
                  type="text"
                  defaultValue={usuario.rol_nombre}
                  disabled 
                  />

                  </div>
                </div>
              
              </div>



              <div className="w-full px-0 text-left pt-4">

                <button 
                  id='btn_confirmar' 
                  type='submit' 
                  className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
                >
                Guardar cambios
                </button>
              </div>


              </form>

              <form className='w-full lg:w-3/4 xlg:w-1/3  border rounded border-indigo-200 p-10 mt-5 bg-white '
              onSubmit={handleSubmitModificarPassword}  >

              <span className="font-bold text-lg">Modificar contraseña</span>
              <hr className="mt-2" />

                <div className="flex flex-wrap -mx-3 mb-6 pt-5">
                  <div className="px-3 w-full">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password1">
                      Nueva Contraseña
                    </label>
                    <input 
                      className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                      id="password1" 
                      name="password1"
                      type="password" 
                      placeholder="******************"
                      required
                      onChange={handleInput}
                      />
                  </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="px-3 w-full">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password2">
                      Confirmar nueva contraseña
                    </label>
                    <input 
                      className="appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                      id="password2"
                      name="password2"
                      type="password" 
                      placeholder="******************"
                      required
                      onChange={handleInput}
                      />
                  </div>
                </div>


                <div className="w-full px-0 text-left pt-4">

                  <button 
                    id='btn_confirmar_password' 
                    type='submit' 
                    className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
                  >
                  Guardar cambios
                  </button>
                </div>


              </form>


            </div>
          </div>
      
        </div>

      </Main>
      </div>
    )
}
