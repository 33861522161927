import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app'
import { obtenerRequerimientosCallCenter } from '../../actions/requerimientos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientosCall from './TableRequerimientosCall';

import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export const RequerimientosCallCenter = () => {

  const dispatch = useDispatch();

  let history = useHistory();

  const { usuario } = useSelector( state => state.auth );

  useEffect(() => {
      dispatch( appSetMenuActual("requerimientos-callcenter") );
  }, [ dispatch ])


  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Origen', accessor: 'origenrequerimiento_nombre' },
            { Header: 'Telefono', accessor: 'telefono_origen' },
            { Header: 'Fecha', accessor: 'fecha', Cell: ({ row }) => <span className="whitespace-nowrap">{row.values.fecha}</span>  },
            { Header: 'Inicio Llamada', accessor: 'hora_inicio_llamada' },
            { Header: 'Fin Llamada', accessor: 'fecha_fin_llamada' },
            { Header: 'Estado',
              Cell: (props) => {
                return (

                  <>

                  { props.estadorequerimiento_id === 1 ?
                    <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.estadorequerimiento_nombre}</span>
                  :  props.estadorequerimiento_id === 2 ?
                    <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>
                  :  props.estadorequerimiento_id === 3 ?
                    <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>
                  :  props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5 ?
                    <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>
                  :
                  null }

                  </>
                );
              }
            },

            { Header: 'Editar',
              Cell: (props) => {
                if(props.estadorequerimiento_id === 1 || props.estadorequerimiento_id === 2)
                return (

                  <Link to={`/requerimientos/editar/${props.id}`}>
                    <button className="bg-indigo-600 text-white hover:bg-indigo-700 text-sm px-5 py-1 rounded"> Gestionar </button>
                  </Link>

                );
                else
                return(
                  <Link to={`/requerimientos/ver/${props.id}`}>
                    <button className="bg-gray-500 text-white hover:bg-gray-700 text-sm px-5 py-1 rounded"> Ver </button>
                  </Link>
                )

              }
            }
          ],
    []
  )

  const [requerimientos, setRequerimientos] = useState([])


  useEffect(() => {
    dispatch(obtenerRequerimientosCallCenter(usuario.xcontact_id_operador)).then(result => {

      if(result[0])
      if(result[0].estadorequerimiento_id === 1)
      history.push('/requerimientos/editar/'+result[0].id);

      setRequerimientos(result)
    })
  }, [ dispatch ])


  useInterval(() => {

    dispatch(obtenerRequerimientosCallCenter(usuario.xcontact_id_operador)).then(result => {

      if(result[0])
        if(result[0].estadorequerimiento_id === 1)
          history.push('/requerimientos/editar/'+result[0].id);

      setRequerimientos(result)
    })
  }, 15000);




  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className="flex justify-left text-sm py-4 md:py-10 px-0 md:px-4 ">
          <div className="flex-1 flex flex-col w-0 overflow-hidden">
            <div className="p-3 flex-1 overflow-y-auto">
              <div className="container mx-auto px-4 sm:px-8 min-w-full">

                <div className="flex flex-row sm:flex-row  justify-between mb-3">

                  <div className="flex flex-row  text-2xl px-0 pb-3">


                      <FontAwesomeIcon icon={faUser}  className="text-gray-700 text-xl mt-1" />
                      <span className=" font-semibold leading-tight ml-3">
                        Requerimientos Operador 1409 <span className="text-base text-gray-500">(Últimas 24 horas)</span>
                      </span>


                  </div>



                  <div className="pt-2 sm:pt-0">
                    <Link to='/requerimientos/nuevo' className="pt-2 md:pt-0">
                      <button
                        className="bg-temuco-green text-white py-3 px-8 rounded ml-3 shadow-md">

                        <FontAwesomeIcon icon={faPlus} size='1x' />
                        <span className="pl-2">
                        Nuevo Requerimiento
                        </span>

                      </button>
                    </Link>
                  </div>

                </div>

                <span className="bg-blue-100 border-blue-200 border rounded  text-blue-800 leading-5 px-4 py-1">
                  <span className="text-xs font-semibold pr-1">Operador ID xContact: </span>
                  <span className="text-xs font-light">{usuario.xcontact_id_operador}</span>
                </span>

                <hr className="mt-5" />

                <div className="py-2">

                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <TableRequerimientosCall columns={columns} data={requerimientos}  />

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
