import { types } from '../types/types';
import { fetchConToken } from '../helpers/fetch';
import { appSetLoader } from './auth'
import cogoToast from 'cogo-toast'

export const obtenerUsuarios = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('usuarios', {}, 'GET');
            const body = await resp.json();
            
            if(body.usuarios)
            return body.usuarios;
            else
            return []

        } catch (error) {
            return []
        }

        

    }
}



export const obtenerUsuariosDepartamento = (departamento_id) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('usuarios/?departamento_id='+departamento_id, {}, 'GET');
            const body = await resp.json();
            
            if(body.usuarios)
            return body.usuarios;
            else
            return []

        } catch (error) {
            return []
        }

        

    }
}



export const obtenerUsuario = (id) => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('usuarios/?usuario_id='+id, {}, 'GET');
            const body = await resp.json();

            if(body.usuarios)
            return body.usuarios[0];
            else
            return [];

        } catch (error) {
            return {}
        }

        

    }
}

export const obtenerRoles = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken('roles', {}, 'GET');
            const body = await resp.json();

            if(Array.isArray(body))
            dispatch( usuariosSetRoles(body) );
            else
            dispatch( usuariosSetRoles([]) );

        } catch (error) {
            dispatch( usuariosSetRoles([]) );
        }

        

    }
}


const usuariosSetRoles = (roles) => ({ type: types.usuariosSetRoles, payload: roles });




export const nuevoUsuario = ( email, nombre, rol_id, departamento_id, xcontact_id_operador, password1 )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Creando nuevo usuario.") );

            const resp = await fetchConToken('usuarios', { usuario_email: email, usuario_nombre: nombre, rol_id, departamento_id, xcontact_id_operador, usuario_password: password1 }, 'POST');
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Usuario creado correctamente.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al crear el usuario. Si el problema persiste contacte al administrador.", {hideAfter:8});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}


export const editarUsuario = ( id, email, nombre, rol_id, departamento_id, xcontact_id_operador )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Editando nuevo usuario.") );

            const resp = await fetchConToken('usuarios', { usuario_id: id, usuario_email: email, usuario_nombre: nombre, rol_id, departamento_id, xcontact_id_operador }, 'PATCH');
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Usuario editado correctamente.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al editar el usuario. Si el problema persiste contacte al administrador.", {hideAfter:8});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}



export const modificarPassword = ( id, password )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Actualizando contraseña del usuario.") );

            const resp = await fetchConToken('usuarios/update-password', { usuario_id: id, usuario_password: password }, 'POST')
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Contraseña actualizada correctamente.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al cambiar la contraseña del usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}




export const eliminarUsuario = ( id )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Eliminando usuario.") );

            const resp = await fetchConToken('usuarios', { usuario_id: id }, 'DELETE')
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Usuario eliminado.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al eliminar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}




export const desactivarUsuario = ( id )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Desactivando usuario.") );

            const resp = await fetchConToken('usuarios/deactivate', { usuario_id: id }, 'POST')
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Usuario desactivado correctamente.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al desactivar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}





export const activarUsuario = ( id )  => {
    return async( dispatch ) => {

        try {

            dispatch( appSetLoader(true, "Activando usuario.") );

            const resp = await fetchConToken('usuarios/activate', { usuario_id: id }, 'POST')
            const body = await resp.json();
            
            if( body.error === false ) {
            
                cogoToast.success("Usuario activado correctamente.", {hideAfter:8});
                dispatch( appSetLoader(false, "") );
                return { error: false };
    
            } else {
    
                if(body.msg_validation){
                    cogoToast.error(body.msg_validation.details[0].message, {hideAfter:8});
                }
                else{
                    cogoToast.error(body.msg, {hideAfter:8});
                }
    
                dispatch( appSetLoader(false, "") );
                return { error: true };
    
            }

        } catch (error) {
            cogoToast.error("Error inesperado al activar usuario. Si el problema persiste contacte al administrador.", {hideAfter:9});
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }

    }
}





// const eventAddNew = (event) => ({
//     type: types.eventAddNew,
//     payload: event
// });

// export const eventSetActive = (event) => ({
//     type: types.eventSetActive,
//     payload: event
// });

// export const eventClearActiveEvent = () => ({ type: types.eventClearActiveEvent });



// export const eventStartUpdate = ( event ) => {
//     return async(dispatch) => {

//         try {
//             const resp = await fetchConToken(`events/${ event.id }`, event, 'PUT' );
//             const body = await resp.json();

//             if ( body.ok ) {
//                 dispatch( eventUpdated( event ) );
//             } else {
//                 Swal.fire('Error', body.msg, 'error');
//             }


//         } catch (error) {
//             console.log(error)
//         }

//     }
// }

// const eventUpdated = ( event ) => ({
//     type: types.eventUpdated,
//     payload: event
// });


// export const eventStartDelete = () => {
//     return async ( dispatch, getState ) => {

//         const { id } = getState().calendar.activeEvent;
//         try {
//             const resp = await fetchConToken(`events/${ id }`, {}, 'DELETE' );
//             const body = await resp.json();

//             if ( body.ok ) {
//                 dispatch( eventDeleted() );
//             } else {
//                 Swal.fire('Error', body.msg, 'error');
//             }


//         } catch (error) {
//             console.log(error)
//         }

//     }
// }


// const eventDeleted = () => ({ type: types.eventDeleted });


// export const eventStartLoading = () => {
//     return async(dispatch) => {

//         try {
            
//             const resp = await fetchConToken( 'events' );
//             const body = await resp.json();

//             const events = prepareEvents( body.eventos );
//             dispatch( eventLoaded( events ) );

//         } catch (error) {
//             console.log(error)
//         }

//     }
// }

// const eventLoaded = (events) => ({
//     type: types.eventLoaded,
//     payload: events
// })

// export const eventLogout =() => ({ type: types.eventLogout });