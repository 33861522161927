import { fetchSinToken, fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';
import cogoToast from 'cogo-toast'

export const startLogin = ( email, password ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Verificando credenciales de acceso...") );

        const resp = await fetchSinToken( 'auth/login', { usuario_email: email, usuario_password: password }, 'POST' );
        const body = await resp.json();

        if( body.error === false ) {
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime() );

            dispatch( appSetLoader(false, "") );

            dispatch( login({
                usuario: body.usuario
            }) )

            
        } else {
            cogoToast.error(body.msg, {hideAfter:5});
            dispatch( appSetLoader(false, "") );
        }
        

    }
}

export const startChecking = () => {
    return async(dispatch) => {

        dispatch( appSetLoader(true, "Verificando credenciales de acceso...") );

        const resp = await fetchConToken( 'auth/renew-token' );
        const body = await resp.json();


        if( body.error === false ) {
 
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime() );

            dispatch( appSetLoader(false, "") );

            dispatch( login({
                usuario: body.usuario
            }) )
        } else {
            dispatch( checkingFinish() );
            dispatch( appSetLoader(false, "") );
        }
    }
}

const checkingFinish = () => ({ type: types.authCheckingFinish });

const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});



export const startRecuperarClave = ( email ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Enviando email de recuperación de contraseña...") );

        const resp = await fetchSinToken( 'auth/recover-password-send-mail', { usuario_email: email }, 'POST' );
        const body = await resp.json();

        if( body.error === false ) {
          
            cogoToast.success("Se envió un email con las instrucciones para recuperar su contraseña.", { hideAfter:5 });
            dispatch( appSetLoader(false, "") );
            return { error: false };

        } else {
            cogoToast.error(body.msg, { hideAfter:5 });
            dispatch( appSetLoader(false, "") );
            return { error: true };
        }
        
    }
}



export const checkTokenResetPassword = ( token ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Validando el token de recuperación...") );

        const resp = await fetchSinToken( 'auth/recover-password-token-verify', { token: token }, 'POST' );
        const body = await resp.json();

        if( body.error === false ) {
            dispatch( appSetLoader(false, "") );
            return true;

        } else {
            dispatch( appSetLoader(false, "") );
            return false;
        }
        
    }
}




export const startRecuperarClavePaso2 = ( token, password1, password2 ) => {
    return async( dispatch ) => {

        dispatch( appSetLoader(true, "Modificando contraseña...") );

        const resp = await fetchSinToken( 'auth/recover-password-confirm', { token, usuario_password: password1 }, 'POST' );
        const body = await resp.json();

        if( body.error === false ) {
            
            cogoToast.success("Contraseña modificada correctamente.", {hideAfter:5});
            dispatch( appSetLoader(false, "") );
            return { error: false };

        } else {

            if(body.msg_validation){
                cogoToast.error(body.msg_validation.details[0].message, {hideAfter:5});
            }
            else{
                cogoToast.error(body.msg, {hideAfter:5});
            }

            dispatch( appSetLoader(false, "") );
            return { error: true };

        }
        

    }
}


export const startLogout = () => {
    return ( dispatch ) => {

        localStorage.clear();
        dispatch( logout() );
    }
}

export const logout = () => ({ type: types.authLogout })

export const appSetLoader = ( visible, msg ) => ({ type: types.appSetLoader, payload: {visible, msg} })