
function unformatRUTValida(rut) {
  rut.value = rut.value.toUpperCase().replace(/[^\w\s]/gi,"");
}

// Obtiene el DV de un RUT
function testDv(T) {
  var M=0,S=1;
  for(;T;T=Math.floor(T/10)){
      S=(S+T%10*(9-M++%6))%11;
  }
  return S?S-1:'K';
} 

// Presenta un mensaje cuando el RUT es invalido
function validaRut(rut) {

  if(rut !== undefined)
  {
    unformatRUTValida(rut);
    var text = rut.value.toString();
    var num = text.substring(0, text.length-1);
    var dv = text.substring(text.length-1);

    if ( dv === testDv(num) ) {

        return true;
    }
    else{

        return false;
    }
  }
  

}




// --------------------------------- Private OLB/IOL --------------------------------------


// Cross-browser support
function trim(rut) {
  var tmp = rut.value.toString().toUpperCase();

  // Remove no number bigger than '0' at the left side
  while ( tmp.length>0 && ( tmp.charAt(0)<'1' || tmp.charAt(0)>'9') )
      tmp = tmp.substring(1, tmp.length);

  // Remove no number (not 'K') a the rigth side
  var newvalue = "";
  for ( var i=0; i<tmp.length; i++) {
      var c = tmp.charAt(i); 
      if ( (c <'0' || c >'9') && c !== 'K')
          continue;
      newvalue = newvalue + c; 
  }
  
  rut.value = newvalue;
}

function validarSiNumero(numero){
  numero.replace(/\s/g,'');
  if (/^([0-9])*$/.test(numero))
      return true;
  else
      return false;
}

export function formatRUT(in_rut) {

  if(in_rut !== undefined)
  {
    var rut = {}
      rut.value=in_rut

    trim(rut);
    var text = rut.value.toString();
    if (text !== ""){
      var num = text.substring(0, text.length-1);
      var dv = text.substring(text.length-1);
      if (validarSiNumero(num)){
          validaRut(rut);
          rut.value = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '-' + dv;

          return rut.value;

      }
      else{
          return rut.value.toLowerCase();

      }
    }
  }
  
}


