import React, {useState} from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Link } from 'react-router-dom';

function TableReporteMensualTrimestral({ columns, data}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    sortBy,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 50
 }
  },
  useSortBy,
  usePagination)



  // Render the UI for your table
  return (
  <>
    <table {...getTableProps()}  
           className="table-reporte w-full leading-normal text-md bg-white font-semibold text-black">
      
      <thead>
        {headerGroups.map(headerGroup => (

          <tr {...headerGroup.getHeaderGroupProps()} 
              className="bg-table-reporte  border-b-2 bg-blue-100 border-gray-300 font-semibold  uppercase tracking-wider  ">

            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} 
                  className="px-5 py-3 text-left">{column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
              </th>
            ))}

          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()} >
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr className="bg-tr-reporte" {...row.getRowProps()}
                className="border-b cursor-pointer ">
              {row.cells.map(cell => {

                return <td  {...cell.getCellProps()} 
                            className="bg-td-reporte p-2 px-5 text-left ">{cell.render('Cell', {  id: cell.row.original.id, 
                                                                                    estadorequerimiento_id: cell.row.original.estadorequerimiento_id, 
                                                                                    estadorequerimiento_nombre: cell.row.original.estadorequerimiento_nombre })}
                       </td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>




    </>
  )
}


export default TableReporteMensualTrimestral
