import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { startLogout } from '../../actions/auth';

import { useHistory} from 'react-router-dom';
import { faColumns, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo_mtemuco from '../../assets/images/logo_mtemuco.png';


export const MenuLeft = (props) => {

  let activoStyle = ' bg-gray-600 text-green-400 font-normal text-sm rounded-lg p-2 ';
  let defaultStyle = 'bg-temuco-menu rounded-lg p-2 text-gray-300  text-md cursor-pointer hover:bg-gray-700';


  const dispatch = useDispatch();
  let history = useHistory();

  const { usuario } = useSelector( state => state.auth );
  const { menuActual } = useSelector( state => state.app );

  const handleLogout = () => {
      dispatch( startLogout() );
  }


  const DashboardStyles = menuActual === 'dashboard' ? activoStyle : defaultStyle;
  const UsuariosStyles = menuActual === 'usuarios' ? activoStyle : defaultStyle;
  const RequerimientosStyles = menuActual === 'requerimientos' ? activoStyle : defaultStyle;
  const RequerimientosCallStyles = menuActual === 'requerimientos-callcenter' ? activoStyle : defaultStyle;
  const RequerimientosDptoStyles = menuActual === 'requerimientos-departamento' ? activoStyle : defaultStyle;
  const RequerimientosFuncStyles = menuActual === 'requerimientos-funcionario' ? activoStyle : defaultStyle;

  const ReporteRequerimientosStyles = menuActual === 'reporte-requerimientos' ? activoStyle : defaultStyle;
  const ReporteMensualTrimestralStyles = menuActual === 'reporte-mensual-trimestral' ? activoStyle : defaultStyle;

  const MisDatosStyles = menuActual === 'mis-datos' ? "bg-gray-700 text-gray-300" : "bg-gray-800";


  const [isExpanded, toggleExpansion] = useState(false)

  return(

    <div className="flex-none lg:flex w-full lg:w-64 max-w-full lg:max-w-2x1 lg:h-full lg:min-h-full text-gray-100">
      <div id="menu_sidebar" className="lg:flex lg:flex-col px-0 py-2 text-sm w-full lg:w-64 fixed lg:min-h-screen z-50 bg-gray-800 text-gray-400 ">

        <div className="h-8 lg:h-16 text-white font-medium flex items-center px-4 mb-3 lg:block lg:flex-1 ">
          <div className="lg:hidden block mr-6 mt-8">
              <button onClick={() => toggleExpansion(!isExpanded)} >
                  <svg className="fill-current text-gray-500 h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M16.4 9H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zm0 4H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zM3.6 7h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1z"/>
                  </svg>
              </button>
          </div>

          <div className="flex flex-row lg:flex-col ">
            <img src={ logo_mtemuco } className="m-auto lg:mt-1 w-32 h-20 " alt="Logo Municipalidad de Temuco"></img>
            <h3 className="w-full text-center text-gray-300 font-light">Sistema de Gestión Municipal de Requerimientos Fono Emergencias 1409</h3>
          </div>


        </div>

        <div id="menu-detalle" className={`${ isExpanded ? `block` : `hidden` } px-4 lg:block lg:flex-1   text-sm `} >

          { (usuario.rol_id === 1 || usuario.rol_id === 5)  ?
          <>
            <div className={'block align-middle mt-6  font-normal text-sm text-gray-500 ' }>
              <span className={'inline-block pb-1 '}>
                Dashboard
              </span>
            </div>


            <div className={'block align-middle ml-2 cursor-pointer ' + DashboardStyles } onClick={() => { history.push('/dashboard/') } }>
              <FontAwesomeIcon icon={faColumns} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Dashboard
              </span>
            </div>
          </>
          : null }

          { usuario.rol_id === 1 || usuario.rol_id === 2 || usuario.rol_id === 3 ||
            usuario.rol_id === 4 || usuario.rol_id === 5 ?
          <div className={'block align-middle mt-6  font-normal text-sm text-gray-500 ' }>
            <span className={'inline-block pb-1 '}>
              Requerimientos
            </span>
          </div>
          : null }

          { usuario.rol_id === 1 || usuario.rol_id === 5 ?
          <>
            <div className={'block align-middle ml-2 cursor-pointer ' + RequerimientosStyles } onClick={() => { history.push('/requerimientos/') } }>
              <FontAwesomeIcon icon={faColumns} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Requerimientos-Dptos.M.
              </span>
            </div>
          </>
          : null }

          { usuario.rol_id === 2 ?
          <>
            <div className={'block align-middle ml-2 cursor-pointer ' + RequerimientosCallStyles } onClick={() => { history.push('/requerimientos/callcenter') } }>
              <FontAwesomeIcon icon={faColumns} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Requerimientos
              </span>
            </div>
          </>
          : null }


          { usuario.rol_id === 3 ?
          <>
            <div className={'block align-middle ml-2 cursor-pointer ' + RequerimientosDptoStyles } onClick={() => { history.push('/requerimientos/departamento') } }>
              <FontAwesomeIcon icon={faColumns} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Requerimientos (Dpto)
              </span>
            </div>
          </>
          : null }


          { usuario.rol_id === 3 || usuario.rol_id === 4 ?
          <>
            <div className={'block align-middle ml-2 cursor-pointer ' + RequerimientosFuncStyles } onClick={() => { history.push('/requerimientos/funcionario') } }>
              <FontAwesomeIcon icon={faColumns} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Requerimientos (Func)
              </span>
            </div>
          </>
          : null }

          { usuario.rol_id === 1 ?
          <>
            <div className={'block align-middle mt-6  font-normal text-sm text-gray-500  ' }>
              <span className={'inline-block pb-1 '}>
                Administración
              </span>
            </div>

            <div className={'block align-middle ml-2 cursor-pointer ' + UsuariosStyles } onClick={() => { history.push('/usuarios/') } }>
              <FontAwesomeIcon icon={faUser} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Usuarios
              </span>
            </div>
          </>
          : null }


          { usuario.rol_id === 1 || usuario.rol_id === 5 ?
          <>
            <div className={'block align-middle mt-6  font-normal text-sm text-gray-500  ' }>
              <span className={'inline-block pb-1 '}>
                Reportes
              </span>
            </div>

            <div className={'block align-middle ml-2 cursor-pointer ' + ReporteRequerimientosStyles } onClick={() => { history.push('/reportes/requerimientos') } }>
              <FontAwesomeIcon icon={faUser} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Requerimientos
              </span>
            </div>

            <div className={'block align-middle ml-2 cursor-pointer ' + ReporteMensualTrimestralStyles } onClick={() => { history.push('/reportes/mensual-trimestral') } }>
              <FontAwesomeIcon icon={faUser} size='1x' />
              <span
                className={'inline-block pl-3 '}

                >
                Mensual-Trimestral
              </span>
            </div>

          </>
          : null }


      </div>


      <div className={`${ isExpanded ? `block` : `hidden` } lg:flex lg:flex-col h-60  rounded-lg mt-4   `} >


        <div className={'flex flex-row items-center align-middle cursor-pointer  px-4 py-5 ' + MisDatosStyles}  onClick={() => { history.push('/mis-datos/') } } >

          <span className="bg-gray-700 px-2 py-2 rounded-full">
            <FontAwesomeIcon icon={faUser} size='1x' className="mx-1 my-0"/>
          </span>

          <div className="flex flex-row "  >

            <ul>
              <li className="pl-3">
              <span
                className="text-md font-normal text-green-500  text-left leading-none"
                >
                { usuario.usuario_nombre.substring(0,30) }
              </span>

              </li>
              <li>
              <span className="pl-3 text-md  hover:text-gray-300 font-light ">Editar mis datos</span>
              </li>
            </ul>

          </div>

        </div>


          <div onClick={ handleLogout } className={'block align-middle ml-2 mb-8 mt-5 px-4 font-light cursor-pointer text-gray-300 text-base hover:text-green-600 ' }>
            <FontAwesomeIcon icon={faSignOutAlt} size='1x' />
            <span className={'inline-block pl-3 pb-1'} >
              Salir
            </span>

            {/* <div className="text-left text-gray-500 opacity-50 text-xs">CLIENT VERSION: {props.appSettings.CLIENT_VERSION}</div>
            <div className="text-left text-gray-500 opacity-50 text-xs">API VERSION: {props.appSettings.API_VERSION}</div> */}

          </div>

      </div>

    </div>

  </div>

)};


