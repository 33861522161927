
export const types = {

    usuariosNuevo: '[usuarios] Nuevo Usuario',
    usuariosEditar: '[usuarios] Editar Usuario',
    usuariosDesactivar: '[usuarios] Desactivar Usuario',
    usuariosEliminar: '[usuarios] Eliminar Usuario',
    usuariosListar: '[usuarios] Listar Usuarios',
    usuariosSetRoles: '[usuarios] Obtener Roles de usuario',


    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',

    authMisDatosModificar: '[auth] Modificar mis datos',

    appSetLoader: '[app] Set loader',
    appSetMenuActual: '[app] Set menu actual',
    appSetApiVersion: '[app] Set Api version',
    appSetClientVersion: '[app] Set Client version',


}