import { types } from '../types/types';


const initialState = {
    loader: false,
    loader_msg: "",
    menuActual: "",
    apiVersion: "",
    clientVersion: ""
}

export const appReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
        case types.appSetLoader:
            return {
                ...state,
                loader: action.payload.visible,
                loader_msg: action.payload.msg
            }

        case types.appSetMenuActual:
            return {
                ...state,
                menuActual: action.payload
            }

        case types.appSetApiVersion:
            return {
                ...state,
                apiVersion: action.payload
            }

        case types.appSetClientVersion:
            return {
                ...state,
                clientVersion: action.payload
            }

        default:
            return state;
    }

}


