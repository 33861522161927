import React from 'react';


import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';


export const AccesoDenegado = () => {


  return (
    <div className='lg:flex min-h-full h-full w-full '>
        <MenuLeft />
        <Main>

        <div className="text-2xl h-full flex flex-wrap content-center ">
          
          <span className=" font-semibold mx-auto my-auto text-gray-500">
            Acceso Denegado
          </span>
        </div>


        </Main>
    </div>
  )
}
