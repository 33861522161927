import { combineReducers } from 'redux';

import { appReducer } from './appReducer';
import { usuariosReducer } from './usuariosReducer';
import { authReducer } from './authReducer';

export const rootReducer = combineReducers({
    usuarios: usuariosReducer,
    auth: authReducer,
    app: appReducer
})

