import React from 'react';
import { useSelector } from 'react-redux';

export const Main = ({ children }) => {

  const { loader, loader_msg } = useSelector( state => state.app );

return (
  
    <div id="main-container" className="flex-1 flex-col w-full h-full min-h-full pt-16 lg:pt-0 bg-gray-300  ">
      {children}

      { loader ? 
      <div id="preloader" className="preloader">

        <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" /> 
        </svg>
        <p id="preloader_text">{ loader_msg }</p>
      </div>
      :
      null
      }
      
    </div>
  

)};

export default Main;
