import React, {useState} from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Link } from 'react-router-dom';

function TableReporteRequerimientos({ columns, data}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    sortBy,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data
    
  },
  useSortBy,
  usePagination)



  // Render the UI for your table
  return (
  <>
    <table {...getTableProps()}  
           className="w-full leading-normal text-md bg-white">
      
      <thead>
        {headerGroups.map(headerGroup => (

          <tr {...headerGroup.getHeaderGroupProps()} 
              className="border-b-2 bg-gray-200 border-gray-300 font-semibold text-xs text-gray-600 uppercase tracking-wider">

            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} 
                  className="px-5 py-3 text-left">{column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
              </th>
            ))}

          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()} >
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}
                className="border-b cursor-pointer text-gray-800">
              {row.cells.map(cell => {

                return <td  {...cell.getCellProps()} 
                            className="p-2 px-5 text-left ">{cell.render('Cell', {  id: cell.row.original.id, 
                                                                                    estadorequerimiento_id: cell.row.original.estadorequerimiento_id, 
                                                                                    estadorequerimiento_nombre: cell.row.original.estadorequerimiento_nombre })}
                       </td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>



    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 px-6">
      <div className="flex-1 flex items-center justify-between">


        <div>
          <span className="relative z-0 inline-flex shadow-sm">
            <button onClick={() => gotoPage(0)} 
                    disabled={!canPreviousPage}
                    className=" relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 
                                bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 
                                focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 
                                active:text-gray-500 transition ease-in-out duration-150">
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} 
                    disabled={!canPreviousPage}
                    className=" -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm 
                                leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none 
                                focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 
                                transition ease-in-out duration-150">
              {'Anterior'}
            </button>{' '}
            <button onClick={() => nextPage()} 
                    disabled={!canNextPage}
                    className=" -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm 
                                leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none 
                                focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 
                                transition ease-in-out duration-150">
              {'Siguiente'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} 
                    disabled={!canNextPage}
                    className=" -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white 
                                text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none 
                                focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition 
                                ease-in-out duration-150">
              {'>>'}
            </button>{' '}
          </span>
        </div>



        <div>
          <p className="text-sm leading-5 text-gray-700">
            <span>
            Página{' '}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{' '}
          </span>
          </p>

          


        </div>

      </div>
    </div>

    </>
  )
}


export default TableReporteRequerimientos
