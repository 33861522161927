import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { appSetMenuActual } from '../../actions/app'
import { cerrarRequerimiento, obtenerRequerimientos, obtenerUltimosRequerimientos} from '../../actions/requerimientos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientos from './TableRequerimientos';


import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_CONFIG } from '../../config/app'


import 'react-confirm-alert/src/react-confirm-alert.css';


import moment from 'moment';
import 'moment/locale/es';


moment.locale('es');



export const RequerimientosVer = (props) => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  


  let history = useHistory();
  const dispatch = useDispatch();

  const { usuario } = useSelector( state => state.auth );

  const handleVolver = () => {
    history.goBack()
  }

  const [requerimiento, setRequerimiento] = useState([]);
  const [ultimosRequerimientos, setUltimosRequerimientos] = useState([])
 

  useEffect(() => {
      dispatch( appSetMenuActual("requerimientos-call") );
    
      if(props.match.params.id !== undefined){
        dispatch( obtenerRequerimientos( props.match.params.id ) ).then( 
          
          (result) => {
         
            setRequerimiento(result[0])

            if(result[0].telefono_origen !== null)
            dispatch( obtenerUltimosRequerimientos( props.match.params.id ) ).then( (result2) => setUltimosRequerimientos(result2) )

          }
          
        )
        
      }



  }, [ dispatch ])


  



  const [formValues, setValues] = useState({
    
    cierre_observaciones: null,
    cierre_imagen_1: null,
    cierre_imagen_2: null
  });


  const handleInputChange = ({ target }) => {

      setValues({
          ...formValues,
          [ target.name ]: target.value
      });


  }
                    

  const handleSubmitCerrarRequerimiento = ( e ) => {
    e.preventDefault();

    dispatch( cerrarRequerimiento( requerimiento, formValues ) )
      .then( response => {

        if(response.error === false)
          //history.goBack();
          dispatch( obtenerRequerimientos( props.match.params.id ) ).then( 
          
            (result) => {
           
              setRequerimiento(result[0])
  
              if(result[0].telefono_origen !== null)
              dispatch( obtenerUltimosRequerimientos( props.match.params.id ) ).then( (result2) => setUltimosRequerimientos(result2) )
  
            }
            
          )
      
      });
    
  }




  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Fecha Llamada', accessor: 'fecha_inicio_llamada' },
            { Header: 'Tipo Requerimiento', accessor: 'tiporequerimiento_nombre' },
            
            { Header: 'Estado',  
              Cell: (props) => {
                return (

                  <>
                  { props.estadorequerimiento_id === 1 ?

                  <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.estadorequerimiento_nombre}</span>

                  :  props.estadorequerimiento_id === 2 ?

                  <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  :  props.estadorequerimiento_id === 3 ?

                  <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  :  props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5 ?

                  <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span> 

                  :
                  null }

                  </>
                );
              }
            },
          
            { Header: 'Ver',  
              Cell: (props) => {
                return (
                  <Link to={`/requerimientos/ver/${props.id}`} target="_blank">
                    <span className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </span>
                  </Link>
                )

              }
            }
          ],
    []
  )









  const handleUploadImage = e => {
    let file = e.target.files[0]
    let inputName = e.target.name;


    if(file) {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {

        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        setValues({...formValues, [inputName]: fileInfo})

      }

  
    }

  }



  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className='flex-1 flex flex-col'>
        
        <div className='flex items-start mb-4 text-sm p-8 md:p-16 md:pt-12'>
          <div className='flex-1 overflow-hidden  rounded p-2 lg:p-6 lg:pt-0 text-left '>
            
          { query.get("from") !== "reporte_requerimientos" ?
          <button 
                id='btn_volver' 
                type='button' 
                className='bg-gray-500 hover:bg-gray-600 text-white text-base font-normal py-2 px-8 mb-5 rounded'
                onClick={handleVolver}
              >
                <FontAwesomeIcon icon={faArrowLeft} size='1x' />
                <span className="pl-2">
                Volver
                </span>
          </button>
          :
          null
          }
      


          { props.match.params.id !== undefined ?
            
            <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 bg-white' >

              <span className="font-bold text-lg">Información General</span>
              <hr className="mt-2" />

            
              <div className="flex flex-row -mx-3 mt-5 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-600 font-semibold w-48" >
                    ID Requerimiento:
                  </label>
                  
                  <span className="font-normal">{requerimiento.id}</span>
                </div>

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0 ">
                  <label className="block uppercase tracking-wide text-gray-600  font-semibold w-48" >
                    Origen Requerimiento:
                  </label>
                  
                  <span className="text-md font-normal">{requerimiento.origenrequerimiento_nombre}</span>


                </div>

              </div>



              <div className="flex flex-row -mx-3 mt-4 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-600 font-semibold w-48" >
                    Fecha inicio llamada:
                  </label>
                  
                  <span className="text-md font-normal">{requerimiento.fecha_inicio_llamada}</span>
                </div>


                <div className="flex flex-row w-full px-3 mb-6 md:mb-0 ">
                  <label className="block uppercase tracking-wide text-gray-600  font-semibold w-48" >
                    Teléfono origen:
                  </label>
                  
                  { requerimiento.origenrequerimiento_id === 1 ?
                  <span className="text-md font-normal">{requerimiento.telefono_origen}</span>
                  :
                  <span>-</span>
                  }

                </div>


                

              </div>



              <div className="flex flex-row -mx-3 mt-4 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-600 font-semibold w-48" >
                    Fecha fin llamada:
                  </label>
                  
                  { requerimiento.origenrequerimiento_id === 1 ?
                  <span className="text-md font-normal">{requerimiento.fecha_fin_llamada}</span>
                  :
                  <span>-</span>
                  }

                </div>


                <div className="flex flex-row w-full px-3 mb-6 md:mb-0 ">
                  <label className="block uppercase tracking-wide text-gray-600  font-semibold w-48" >
                    Estado:
                  </label>
                  

                  { requerimiento.estadorequerimiento_id === 1 ?

                  <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{requerimiento.estadorequerimiento_nombre}</span>

                  :  requerimiento.estadorequerimiento_id === 2 ?

                  <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {requerimiento.estadorequerimiento_nombre} </span> 

                  :  requerimiento.estadorequerimiento_id === 3 ?

                  <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {requerimiento.estadorequerimiento_nombre} </span> 

                  :  requerimiento.estadorequerimiento_id === 4 || requerimiento.estadorequerimiento_id === 5 ?

                  <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {requerimiento.estadorequerimiento_nombre} </span> 

                  :
                  null }


                </div>


              </div>

            </div>

          : null }





          { props.match.params.id !== undefined && requerimiento.telefono_origen !== null ?

            <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' > 

              <span className="font-semibold text-md text-gray-800">Últimos requerimientos asociados al número de teléfono</span>
              <hr className="mt-2" />


              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                <TableRequerimientos columns={columns} data={ultimosRequerimientos} />

                </div>
              </div>



            </div>

          : null }








          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >  

            <span className="font-bold text-lg">Información Vecino</span>
            <hr className="mt-2" />



            <div className="flex flex-row -mx-3 mb-7 mt-6">

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" >
                  Nombre
                </label>
                <span className="text-md font-semibold">{requerimiento.contacto_nombre}</span>
                
              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="telefono_contacto">
                  Teléfono de contacto
                </label>
                <span className="text-md font-semibold">{requerimiento.contacto_telefono_contacto}</span>
                
              </div>

            </div>





            <div className="flex flex-row -mx-3 mb-7">

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="rut">
                  RUT
                </label>
                <span className="text-md font-semibold">{requerimiento.contacto_rut}</span>
                
              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <span className="text-md font-semibold">{requerimiento.contacto_email}</span>
                
                
              </div>

            </div>





            <div className="flex flex-wrap -mx-3 mb-7">
              <div className="w-full lg:w-2/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="rol">
                  Sector
                </label>
                
                <span className="text-md font-semibold">{requerimiento.sector_nombre}</span>

              </div>
            </div>



            <div className="w-full px-3 mb-7 -mx-3">
              <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="direccion">
                Dirección:
              </label>

              <span className="text-md font-semibold">{requerimiento.contacto_direccion}</span>
              
              
            </div>


            <div className="w-full px-3 mb-7 -mx-3">
              <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="villa">
                Villa / Población / Barrio
              </label>

              <span className="text-md font-semibold">{requerimiento.contacto_villa_pob_barrio}</span>
            
            </div>

          </div>


          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >  

            <span className="font-bold text-lg">Información requerimiento</span>
            <hr className="mt-2" />

              

              <div className="flex flex-wrap -mx-3 mb-5 mt-7  ">

                <div className="w-full lg:w-3/4 px-3 mb-5 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Unidad Operativa
                  </label>
                  
                  <span className="text-md font-semibold">{requerimiento.unidadoperativa_nombre}</span>
                </div>

              </div>


              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Tipo de Requerimiento
                  </label>
                  
                  <span className="text-md font-semibold">{requerimiento.tiporequerimiento_nombre}</span>
                </div>
              </div>


              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Departamento
                  </label>
                  
                  <span className="text-md font-semibold">{requerimiento.departamento_nombre}</span>
                </div>
              </div>


              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Requerimiento
                  </label>
                  
                  <span className="text-md font-semibold">{requerimiento.requerimientotipificado_nombre}</span>
                </div>
              </div>


              { requerimiento.unidadoperativa_id === 2 ?
              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Enviar movil a terreno
                  </label>

                  {requerimiento.enviar_movil_terreno === 1 ?
                  <span className="text-md font-semibold">Si</span>
                  :
                  <span className="text-md font-semibold">No</span>
                  }

                </div>
              </div>
              :
              null }




              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-1" htmlFor="rol">
                    Detalle Requerimiento
                  </label>
                  
                  <span className="text-md font-semibold">{requerimiento.observaciones}</span>
                </div>
              </div>






              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4" htmlFor="rol">
                    Imagenes
              </label>


              <div className="flex flex-row -mx-3 mb-8">
              


                <div className="w-60 px-3 mb-6 md:mb-0">
                                   
                  {requerimiento.imagen_1 !== null ?
                  <a href={`${APP_CONFIG.apiUrl}${requerimiento.imagen_1}`} target="_blank">
                    <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={`${APP_CONFIG.apiUrl}${requerimiento.imagen_1}`} />
                  </a>
                  : null }

                </div>

                <div className="w-60 px-3 mb-6 md:mb-0">
           
                  {requerimiento.imagen_2 !== null ?
                  <a href={`${APP_CONFIG.apiUrl}${requerimiento.imagen_2}`} target="_blank">
                    <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={`${APP_CONFIG.apiUrl}${requerimiento.imagen_2}`} />
                  </a>
                  : null }

                </div>


                <div className="w-60 px-3 mb-6 md:mb-0">
              
                  {requerimiento.imagen_3 !== null ?
                  <a href={`${APP_CONFIG.apiUrl}${requerimiento.imagen_3}`} target="_blank">
                    <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={`${APP_CONFIG.apiUrl}${requerimiento.imagen_3}`} />
                  </a>
                  : null }

                </div>




              </div>







        </div>
    

            
        {requerimiento.estadorequerimiento_id === 3 && (usuario.rol_id === 1 || usuario.rol_id === 2 || usuario.rol_id === 3 || usuario.rol_id === 4) ?
        <form onSubmit={handleSubmitCerrarRequerimiento} autoComplete="off">

          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >  

          <span className="font-bold text-lg">Cierre Requerimiento</span>
          <hr className="mt-2 mb-4" />

          <div className="flex flex-row">

            <div className="flex flex-wrap -mx-3 mb-8 w-full">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="rol">
                  Detalle cierre Requerimiento
                </label>
                
                <label className="block">
                  <textarea id="cierre_observaciones" name="cierre_observaciones" onChange={handleInputChange} className="form-textarea mt-1 block w-full border border-gray-300 rounded-lg " rows="8" ></textarea>
                </label>
              </div>
            </div>


            <div className="px-3 mb-6 md:mb-0 w-96 ml-8">
                
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="rol">
                  Imagen
                </label>
                    
                <label className="block">

                  <label className="w-full flex flex-col items-center px-4 py-2 bg-white text-gray-400 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-gray-500 hover:text-white">
                    <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-1 text-xs leading-normal">Seleccione Imagen </span>
                    <input 
                        type="file"
                        name="cierre_imagen_1"
                        id="cierre_imagen_1"
                        accept=".jpeg, .png, .jpg" 
                        onChange={handleUploadImage}   
                        className="hidden" 
                    />
                  </label>



                
                </label>
                
                {formValues.cierre_imagen_1 !== null ?
                <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={formValues.cierre_imagen_1.base64} />
                : null }

              </div>

              
            </div>




            <hr className="mt-2 mb-4" />


            <div className="w-full px-0 text-left pt-4">

              <button 
                id='btn_confirmar' 
                type='submit' 
                className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
              >
              <FontAwesomeIcon icon={faSave} size='1x' />
              <span className="pl-2">
              Ingresar cierre Requerimiento
              </span>
              </button>
            </div>

            </div>

          </form>
          : null }

          {requerimiento.estadorequerimiento_id === 4 && requerimiento.requerimientotipificado_flujo === 1 ?
          <>
          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >  

            <span className="font-bold text-lg">Cierre Requerimiento</span>
            <hr className="mt-2 mb-4" />

            <div className="flex flex-row">

              <div className="flex flex-wrap -mx-3 mb-8 w-full">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="rol">
                    Detalle cierre Requerimiento
                  </label>
                  
                  <label className="block">
                    <p>{requerimiento.cierre_observaciones}</p>
                  </label>
                </div>
              </div>


              <div className="px-3 mb-6 md:mb-0 w-96 ml-8">
                  
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1" htmlFor="rol">
                    Imagen
                  </label>
            
                  
                  {requerimiento.cierre_imagen_1 !== null ?
                    <a href={`${APP_CONFIG.apiUrl}${requerimiento.cierre_imagen_1}`} target="_blank">
                      <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={`${APP_CONFIG.apiUrl}${requerimiento.cierre_imagen_1}`} />
                    </a>
                    : null }

                </div>

                
              </div>



            </div>
            </>
            : null }

          

          </div>
        </div>
     
    </div>
      </Main>
    </div>
  )
}
