import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';


export const PrivateRoute = ({
    isAuthenticated,
    isChecked,
    usuario,
    roles,
    usuarioPathInicial,
    component: Component,
    ...rest
}) => {

    if(usuarioPathInicial){

        switch (usuarioPathInicial.rol_id) {
            case 1:
                return (<Route { ...rest } component={ (props) => ( <Redirect to="/dashboard" /> )} />)
            case 2:
                return (<Route { ...rest } component={ (props) => ( <Redirect to="/requerimientos/callcenter/" /> )} />)
            case 3:
                return (<Route { ...rest } component={ (props) => ( <Redirect to="/requerimientos/departamento/" /> )} />)
            case 4:
                return (<Route { ...rest } component={ (props) => ( <Redirect to="/requerimientos/funcionario/" /> )} />)
            case 5:
                return (<Route { ...rest } component={ (props) => ( <Redirect to="/dashboard/" /> )} />)
          }


        
    }

    if(usuario && roles){

    if(!(roles.includes(usuario.rol_id)))
    return (
        <Route { ...rest }
            component={ (props) => (
                <Redirect to="/acceso-denegado" />
            )}
        
        />
    )


    }

    


    if(isChecked)
    return (
        <Route { ...rest }
            component={ (props) => (
                ( isAuthenticated )
                    ? ( <Component { ...props } /> )
                    : ( <Redirect to="/login" /> )
            )}
        
        />
    )
    else
    return null;
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    // component: PropTypes.func.isRequired
}
