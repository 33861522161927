import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';


import { appSetMenuActual } from '../../actions/app'
import { generarRequerimiento, obtenerRequerimientos, obtenerUltimosRequerimientos, obtenerSectores, obtenerUnidadesOperativas,  obtenerRequerimientosTipificados } from '../../actions/requerimientos'
import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';

import  TableRequerimientos  from './TableRequerimientos';

import { formatRUT } from '../../helpers/Rut';

import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import 'moment/locale/es';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

moment.locale('es');

registerLocale("es", es);
setDefaultLocale("es");


export const RequerimientosEditar = (props) => {

  let history = useHistory();
  const dispatch = useDispatch();

  const [requerimiento, setRequerimiento] = useState([]);
  const [ultimosRequerimientos, setUltimosRequerimientos] = useState([])
  const [sectores, setSectores] = useState([])
  const [unidadesOperativas, setUnidadOperativa] = useState([])
  const [estadoFinalDisabled, setEstadoFinalDisabled] = useState(true)
  const [estadoFinal, setEstadoFinal] = useState("")
  const [requerimientosTipificados, setRequerimientosTipificados] = useState([])


  useEffect(() => {
      dispatch( appSetMenuActual("requerimientos-call") );

      if(props.match.params.id !== undefined){
        dispatch( obtenerRequerimientos( props.match.params.id ) ).then(

          (result) => {

            setRequerimiento(result[0])

            dispatch( obtenerUltimosRequerimientos( props.match.params.id ) ).then( (result2) => {

              if(result2[0] !== undefined) {

                setValues({...formValues,
                            nombre: result2[0].contacto_nombre,
                            rut: result2[0].contacto_rut,
                            email: result2[0].contacto_email,
                            sector_id: result2[0].sector_id,
                            direccion: result2[0].contacto_direccion,
                            villa: result2[0].contacto_villa_pob_barrio,
                            telefono_contacto: result[0].telefono_origen })

              }
              else {
                setValues({
                  ...formValues,
                  telefono_contacto: result[0].telefono_origen })
              }

              setUltimosRequerimientos(result2)

            })

          } // end result

        )

      }


      dispatch( obtenerSectores() ).then( (result) => { setSectores(result) } )
      dispatch( obtenerUnidadesOperativas() ).then( (result) => setUnidadOperativa(result) )

      dispatch( obtenerRequerimientosTipificados() ).then( (result) => setRequerimientosTipificados(result) )



  }, [ dispatch ])






  const [formValues, setValues] = useState({
    fecha_ingreso: new Date(),
    nombre: null,
    telefono_contacto: null,
    rut: "",
    email: null,
    sector_id: 0,
    direccion: null,
    villa: null,
    unidadoperativa_id: 0,
    requerimientotipificado_id: 0,
    tiporequerimiento_nombre: "",
    departamento_nombre: "",
    enviar_movil_terreno: 0,
    patrullamovil_id: 0,
    observaciones: null,
    imagen_1: null,
    imagen_2: null,
    imagen_3: null,

    cierre_observaciones: null,
    cierre_imagen_1: null,
    cierre_imagen_2: null,

    estado_final: ""
  });





  const handleInputChange = ({ target }) => {

      setValues({
          ...formValues,
          [ target.name ]: target.value
      });

  }


  const onChangeEstadoFinal = ({ target }) => {

    setEstadoFinal(target.value)

  }




  const handleInputRut = (event) => {

    if(event.target.value!==undefined && event.target.value!==null)
    {
      if(formatRUT(event.target.value) !== undefined)
      {
        let rut = formatRUT(event.target.value).replace(/\./g, '').replace(/-/g, '');

        setValues({
          ...formValues,
          rut: rut
        });
      }

  }

  }


  const handleFormatRut = event => {

    if(formatRUT(event.target.value) !== undefined && formatRUT(event.target.value) !== null)
    {
      document.getElementById("rut").value = formatRUT(event.target.value);
    }
    else
    {
      document.getElementById("rut").value = "";
      setValues({
        ...formValues,
        rut: ""
      });
    }
  }



  const handleMovilTerrenoChange = event => {
    setValues({...formValues, enviar_movil_terreno: event.target.checked === true ? 1 : 0 });
  }


  const handleUploadImage = e => {
    let file = e.target.files[0]
    let inputName = e.target.name;


    if(file) {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {

        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        setValues({...formValues, [inputName]: fileInfo})

      }


    }

  }





  const handleSubmitGenerarRequerimiento = ( e ) => {
    e.preventDefault();

    setValues({...formValues, estado_final: estadoFinal})

    if(formValues.sector_id === 0){
      alert("Debe seleccionar un Sector.")
      document.getElementById("sector_id").focus();
    }
    else if(formValues.unidadoperativa_id === 0){
      alert("Debe seleccionar una Unidad Operativa.")
      document.getElementById("unidadoperativa_id").focus();
    }
    else if(formValues.requerimientotipificado_id === 0){
      alert("Debe seleccionar un Requerimiento.")
      document.getElementById("requerimientotipificado_id").focus();
    }
    else if(formValues.enviar_movil_terreno === 1 && formValues.patrullamovil_id === 0){
      alert("Debe seleccionar una unidad de Patrullaje Movil.")
      document.getElementById("enviar_movil_terreno").focus();
    }
    else if(formValues.nombre === null || formValues.nombre.trim() === ""){
      alert("Debe ingresar un nombre.")
      document.getElementById("requerimientotipificado_id").focus();
    }
    else if(formValues.telefono_contacto === null || formValues.telefono_contacto.trim() === ""){
      alert("Debe ingresar un teléfono de contacto.")
      document.getElementById("telefono_contacto").focus();
    }
    else if(props.match.params.id === undefined && !moment(formValues.fecha_ingreso).isValid()){
      alert("Debe seleccionar una Fecha de Ingreso.")
      document.getElementById("fecha_ingreso").focus();


    }
    else{

      dispatch( generarRequerimiento( requerimiento, formValues ) )
      .then( response => {

        if(response.error === false)
          history.goBack();

      });

    }



  }


  const items_select_sectores = [];

  sectores.forEach(function(valor, indice, array) {

    if (valor.id !== null && valor.id !== undefined) {
      items_select_sectores.push( <option value = { valor.id } key ={`option_sector_${ valor.id }`} > { valor.nombre } </option>
        )
    }

  });


  const items_select_unidades_operativas = [];

  unidadesOperativas.forEach(function(valor, indice, array) {

    if (valor.id !== null && valor.id !== undefined) {
      items_select_unidades_operativas.push( <option value = { valor.id } key ={`option_rol_${ valor.id }`} > { valor.nombre } </option>
        )
    }

  });



  const items_select_requerimientos_tipificados = [];

  requerimientosTipificados.forEach(function(valor, indice, array) {

    if (valor.id !== null && valor.id !== undefined) {
      items_select_requerimientos_tipificados.push( <option value = { valor.id } key ={`option_rol_${ valor.id }`} > { valor.nombre } </option>
        )
    }

  });






  useEffect(() => {

    requerimientosTipificados.filter(x => x.id == formValues.requerimientotipificado_id).map(x => {
      setValues({...formValues, departamento_nombre: x.departamento_nombre, tiporequerimiento_nombre: x.tiporequerimiento_nombre, estado_final: x.flujo === 1 ? "derivado" : "cerrado"})
      setEstadoFinalDisabled(x.flujo_bloqueado === 1 ? true : false)
      setEstadoFinal(x.flujo === 1 ? "derivado" : "cerrado")
    });


  }, [ dispatch, formValues.requerimientotipificado_id ])










  const columns = useMemo(
    () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Fecha Llamada', accessor: 'fecha_inicio_llamada' },
            { Header: 'Tipo Requerimiento', accessor: 'tiporequerimiento_nombre' },

            { Header: 'Estado',
              Cell: (props) => {
                return (

                  <>
                  { props.estadorequerimiento_id === 1 ?

                  <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default whitespace-nowrap">{props.estadorequerimiento_nombre}</span>

                  :  props.estadorequerimiento_id === 2 ?

                  <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>

                  :  props.estadorequerimiento_id === 3 ?

                  <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>

                  :  props.estadorequerimiento_id === 4 || props.estadorequerimiento_id === 5 ?

                  <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default whitespace-nowrap"> {props.estadorequerimiento_nombre} </span>

                  :
                  null }

                  </>
                );
              }
            },

            { Header: 'Ver',
              Cell: (props) => {
                return (
                  <Link to={`/requerimientos/ver/${props.id}`} target="_blank">
                    <span className="bg-green-500 text-white hover:bg-green-600 text-sm px-5 py-1 rounded"> Ver </span>
                  </Link>
                )

              }
            }
          ],
    []
  )



  const handleVolver = () => {
    history.goBack()
  }



  return (
    <div className='lg:flex min-h-full h-full w-full '>
      <MenuLeft />
      <Main>
      <div className='flex-1 flex flex-col'>

        <div className='flex items-start mb-4 text-sm p-8 md:p-16 md:pt-12'>
          <div className='flex-1 overflow-hidden  rounded p-2 lg:p-6 lg:pt-0 text-left '>

          <button
                id='btn_volver'
                type='button'
                className='bg-gray-500 hover:bg-gray-600 text-white text-base font-bold py-2 px-8 mb-5 rounded'
                onClick={handleVolver}
              >
                <FontAwesomeIcon icon={faArrowLeft} size='1x' />
                <span className="pl-2">
                Volver
                </span>
              </button>


        <form onSubmit={handleSubmitGenerarRequerimiento} autoComplete="off">

          { props.match.params.id !== undefined ?

            <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 bg-white' >

              <span className="font-bold text-lg">Información General</span>
              <hr className="mt-2" />


              <div className="flex flex-row -mx-3 mt-5 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 font-semibold w-40" htmlFor="nombre">
                    ID Requerimiento:
                  </label>

                  <span>{requerimiento.id}</span>
                </div>

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0 ">
                  <label className="block uppercase tracking-wide text-gray-500  font-semibold w-40" htmlFor="nombre">
                    Teléfono origen:
                  </label>

                  <span>{requerimiento.telefono_origen}</span>
                </div>

              </div>



              <div className="flex flex-row -mx-3 mt-4 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 font-semibold w-40" htmlFor="nombre">
                    Fecha inicio llamada:
                  </label>

                  <span>{requerimiento.fecha_inicio_llamada}</span>
                </div>

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0 ">
                  <label className="block uppercase tracking-wide text-gray-500  font-semibold w-40" htmlFor="nombre">
                    Estado:
                  </label>


                  { requerimiento.estadorequerimiento_id === 1 ?

                  <span className="px-4 py-1  text-xs rounded-full text-green-600  bg-green-200 cursor-default">{requerimiento.estadorequerimiento_nombre}</span>

                  :  requerimiento.estadorequerimiento_id === 2 ?

                  <span className="px-4 py-1  text-xs rounded-full text-pink-600  bg-pink-200 cursor-default"> {requerimiento.estadorequerimiento_nombre} </span>

                  :  requerimiento.estadorequerimiento_id === 3 ?

                  <span className="px-4 py-1  text-xs rounded-full text-indigo-600  bg-indigo-200 cursor-default"> {requerimiento.estadorequerimiento_nombre} </span>

                  :  requerimiento.estadorequerimiento_id === 4 ?

                  <span className="px-4 py-1  text-xs rounded-full text-gray-600  bg-gray-200 cursor-default"> {requerimiento.estadorequerimiento_nombre} </span>

                  :
                  null }


                </div>

              </div>



              <div className="flex flex-row -mx-3 mt-4 text-xs">

                <div className="flex flex-row w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 font-semibold w-40" htmlFor="nombre">
                    Fecha fin llamada:
                  </label>

                  <span>{requerimiento.fecha_fin_llamada}</span>
                </div>


              </div>

            </div>

          : null }





          { props.match.params.id !== undefined ?

            <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >

              <span className="font-semibold text-md text-gray-600">Últimos requerimientos asociados al número de teléfono</span>
              <hr className="mt-2" />


              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto min-w-full">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">

                <TableRequerimientos columns={columns} data={ultimosRequerimientos} />

                </div>
              </div>



            </div>

          : null }


          { props.match.params.id === undefined ?

          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >

            <span className="font-bold text-lg">Información General</span>
            <hr className="mt-2" />

            <div className="w-1/2 px-3 mb-6 md:mb-0 mt-7">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="fecha_ingreso">
                Fecha de Ingreso
              </label>


              <DatePicker
              className="border border-gray-300 focus:border-indigo-300 p-3 rounded-lg "
              id="fecha_ingreso"
              name="fecha_ingreso"
              selected={formValues.fecha_ingreso} onChange={date => handleInputChange({ target: {name: "fecha_ingreso", value: date }})}
              locale="es"
              timeInputLabel="Hora:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput

              />


            </div>


          </div>

          : null }










          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >

            <span className="font-bold text-lg">Información Vecino</span>
            <hr className="mt-2" />



            <div className="flex flex-row -mx-3 mb-2 mt-6">

              <div className="w-full px-3 mb-6 md:mb-0">

                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="nombre">
                  <span className="text-red-600 text-bold text-md">*</span> Nombre y Apellidos
                </label>

                <input
                  className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
                  id="nombre"
                  name="nombre"
                  type="text"
                  required
                  defaultValue={formValues.nombre}
                  onChange={handleInputChange}
                  autoComplete="off"
                />

              </div>

              <div className="w-full px-3 mb-6 md:mb-0">

                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="telefono_contacto">
                  <span className="text-red-600 text-bold text-md">*</span> Teléfono de contacto
                </label>

                <input
                  className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
                  id="telefono_contacto"
                  name="telefono_contacto"
                  type="text"
                  required
                  onChange={handleInputChange}
                  defaultValue={formValues.telefono_contacto}
                  autoComplete="off"
                />

              </div>

            </div>





            <div className="flex flex-row -mx-3 mb-6">

              <div className="w-full px-3 mb-6 md:mb-0">

                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="rut">
                  RUT
                </label>

                <input
                className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
                id="rut"
                name="rut"
                type="text"
                defaultValue={formValues.rut }
                onChange={handleInputRut}
                regexp="^0*\s*(\d{1,3}(\.?\d{3})*)\-?([\dkK]{1})\s*$"
                onBlur={handleFormatRut}
                autoComplete="off"

                />

              </div>

              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
                id="email"
                name="email"
                type="email"
                defaultValue={formValues.email}
                onChange={handleInputChange}
                autoComplete="off"
                />

              </div>

            </div>





            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full lg:w-2/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="rol">
                  <span className="text-red-600 text-bold text-md">*</span> Sector
                </label>

                <div>
                  <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                  id = 'sector_id'
                  name = "sector_id"
                  onChange={handleInputChange}
                  value={formValues.sector_id}
                   >
                  <option value = "0" > - Seleccione - </option>

                  { items_select_sectores }

                  </select>
                </div>
              </div>
            </div>



            <div className="w-full px-3 mb-6 md:mb-0 -mx-3">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="direccion">
                Dirección:
              </label>
              <input
              className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
              id="direccion"
              name="direccion"
              type="direccion"
              defaultValue={formValues.direccion}
              onChange={handleInputChange}
              autoComplete="off"
              />

            </div>


            <div className="w-full px-3 mb-6 md:mb-0 -mx-3 mt-4">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2" htmlFor="villa">
                Villa / Población / Barrio
              </label>
              <input
              className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'
              id="villa"
              name="villa"
              type="villa"
              defaultValue={formValues.villa}
              onChange={handleInputChange}
              autoComplete="off"
              />

            </div>



          </div>


          <div className=' w-full lg:w-full xlg:w-1/3 border rounded border-indigo-200 p-10 mt-6 bg-white' >

            <span className="font-bold text-lg">Información requerimiento</span>
            <hr className="mt-2" />



              <div className="flex flex-wrap -mx-3 mb-5 mt-7  ">

                <div className="w-full lg:w-3/4 px-3 mb-5 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-1" htmlFor="rol">
                  <span className="text-red-600 text-bold text-md">*</span> Unidad Operativa
                  </label>

                  <div>
                    <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                    id = 'unidadoperativa_id'
                    name = "unidadoperativa_id"
                    onChange={handleInputChange}
                    value={formValues.unidadoperativa_id}
                    >
                    <option value = "0" > - Seleccione - </option>

                    { items_select_unidades_operativas }

                    </select>
                  </div>
                </div>

              </div>


              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">

                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-1" htmlFor="rol">
                    <span className="text-red-600 text-bold text-md">*</span> Requerimiento
                  </label>

                  <div>
                    <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                    id = 'requerimientotipificado_id'
                    name = "requerimientotipificado_id"
                    onChange={handleInputChange}
                    value={formValues.requerimientotipificado_id}
                    >
                    <option value = "0" > - Seleccione - </option>

                    { items_select_requerimientos_tipificados }

                    </select>
                  </div>
                </div>
              </div>





              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-1" htmlFor="rol">
                    Tipo de Requerimiento
                  </label>

                  <div>

                    <span className="inline-block select-temuco w-5/6 lg:w-3/4 bg-gray-50 border-gray-100 border-2 text-gray-400 py-3 px-4 pr-8 rounded leading-tight h-12">{formValues.tiporequerimiento_nombre}</span>

                  </div>
                </div>
              </div>


              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-1" htmlFor="rol">
                    Departamento
                  </label>

                  <div>
                  <span className="inline-block select-temuco w-5/6 lg:w-3/4 bg-gray-50 border-gray-100 border-2 text-gray-400 py-3 px-4 pr-8 rounded leading-tight h-12">{formValues.departamento_nombre}</span>
                  </div>
                </div>
              </div>

<hr />


              <div className="flex flex-wrap -mx-3 mb-2 py-3 px-1">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="inline-flex items-center mt-3" >


                    <label className="inline-flex items-center">
                      <input type="radio" className="form-radio h-5 w-5" name="estado_final" value="cerrado" checked={estadoFinal === 'cerrado'} onChange={onChangeEstadoFinal} disabled={estadoFinalDisabled} />
                      <span className="ml-2">Cerrado</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <input type="radio" className="form-radio h-5 w-5" name="estado_final" value="derivado" checked={estadoFinal === 'derivado'} onChange={onChangeEstadoFinal} disabled={estadoFinalDisabled} />
                      <span className="ml-2">Derivado</span>
                    </label>

                  </label>
                </div>
              </div>
<hr />

              <div className="flex flex-wrap -mx-3 mb-8 mt-5">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="inline-flex items-center mt-3">

                    <span className="ml-0 text-gray-700 w-40">Enviar móvil a terreno</span>

                    <input  type="checkbox"
                            id="enviar_movil_terreno"
                            name="enviar_movil_terreno"
                            className="form-checkbox h-5 w-5 text-red-600 "

                            onChange={handleMovilTerrenoChange}
                    />

                  </label>
                </div>
              </div>

              { formValues.enviar_movil_terreno === 1 ?
              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">

                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-1" htmlFor="rol">
                    Seleccionar Patrulla Móvil
                  </label>

                  <div>
                    <select className = 'inline-block select-temuco cursor-pointer w-5/6 lg:w-3/4 bg-gray-100 border-gray-100 border-2 text-gray-700 py-3 px-4 pr-8 rounded leading-tight'
                    id = 'patrullamovil_id'
                    name = "patrullamovil_id"
                    onChange={handleInputChange}
                    value={formValues.patrullamovil_id}
                    >
                    <option value = "0" > - Seleccione - </option>

                    {/* { items_select_requerimientos_tipificados }  */}
                    <option value = "1" > A398 </option>
                    <option value = "2" > A399 </option>
                    <option value = "3" > A400 </option>
                    <option value = "4" > A401 </option>
                    <option value = "5" > A402 </option>
                    <option value = "6" > A403 </option>



                    </select>
                  </div>
                </div>
              </div>
              : null }


<hr />

              <div className="flex flex-wrap -mx-3 mb-8 mt-8">
                <div className="w-full lg:w-3/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-1" htmlFor="rol">
                    Detalle Requerimiento
                  </label>

                  <label className="block">
                    <textarea id="observaciones" name="observaciones" onChange={handleInputChange} className='appearance-none input-temuco rounded w-full py-3 px-3 text-gray-700 leading-tight'  rows="5" ></textarea>
                  </label>
                </div>
              </div>



              <label className="block uppercase tracking-wide text-gray-500 text-xs font-semibold mb-2 mt-4" htmlFor="rol">
                    Imagenes
              </label>


              <div className="flex flex-row -mx-3 mb-8">



                <div className="w-60 px-3 mb-6 md:mb-0">


                  <label className="block">

                    <label className="w-full flex flex-col items-center px-4 py-2 bg-white text-gray-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-gray-500 hover:text-white">
                      <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span className="mt-1 text-xs leading-normal">Seleccione Imagen 1</span>
                      <input
                          type="file"
                          name="imagen_1"
                          id="imagen_1"
                          accept=".jpeg, .png, .jpg"
                          onChange={handleUploadImage}
                          className="hidden"
                      />
                    </label>




                  </label>

                  {formValues.imagen_1 !== null ?
                  <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={formValues.imagen_1.base64} />
                  : null }

                </div>

                <div className="w-60 px-3 mb-6 md:mb-0">

                  <label className="block">

                    <label className="w-full flex flex-col items-center px-4 py-2 bg-white text-gray-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-gray-500 hover:text-white">
                      <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span className="mt-1 text-xs leading-normal">Seleccione Imagen 2</span>
                      <input
                          type="file"
                          name="imagen_2"
                          id="imagen_2"
                          accept=".jpeg, .png, .jpg"
                          onChange={handleUploadImage}
                          className="hidden"
                      />
                    </label>




                  </label>

                  {formValues.imagen_2 !== null ?
                  <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={formValues.imagen_2.base64} />
                  : null }

                </div>


                <div className="w-60 px-3 mb-6 md:mb-0">

                  <label className="block">

                    <label className="w-full flex flex-col items-center px-4 py-2 bg-white text-gray-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-gray-500 hover:text-white">
                      <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span className="mt-1 text-xs leading-normal">Seleccione Imagen 3</span>
                      <input
                          type="file"
                          name="imagen_3"
                          id="imagen+3"
                          accept=".jpeg, .png, .jpg, .png, .gif"
                          onChange={handleUploadImage}
                          className="hidden"
                      />
                    </label>




                  </label>

                  {formValues.imagen_3 !== null ?
                  <img className="mt-5 border border-gray-200 border-dashed rounded-lg p-3" src={formValues.imagen_3.base64} />
                  : null }

                </div>




              </div>



            <hr className="mt-10 mb-4" />


            <div className="w-full px-0 text-left pt-4">

              <button
                id='btn_confirmar'
                type='submit'
                className='bg-temuco-green text-white text-base font-bold py-3 px-8 rounded'
              >
              <FontAwesomeIcon icon={faSave} size='1x' />
              <span className="pl-2">
              Ingresar Requerimiento
              </span>
              </button>
            </div>

        </div>

        </form>










          </div>
        </div>

    </div>
      </Main>
    </div>
  )
}
