import { fetchConToken } from '../helpers/fetch';


export const obtenerDepartamentos = (tiporequerimiento_id = 0) => {
    return async( dispatch ) => {

        try {
            let resp;
          
            resp = await fetchConToken('departamentos/?tiporequerimiento_id='+tiporequerimiento_id, {}, 'GET');
          
            const body = await resp.json();

            return body.departamentos || [];

        } catch (error) {
            return []
        }

    }
}

export const obtenerDepartamentosMunicipales = () => {
    return async( dispatch ) => {

        try {
            let resp;
          
            resp = await fetchConToken('departamentos/municipales', {}, 'GET');
          
            const body = await resp.json();

            return body.departamentos || [];

        } catch (error) {
            return []
        }

    }
}

