import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {appSetMenuActual} from '../../actions/app'

import {  getTotalesDia,
          getConsolidadoRequerimientosDia,
          getConsolidadoTiemposLlamadaDia } from '../../actions/reportes';

import { Bar } from 'react-chartjs-2';

import { Main } from '../App/Main';
import { MenuLeft } from '../App/MenuLeft';
import 'moment/locale/es';


import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

moment.locale('es');
registerLocale("es", es);
setDefaultLocale("es");


export const Dashboard = () => {

  const dispatch = useDispatch();

  // const { uid } = useSelector( state => state.auth );

  useEffect(() => {
      dispatch( appSetMenuActual("dashboard") );
  }, [ dispatch ])



  const colors = [ '#673AB7', '#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7','#673AB7', ];
  const colors2 = [ '#3fdea1', '#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1','#3fdea1', ];
  const colorsBlue = [ '#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63','#E91E63', ];

  const [fechaDashboard, setFechaDashboard] = useState(new Date())
  const [totalesDia, setTotalesDia] = useState(null)
  const [consolidadoRequerimientosDia , setConsolidadoRequerimientosDia] = useState([])
  const [consolidadoServiciosMunicipalesDia , setConsolidadoServiciosMunicipalesDia] = useState([])
  const [consolidadoServiciosExternosDia , setConsolidadoServiciosExternosDia] = useState([])
  const [consolidadoTiemposLlamadaDia , setConsolidadoTiemposLlamadaDia] = useState([])

  const [tiempoPromedioLlamadas, setTiempoPromedioLlamadas] = useState(0)

  useEffect(() => {

    dispatch( getTotalesDia( moment(fechaDashboard).format('DD/MM/YYYY') ) )
    .then(result => { setTotalesDia(result);  })

    dispatch( getConsolidadoRequerimientosDia( 0, moment(fechaDashboard).format('DD/MM/YYYY') ) )
    .then(result => { setConsolidadoRequerimientosDia(result);  })

    dispatch( getConsolidadoRequerimientosDia( 1, moment(fechaDashboard).format('DD/MM/YYYY') ) )
    .then(result => { setConsolidadoServiciosMunicipalesDia(result);  })

    dispatch( getConsolidadoRequerimientosDia( 2, moment(fechaDashboard).format('DD/MM/YYYY') ) )
    .then(result => { setConsolidadoServiciosExternosDia(result);  })

    dispatch( getConsolidadoTiemposLlamadaDia( moment(fechaDashboard).format('DD/MM/YYYY') ) )
    .then(result => { 
      setConsolidadoTiemposLlamadaDia(result);  

      var total = 0.0;
      for(var i = 0; i < result.length; i++) {
          total += result[i].total_minutos;
      }

      var avg = 0.0
      
      if(result.filter(item => item.total_minutos > 0).length > 0)
      avg = total / result.filter(item => item.total_minutos > 0).length;
      
      setTiempoPromedioLlamadas(avg.toFixed(1))

    })


    


  }, [fechaDashboard])



  const consolidadoDia_graph_data = {
    labels: consolidadoRequerimientosDia.filter(result => result.horario !== null).map(a =>  a.horario),
    datasets: [{
      type: 'line',
      label: 'Total Requerimientos',
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 2,
      fill: false,
      data: consolidadoRequerimientosDia.filter(result => result.horario !== null).map(a => a.total_requerimientos),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    },

    {
      type: 'bar',
      label: 'Servicios Municipales',
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 2,
      fill: false,
      data: consolidadoServiciosMunicipalesDia.filter(result => result.horario !== null).map(a => a.total_requerimientos),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    },
    {
      type: 'bar',
      label: 'Gestión Externa',
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 2,
      fill: false,
      data: consolidadoServiciosExternosDia.filter(result => result.horario !== null).map(a => a.total_requerimientos),
      backgroundColor: colorsBlue,
      hoverBackgroundColor: colorsBlue
    },


  ]
  };



    const consolidadoTiemposLlamadaDia_graph_data = {
    //labels: consolidadoUltimas24h.map(a =>  a.horario),
    labels: consolidadoTiemposLlamadaDia.filter(result => result.horario !== null).map(a =>  a.horario),
    datasets: [{
      type: 'line',
      label: 'Tiempo Promedio Llamada (minutos)',
      borderColor: '#13b376',
      borderWidth: 2,
      fill: false,
      data: consolidadoTiemposLlamadaDia.filter(result => result.horario !== null).map(a => a.total_minutos),
      backgroundColor: colors2,
      hoverBackgroundColor: colors2
    },


  ]
  };


  return (
    <div className='lg:flex min-h-full h-full w-full '>
        <MenuLeft />
        <Main>

        <div className="flex flex-row  text-2xl px-3 pb-3">
          
          <span className=" font-semibold leading-tight ml-6 mt-5">
            Dashboard
          </span>

          <div className="w-auto ml-10 flex flex-row mt-4 ">
            <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2 mt-3 mr-2" htmlFor="fecha_dashboard">
              FECHA:
            </label>
            
            <DatePicker
              id="fecha_dashboard"
              className="border border-gray-300 focus:border-indigo-300 p-2 text-base rounded-lg "
              selected={fechaDashboard} 
              onChange={date => setFechaDashboard(date)}  
              locale="es" 
              dateFormat="dd/MM/yyyy"
              minDate={new Date("04/01/2021")}
              maxDate={new Date()}
              placeholderText="Seleccione"
              />
          </div>
          
        </div>

<hr className="py-3" />

        <div className="flex flex-wrap -mb-4 p-2 pl-8 ">

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100 border-gray-300 h-28 mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Total Requerimientos</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-2xl text-center text-green-500">{ totalesDia !== null ? totalesDia.total_requerimientos : 0 }</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100 border-gray-300 h-28 mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Derivación Servicios Municipales</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_servicios_municipales : 0}</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100  border-gray-300 h-auto mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Gestión Servicios Externos</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_servicios_externos : 0}</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100  border-gray-300 h-auto mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Información General</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_informacion_general : 0}</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100  border-gray-300 h-auto mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Reclamos / Sugerencias / Felicitaciones</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_reclamos_sugerencias_felicitaciones : 0}</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100  border-gray-300 h-auto mx-2 p-2 rounded-lg border ">
            <span className="w-full font-bold text-base text-center">Pitanzas</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_pitanzas : 0}</span>
          </div>

          <div className="flex flex-wrap w-56 mb-4 bg-gray-100  border-gray-300  h-auto mx-2 p-2 rounded-lg border">
            <span className="w-full font-bold text-base text-center">Móviles a terreno</span>
            <span className="w-full font-normal text-base text-center"></span>
            <span className="w-full font-bold text-3xl text-center text-green-500">{totalesDia !== null ? totalesDia.total_movil_terreno : 0}</span>
          </div>

        </div>

        <div className="bg-white w-auto p-5 m-10 rounded">
          <Bar data={consolidadoDia_graph_data} height={100} />
        </div>






        <div className="flex flex-row w-full">

            <div className="flex flex-wrap -mb-4 p-0 px-3 pl-8  ">

            <div className="flex flex-wrap w-56 mb-4 bg-gray-100 border-gray-300 h-28 mx-2 p-2 rounded-lg border ">
              <span className="w-full font-bold text-base text-center"> Tiempo Promedio Llamada (minutos)</span>
              <span className="w-full font-normal text-base text-center"></span>
              <span className="w-full font-bold text-3xl text-center text-green-500"> {tiempoPromedioLlamadas} </span>
            </div>

            </div>

            <div className="bg-white w-full p-2 rounded mr-10">
              <Bar data={consolidadoTiemposLlamadaDia_graph_data} height={100} />
            </div>

        </div> 


        




        </Main>
    </div>
  )
}
