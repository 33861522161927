import { fetchSinToken, fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';

export const setApiVersion = () => {
  return async( dispatch ) => {

      try {
          const resp = await fetchSinToken('api-version', {}, 'GET');
          const body = await resp.json();

          dispatch({ type: types.appSetApiVersion, payload: body })

      } catch (error) {
        console.log(error)
          return {}
      }

  }
}

export const appSetMenuActual = ( menu ) => ({ type: types.appSetMenuActual, payload: menu });
